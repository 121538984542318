import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { db } from '../utils/firebase';
import { collection, getDocs, query, where, Timestamp, orderBy, limit } from 'firebase/firestore';
import { FaSync } from 'react-icons/fa';
import { differenceInDays } from 'date-fns';
import { getStorageInfo } from '../utils/firebaseUtils';
import { auth } from '../utils/firebase';
import { getAverageRatingAcrossAllRecipes } from '../utils/firebaseUtils';
import { getRecipeStatus } from '../utils/recipeUtils';

const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 24px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 15px;
`;

const StatCard = styled(Link)`
  background-color: #ffffff;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    background-color: #f8f9fa;
    transform: translateY(-2px);
  }
`;

const StatTitle = styled.h3`
  margin-bottom: 3px;
  color: #7f8c8d;
  font-size: 12px;
  font-weight: 600;
`;

const StatValue = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #2c3e50;
  margin: 0;
`;

const DateRangeContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
`;

const DateInput = styled.input`
  padding: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 14px;
`;

const RefreshButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #2980b9;
  }
`;

const LoadingSpinner = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const AdminHome = () => {
  const [stats, setStats] = useState({
    totalUsers: 0,
    adminUsers: 0,
    blockedUsers: 0,
    totalRecipes: 0,
    approvedRecipes: 0,
    pendingRecipes: 0,
    rejectedRecipes: 0,
    averageRating: 0,
    recipesPerDay: 0,
  });
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [storageInfo, setStorageInfo] = useState({ totalFiles: 0, totalSpaceUsed: 0 });

  const fetchStats = useCallback(async (start, end) => {
    setLoading(true);
    try {
      // Fetch all recipes ordered by createdAt (ascending) and limit to 1 to get the oldest
      const oldestRecipeQuery = query(
        collection(db, 'recipes'),
        orderBy('createdAt', 'asc'),
        limit(1)
      );
      const oldestRecipeSnapshot = await getDocs(oldestRecipeQuery);
      
      let oldestDate = new Date(0); // Default to Unix epoch
      if (!oldestRecipeSnapshot.empty) {
        oldestDate = oldestRecipeSnapshot.docs[0].data().createdAt.toDate();
      }


      const startTimestamp = start ? Timestamp.fromDate(new Date(start)) : Timestamp.fromDate(oldestDate);
      const endTimestamp = end ? Timestamp.fromDate(new Date(end)) : Timestamp.fromDate(new Date());


      // Fetch all recipes
      const allRecipesSnapshot = await getDocs(collection(db, 'recipes'));
      const totalRecipes = allRecipesSnapshot.size;

      // Fetch date-filtered recipes
      const recipesQuery = query(
        collection(db, 'recipes'),
        where('createdAt', '>=', startTimestamp),
        where('createdAt', '<=', endTimestamp)
      );
      const filteredRecipeSnapshot = await getDocs(recipesQuery);

      // Calculate recipe stats
      const approvedRecipes = filteredRecipeSnapshot.docs.filter(doc => doc.data().approved).length;
      const pendingRecipes = filteredRecipeSnapshot.docs.filter(doc => 
        getRecipeStatus(doc.data()) === 'pending'
      ).length;
      const rejectedRecipes = filteredRecipeSnapshot.docs.filter(doc => doc.data().status === 'rejected').length;


      // Calculate average rating using the ratings collection
      const averageRating = await getAverageRatingAcrossAllRecipes();

      // Calculate recipes per day
      const days = Math.max(differenceInDays(endTimestamp.toDate(), startTimestamp.toDate()), 1);
      const recipesPerDay = (filteredRecipeSnapshot.size / days).toFixed(2);

      // Fetch user stats
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const totalUsers = usersSnapshot.size;
      const adminUsers = usersSnapshot.docs.filter(doc => doc.data().isAdmin).length;
      const blockedUsers = usersSnapshot.docs.filter(doc => doc.data().isBlocked).length;

      // Fetch email opt-ins (assuming there's a field for this)
      const emailOptIns = usersSnapshot.docs.filter(doc => doc.data().emailOptIn).length;

      setStats({
        totalRecipes,
        approvedRecipes,
        pendingRecipes,
        rejectedRecipes,
        averageRating: averageRating !== null ? averageRating.toFixed(2) : 'N/A',
        recipesPerDay,
        totalUsers,
        adminUsers,
        blockedUsers,
        emailOptIns,
        totalFiles: storageInfo.totalFiles,
        spaceUsed: storageInfo.totalSpaceUsed
      });

    } catch (error) {
      // Set default values in case of error
      setStats({
        totalRecipes: 0,
        approvedRecipes: 0,
        pendingRecipes: 0,
        rejectedRecipes: 0,
        averageRating: 'N/A',
        recipesPerDay: '0.00',
        totalUsers: 0,
        adminUsers: 0,
        blockedUsers: 0,
        emailOptIns: 0,
        totalFiles: 0,
        spaceUsed: 0
      });
    } finally {
      setLoading(false);
    }
  }, [storageInfo]);

  useEffect(() => {
    fetchStats(startDate, endDate);
  }, [fetchStats, startDate, endDate]);

  useEffect(() => {
    const fetchStorageInfo = async () => {
      if (auth.currentUser) {
        const info = await getStorageInfo();
        setStorageInfo(info);
      } else {
        setStorageInfo({ totalFiles: 0, totalSpaceUsed: 0, error: "User not authenticated" });
      }
    };

    fetchStorageInfo();
  }, []);

  const handleRefresh = () => {
    fetchStats(startDate, endDate);
  };

  return (
    <DashboardContainer>
      <Header>Admin Dashboard</Header>
      <DateRangeContainer>
        <DateInput
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <DateInput
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <RefreshButton onClick={handleRefresh}>
          <FaSync /> Refresh
        </RefreshButton>
      </DateRangeContainer>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <StatsContainer>
          <StatCard to="/admin-dashboard/recipes?filter=pending">
            <StatTitle>Pending Recipes</StatTitle>
            <StatValue>{stats.pendingRecipes}</StatValue>
          </StatCard>
          <StatCard to="/admin-dashboard/recipes">
            <StatTitle>Total Recipes</StatTitle>
            <StatValue>{stats.totalRecipes}</StatValue>
          </StatCard>
          <StatCard to="/admin-dashboard/recipes?filter=approved">
            <StatTitle>Approved Recipes</StatTitle>
            <StatValue>{stats.approvedRecipes}</StatValue>
          </StatCard>
          <StatCard to="/admin-dashboard/recipes?filter=rejected">
            <StatTitle>Rejected Recipes</StatTitle>
            <StatValue>{stats.rejectedRecipes}</StatValue>
          </StatCard>
          <StatCard as="div">
            <StatTitle>Avg Recipe Rating</StatTitle>
            <StatValue>{stats.averageRating}</StatValue>
          </StatCard>
          <StatCard as="div">
            <StatTitle>Recipes Per Day</StatTitle>
            <StatValue>{stats.recipesPerDay}</StatValue>
          </StatCard>
          <StatCard to="/admin-dashboard/users">
            <StatTitle>Total Users</StatTitle>
            <StatValue>{stats.totalUsers}</StatValue>
          </StatCard>
          <StatCard to="/admin-dashboard/users?filter=admin">
            <StatTitle>Admin Users</StatTitle>
            <StatValue>{stats.adminUsers}</StatValue>
          </StatCard>
          <StatCard to="/admin-dashboard/users?filter=blocked">
            <StatTitle>Blocked Users</StatTitle>
            <StatValue>{stats.blockedUsers}</StatValue>
          </StatCard>
          <StatCard as="div">
            <StatTitle>Email Opt-ins</StatTitle>
            <StatValue>{stats.emailOptIns}</StatValue>
          </StatCard>
          <StatCard as="div">
            <StatTitle>Total Files</StatTitle>
            <StatValue>{storageInfo.totalFiles}</StatValue>
          </StatCard>
          <StatCard as="div">
            <StatTitle>Space Used (MB)</StatTitle>
            <StatValue>{storageInfo.totalSpaceUsed}</StatValue>
          </StatCard>
        </StatsContainer>
      )}
    </DashboardContainer>
  );
};

export default AdminHome;