import { format, isValid, parseISO } from 'date-fns';

export const formatTimestamp = (dateValue) => {
  if (!dateValue) return 'N/A';
  
  let date;
  if (typeof dateValue === 'string') {
    date = parseISO(dateValue);
  } else if (dateValue instanceof Date) {
    date = dateValue;
  } else if (dateValue.toDate && typeof dateValue.toDate === 'function') {
    // Handle Firestore Timestamp
    date = dateValue.toDate();
  } else if (dateValue.seconds) {
    // Handle object with seconds
    date = new Date(dateValue.seconds * 1000);
  } else {
    return 'N/A';
  }
  
  return isValid(date) ? format(date, 'MM/dd/yyyy') : 'N/A';
};

export default formatTimestamp;
