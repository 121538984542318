export const getRecipeStatus = (recipe) => {
  //console.log('Checking recipe status:', recipe);
  if (recipe.rejected === true) return 'rejected';
  if (recipe.approved === true) return 'approved';
  if (recipe.approvalRequested === true) return 'pending';
  return 'inProgress';
};

export const unescapeCommas = (str) => {
  return str.replace(/\\,/g, ',');
};
