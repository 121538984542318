import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { checkAdminStatus, getUserDocument } from '../utils/firebaseUtils';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          const isAdmin = await checkAdminStatus(firebaseUser.uid);
          const userDoc = await getUserDocument(firebaseUser.uid);
          setUser({ ...firebaseUser, ...userDoc, isAdmin });
        } else {
          setUser(null);
        }
      } catch (err) {
        //console.error("Error in auth state change:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    user,
    loading,
    error
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
