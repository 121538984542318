import React, { useState } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { FaTimes, FaTrashAlt, FaCopy, FaPrint, FaFilePdf, FaEnvelope } from 'react-icons/fa';
import { jsPDF } from "jspdf";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a2b2f;
  padding: 12.5px 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 50px;
  width: auto;
`;

const ActionBar = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  svg {
    font-size: 1.125rem;
  }
`;

const CloseButton = styled(ActionButton)``;

const ModalContent = styled.div`
  background-color: #fff;
  width: 625px;
  height: 1000px;
  max-width: 90%;
  max-height: 80vh;
  border-radius: 10px;
  box-shadow: 0 5px 18.75px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  animation: ${fadeIn} 0.3s ease-out;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: 750px) {
    width: 90%;
    height: auto;
    min-height: 125px;
  }
`;

const ListContainer = styled.div`
  padding: 25px;
  flex-grow: 1;
  overflow-y: auto;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  flex-grow: 1;
`;

const ListItem = styled.li`
  padding: 5px 0; // Reduced from 10px to 5px
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  font-size: 1.0rem;
  color: #333;
`;

const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px; // Reduced from 2px to 1px
`;

const ItemDetails = styled.span`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const RecipeInfo = styled.span`
  font-size: 0.7rem;
  color: #666;
  font-style: italic;
  margin-top: -11px;
`;

const RemoveButton = styled.button`
  background-color: transparent;
  color: #e74c3c;
  border: none;
  padding: 6.25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.25rem;
  transition: color 0.3s;

  &:hover {
    color: #c0392b;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Arial, sans-serif;
  }
`;

const Quantity = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

const Separator = styled.span`
  margin: 0 5px;
`;

const ItemName = styled.span`
  flex-grow: 1;
`;

const ClearButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 5px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const ConfirmModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 300px;
  text-align: center;
`;

const ModalTitle = styled.h3`
  margin-top: 0;
  color: #2c3e50;
`;

const ModalText = styled.p`
  color: #34495e;
  margin-bottom: 20px;
`;

const ModalButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ModalButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const ConfirmButton = styled(ModalButton)`
  background-color: #e74c3c;
  color: white;
`;

const CancelButton = styled(ModalButton)`
  background-color: #7f8c8d;
  color: white;
`;

const ShoppingList = ({ items, onClose, setShoppingList }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleRemoveItem = (itemToRemove) => {
    setShoppingList(prevList => prevList.filter(item => {
      if (typeof item === 'object' && item.item) {
        return item.item !== itemToRemove.item || item.recipeName !== itemToRemove.recipeName;
      }
      return item !== itemToRemove;
    }));
  };

  const copyToClipboard = () => {
    const text = items.map(item => `${item.item} (${item.recipeName})`).join('\n');
    navigator.clipboard.writeText(text).then(() => {
      alert('Shopping list copied to clipboard!');
    }, (err) => {
      //console.error('Could not copy text: ', err);
    });
  };

  const printList = () => {
    window.print();
  };

  const saveAsPDF = () => {
    const doc = new jsPDF();
    doc.text("Shopping List", 10, 10);
    items.forEach((item, index) => {
      doc.text(`${item.item} (${item.recipeName})`, 10, 20 + (index * 10));
    });
    doc.save("shopping_list.pdf");
  };

  const emailList = () => {
    const subject = encodeURIComponent("My Shopping List");
    const body = encodeURIComponent(items.map(item => `${item.item} (${item.recipeName})`).join('\n'));
    
    // Check if the user is likely using Gmail
    const isGmail = window.navigator.userAgent.indexOf("Google") > -1;
    
    if (isGmail) {
      // Open Gmail compose window
      window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=&su=${subject}&body=${body}`, '_blank');
    } else {
      // Fallback to default mailto link
      window.location.href = `mailto:?subject=${subject}&body=${body}`;
    }
  };

  const handleClearAll = () => {
    setShowConfirmModal(true);
  };

  const confirmClearAll = () => {
    setShoppingList([]);
    setShowConfirmModal(false);
  };

  const renderListItem = (item) => {
    //console.log('Rendering shopping list item:', item);
    if (!item || typeof item !== 'object') {
      return null;
    }

    // Unwrap the nested item if necessary
    const actualItem = item.item && typeof item.item === 'object' ? item.item : item;

    let amount = '';
    let name = '';
    let recipeName = actualItem.recipeName || 'Unknown Recipe';
    let calculatedServes = actualItem.calculatedServes || 'N/A';
    let originalServes = actualItem.originalServes || 'N/A';

    if (actualItem.item && typeof actualItem.item === 'string') {
      const parts = actualItem.item.split(' ');
      amount = parts[0];
      // If there's a measurement, include it in the amount
      if (parts.length > 2 && isNaN(parts[1])) {
        amount += ' ' + parts[1];
        name = parts.slice(2).join(' ');
      } else {
        name = parts.slice(1).join(' ');
      }
    } else {
      // If item is not a string, use it as is
      name = actualItem.item || JSON.stringify(actualItem);
    }

    // Combine recipe name with serves info
    const recipeInfoWithServes = `${recipeName} (Serves: ${calculatedServes}, originally ${originalServes})`;

    return (
      <>
        <ItemContent>
          <ItemDetails>
            {amount && <Quantity>{amount}</Quantity>}
            {amount && <Separator>-</Separator>}
            <ItemName>{name}</ItemName>
          </ItemDetails>
          <RemoveButton onClick={() => handleRemoveItem(item)}>
            <FaTrashAlt />
          </RemoveButton>
        </ItemContent>
        <RecipeInfo>{recipeInfoWithServes}</RecipeInfo>
      </>
    );
  };

  return (
    <ModalOverlay onClick={onClose}>
      <GlobalStyle />
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <LogoContainer>
            <LogoImage 
              src="https://aws.boone-crockett.org/s3fs-public/styles/12-col_848px/public/thumbnails/image/wild_recipes_logo_6.png" 
              alt="Wild Recipes" 
            />
          </LogoContainer>
          <ButtonGroup>
            <ClearButton onClick={handleClearAll}>
              <FaTrashAlt /> Clear All
            </ClearButton>
            <ActionBar>
              <ActionButton onClick={copyToClipboard}><FaCopy /></ActionButton>
              <ActionButton onClick={printList}><FaPrint /></ActionButton>
              <ActionButton onClick={saveAsPDF}><FaFilePdf /></ActionButton>
              <ActionButton onClick={emailList}><FaEnvelope /></ActionButton>
              <CloseButton onClick={onClose}>
                <FaTimes />
              </CloseButton>
            </ActionBar>
          </ButtonGroup>
        </ModalHeader>
        <ListContainer>
          <List>
            {items.map((item, index) => {
              return (
                <ListItem key={index}>
                  {renderListItem(item)}
                </ListItem>
              );
            })}
          </List>
          {items.length === 0 && <div>No items in the shopping list</div>}
        </ListContainer>
      </ModalContent>
      {showConfirmModal && (
        <ConfirmModal onClick={e => e.stopPropagation()}>
          <ModalTitle>Clear Shopping List</ModalTitle>
          <ModalText>Are you sure you want to clear the entire shopping list?</ModalText>
          <ModalButtonGroup>
            <CancelButton onClick={() => setShowConfirmModal(false)}>Cancel</CancelButton>
            <ConfirmButton onClick={confirmClearAll}>Clear All</ConfirmButton>
          </ModalButtonGroup>
        </ConfirmModal>
      )}
    </ModalOverlay>
  );
};

export default ShoppingList;