import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { FaFileAlt, FaUtensils, FaClock, FaUsers, FaThermometerHalf, FaMapMarkerAlt, FaStar, FaLink, FaFire, FaHeart, FaRegHeart, FaYoutube, FaTimes } from 'react-icons/fa';
import { db } from '../utils/firebase';
import { doc, setDoc, collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import Modal from './Modal';
import { Helmet } from 'react-helmet-async';

// Move all styled-component definitions here, outside of the component
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Droid Sans';
    src: url('https://fonts.googleapis.com/css2?family=Droid+Sans:wght@700&display=swap');
    font-weight: 700;
    font-style: normal;
  }
`;

const PrintStyle = createGlobalStyle`
  @media print {
    body * {
      visibility: hidden;
    }
    #recipe-content, #recipe-content * {
      visibility: visible;
    }
    #recipe-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 8.5in;
      height: 11in;
      padding: 0.5in;
      font-size: 12pt;
      line-height: 1.3;
    }
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const PopupContent = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 8.5in;
  height: 11in;
  max-width: 95%;
  max-height: 95vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  box-shadow: 0 5px 18.75px rgba(0, 0, 0, 0.2);
  aspect-ratio: 8.5 / 11;

  @media (max-width: 840px) {
    width: 95%;
    height: auto;
    max-height: 90vh; // Adjust max-height for smaller screens
    overflow-y: auto; // Ensure content is scrollable
    aspect-ratio: auto;
  }

  @media print {
    width: 8.5in;
    height: 11in;
    max-width: none;
    max-height: none;
    overflow: visible;
    box-shadow: none;
  }
`;

const PopupHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  align-items: center;
  background-color: #2a2b2f;
  padding: 18px 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  height: auto;
  min-height: 120px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    padding: 15px;
  }
`;

const CenteredRecipeTitle = styled.h2`
  color: white;
  margin: 0 auto;
  font-size: 1.5rem;
  text-align: center;
  grid-column: 1 / -1;
  grid-row: 1;
  font-family: 'Droid Sans', sans-serif;
  font-weight: 700;
  padding: 0 10px;
  z-index: 1;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: start;
  margin-top: 10px;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.5rem;
    margin-top: 15px;
    grid-row: 2;
  }
`;

const SubmittedByHeader = styled.p`
  color: #e0e0e0;
  font-size: 0.8rem;
  text-align: center;
  grid-column: 1 / -1;
  grid-row: 2;
  margin-top: 0px;

  @media (max-width: 768px) {
    grid-row: 3;
    margin-top: 5px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  z-index: 2;
  height: 100%;
  padding-top: 10px;
  margin-top: -135px;
  margin-left: -10px;

  @media (max-width: 768px) {
    display: none; // Hide logo on smaller screens
  }
`;

const LogoImage = styled.img`
  height: 60px; // Fixed height
  margin-top: -10px;
  width: auto;
  object-fit: contain;
`;

const ActionBar = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 2;
  display: flex;
  align-items: center; // Center items vertically
  gap: 10px; // Add equal space between items
`;

const ActionButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  width: 30px;
  height: 30px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  svg {
    font-size: 1.2rem;
  }
`;

const FavoriteButton = styled(ActionButton)`
  color: ${props => props.$isFavorited ? '#ff4136' : 'white'};

  &:hover {
    color: ${props => props.$isFavorited ? '#ff4136' : 'white'};
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const PopupBody = styled.div`
  padding: 25px;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 840px) {
    padding: 15px;
  }

  @media print {
    overflow: visible;
  }
`;

const RatingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
  padding-top: 5px;
  padding-bottom: 2px;
  border-top: 1px solid #ccc;
  background-color: white;
  width: 100%;

  @media (max-width: 840px) {
    padding: 10px 0;
  }
`;

const RecipeMetadata = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
`;

const MetadataItem = styled.span`
  display: inline-block;
  font-size: 0.6rem;
  color: ${props => props.$isMethod ? '#e74c3c' : '#34495e'};
  background-color: ${props => props.$isMethod ? '#fadbd8' : '#eaecee'};
  padding: 5px 10px;
  border-radius: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  white-space: nowrap;

  svg {
    margin-right: 5px;
    vertical-align: middle;
  }
`;

const RecipeDescription = styled.p`
  margin-bottom: 10px;
  font-style: italic;
  font-size: 1.2rem;
`;

const ServingControlContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const ServingControl = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ServingButton = styled.button`
  background-color: ${props => props.$active ? '#2a6496' : 'transparent'};
  color: ${props => props.$active ? 'white' : '#2a6496'};
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1; // Ensure consistent line height

  // Move the symbol up by 2px
  padding-top: 0;
  padding-bottom: 1px; // Increase bottom padding to move content up

  &:hover {
    background-color: #2a6496; // Theme blue color
    color: white;
  }

  &:first-child {
    margin-right: 2px;
  }

  &:last-child {
    margin-left: 2px;
  }

  // Ensure the content is centered horizontally
  span {
    display: inline-block;
    transform: translateY(-1px); // Fine-tune vertical positioning
  }
`;

const ServingDisplay = styled.span`
  margin: 0 6px; // Reduce margin
  font-size: 0.9rem;
  font-weight: bold;
  min-width: 70px; // Reduce min-width slightly
  text-align: center;
`;

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  flex: 0 0 300px;
`;

const RecipeSection = styled.div`
  margin-top: 5px;
margin-bottom: 5px;
`;

const SectionTitle = styled.h3`
margin-top: 5px;
margin-bottom: 15px;
  color: #2c3e50;
`;

const IngredientList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: -10px;
`;

const IngredientItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
`;

const IngredientCheckbox = styled.input`
  margin-right: 2px;
  align-self: flex-start;
  margin-top: 2px;
`;

const IngredientContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const IngredientAmount = styled.span`
  flex: 0 1 auto;
  text-align: left;
  padding-right: 5px;
  padding-left: 5px;
  font-weight: bold;
`;

const IngredientUnit = styled.span`
  flex: 0 1 auto;
  text-align: left;
  padding-right: 5px;
  font-weight: bold;
`;

const IngredientName = styled.span`
  flex: 1;
  text-align: left;
`;

const ShoppingListButton = styled.button`
  background-color: #5f4d4d;
  color: white;
  border: none;
  padding: 5px 8px;
  cursor: pointer;
  font-size: .7rem;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 0px; // Add left margin to align with ingredients
  border-radius: 5px;
  transition: background-color 0.3s ease;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background-color: #7a6363;
  }
`;

const InstructionsSection = styled.div`
  margin-bottom: 0px;
  margin-top: 0px;
`;

const StepList = styled.ol`
  padding-left: 20px;
`;

const StepItem = styled.li`
  margin-bottom: 5px;
`;

const RatingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RatingTitle = styled.h4`
  margin-bottom: 5px;
`;

const StarRating = styled.div`
  display: flex;
  align-items: center;
`;

const StarIcon = styled(FaStar)`
  color: ${props => props.$filled ? '#ffc107' : '#e4e5e9'};
  cursor: pointer;
  transition: color 0.2s ease-in-out;
`;

const RatingText = styled.span`
  margin-left: 5px;
  font-size: 0.9rem;
`;

const RatingValue = styled.span`
  margin-left: 5px;
  font-weight: bold;
`;

const LinkDisplay = styled.div`
  background-color: #f1f2f6;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  word-break: break-all;
`;

const ModalButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  font-size: 1rem;
`;

const SelectAllContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const SelectAllCheckbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 10px;
  align-self: flex-start;
`;

const SelectAllLabel = styled.span`
  font-size: 0.9rem;
`;

// Update the LoginModal styled component
const LoginModal = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px; // Increased from 300px
  width: 100%;
`;

const LoginModalTitle = styled.h3`
  margin-top: 0;
  color: #2c3e50;
  font-size: 1.5rem;
`;

const LoginModalText = styled.p`
  margin-bottom: 20px;
  color: #34495e;
  font-size: 1.1rem;
`;

const LoginModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const LoginModalButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2980b9;
  }
`;

const LoginNowButton = styled(LoginModalButton)`
  background-color: #2ecc71;

  &:hover {
    background-color: #27ae60;
  }
`;

const CopyNotification = styled.div`
  display: ${props => (props.$show ? 'block' : 'none')};
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  position: fixed;
  top: 13%;
  left: 60%;
  z-index: 1000;
  transition: opacity 0.5s ease;
`;

const RecipeImageContainer = styled.div`
  width: 100%;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  background-color: #f0f0f0;
  position: relative;
  cursor: ${props => props.$hasVideo ? 'pointer' : 'default'};
`;

const RecipeImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const VideoButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  color: #FF0000; // YouTube red color
  transition: background-color 0.2s, color 0.2s;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #FF0000;
    color: white;
  }

  span {
    margin-right: 5px;
    font-weight: bold;
  }

  svg {
    font-size: 1.2rem;
  }
`;

const RecipeDetailPopup = ({ 
  recipe, 
  onClose, // This prop is used, so we'll keep it
  user, 
  addToShoppingList, 
  isFavorite, 
  onFavoriteToggle, 
  onRatingChange, 
  openLoginModal 
}) => {
  const [servings, setServings] = useState(parseInt(recipe.Serves) || 1);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [scaledIngredients, setScaledIngredients] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareableLink, setShareableLink] = useState('');
  const [userRating, setUserRating] = useState(0);
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [localAverageRating, setLocalAverageRating] = useState(recipe.averageRating || 0);
  const [localNumberOfRatings, setLocalNumberOfRatings] = useState(recipe.numberOfRatings || 0);
  const [selectionState, setSelectionState] = useState('none'); // 'none', 'partial', 'all'
  const [showLoginModal, setShowLoginModal] = useState(false);

  const parseMixedNumber = (value) => {
    const parts = value.trim().split(/\s+/);
    let result = 0;
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (part.includes('/')) {
        const [numerator, denominator] = part.split('/').map(Number);
        result += numerator / denominator;
      } else if (!isNaN(part)) {
        result += Number(part);
      } else {
        break; // Stop parsing if we encounter a non-numeric part
      }
    }
    return result;
  };

  const formatNumber = (num) => {
    if (num % 1 === 0) return num.toString();
    const wholePart = Math.floor(num);
    const fractionalPart = num - wholePart;
    const fractionPrecision = 16; // Adjust for desired precision
    let numerator = Math.round(fractionalPart * fractionPrecision);
    let denominator = fractionPrecision;
    
    // Simplify the fraction
    const gcd = (a, b) => b ? gcd(b, a % b) : a;
    const divisor = gcd(numerator, denominator);
    numerator /= divisor;
    denominator /= divisor;
    
    if (wholePart === 0) {
      return `${numerator}/${denominator}`;
    } else {
      return `${wholePart} ${numerator}/${denominator}`;
    }
  };

  const parseAndScaleIngredient = useCallback((ingredient, scale) => {
    console.log('Original ingredient:', ingredient);
    console.log('Scale:', scale);

    const parts = ingredient.trim().split(/\s+/);
    let amountStr = parts[0];
    if (parts.length > 1 && /^\d+$/.test(parts[0]) && /^\d+\/\d+$/.test(parts[1])) {
      amountStr = `${parts[0]} ${parts[1]}`;
      parts.splice(0, 2, amountStr);
    }
    
    console.log('Amount string:', amountStr);

    // Check if the first part is a number or fraction
    if (/^(\d+(\s+)?)?\d+\/\d+$|^\d+$/.test(amountStr)) {
      console.log('Matched number or fraction');
      const amount = parseMixedNumber(amountStr);
      console.log('Parsed amount:', amount);
      const scaledAmount = amount * scale;
      console.log('Scaled amount:', scaledAmount);
      const formattedAmount = formatNumber(scaledAmount);
      console.log('Formatted amount:', formattedAmount);
      const [unit, ...nameParts] = parts.slice(1);
      return { 
        amount: formattedAmount, 
        unit: unit || '', 
        name: nameParts.join(' ')
      };
    }
    
    return { amount: '', unit: '', name: ingredient };
  }, []);

  useEffect(() => {
    console.log('Servings changed:', servings);
    console.log('Original serves:', recipe.Serves);

    const originalServings = parseMixedNumber(recipe.Serves.toString()) || 1;
    const scale = servings / originalServings;
    console.log('Calculated scale:', scale);

    let ingredientsList = [];
    if (Array.isArray(recipe.Ingredients)) {
      ingredientsList = recipe.Ingredients;
    } else if (typeof recipe.Ingredients === 'string') {
      ingredientsList = recipe.Ingredients.split('|').map(ing => ing.trim());
    } else if (typeof recipe.Ingredients === 'object') {
      ingredientsList = Object.entries(recipe.Ingredients).map(([key, value]) => `${key}: ${value}`);
    }

    console.log('Ingredients list:', ingredientsList);

    const scaledIngredients = ingredientsList.map(ing => parseAndScaleIngredient(ing, scale));
    console.log('Scaled ingredients:', scaledIngredients);

    setScaledIngredients(scaledIngredients);
  }, [recipe.Ingredients, recipe.Serves, servings, parseAndScaleIngredient, recipe]);
  
  useEffect(() => {
    const fetchUserRating = async () => {
      if (user && recipe.id) {
        const ratingsRef = collection(db, 'ratings');
        const q = query(ratingsRef, where('userId', '==', user.uid), where('recipeId', '==', recipe.id));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const ratingDoc = querySnapshot.docs[0];
          setUserRating(ratingDoc.data().rating);
        }
      }
    };
  
    fetchUserRating();
  }, [user, recipe.id]);
  
  // Remove the unused handleContentClick function
  // const handleContentClick = useCallback((e) => {
  //   e.stopPropagation();
  // }, []);
  
  const decreaseServings = () => {
    setServings(prev => Math.max(prev - 1, 1));
  };

  const increaseServings = () => {
    setServings(prev => prev + 1);
  };

  const toggleAllIngredients = () => {
    const selectableIngredients = scaledIngredients.filter(ingredient => 
      ingredient.amount && ingredient.amount.trim() !== ''
    );

    if (selectionState === 'all') {
      setSelectedIngredients([]);
      setSelectionState('none');
    } else {
      setSelectedIngredients(selectableIngredients.map(ingredient => 
        `${ingredient.amount} ${ingredient.unit} ${ingredient.name}`.trim()
      ));
      setSelectionState('all');
    }
  };

  const handleIngredientToggle = (ingredient) => {
    setSelectedIngredients(prev => {
      const newSelection = prev.includes(ingredient)
        ? prev.filter(item => item !== ingredient)
        : [...prev, ingredient];
      
      const selectableIngredients = scaledIngredients.filter(ing => 
        ing.amount && ing.amount.trim() !== ''
      );
      
      if (newSelection.length === 0) {
        setSelectionState('none');
      } else if (newSelection.length === selectableIngredients.length) {
        setSelectionState('all');
      } else {
        setSelectionState('partial');
      }
      
      return newSelection;
    });
  };

  useEffect(() => {
    const selectableIngredients = scaledIngredients.filter(ingredient => 
      ingredient.amount && ingredient.amount.trim() !== ''
    );

    if (selectedIngredients.length === 0) {
      setSelectionState('none');
    } else if (selectedIngredients.length === selectableIngredients.length) {
      setSelectionState('all');
    } else {
      setSelectionState('partial');
    }
  }, [selectedIngredients, scaledIngredients]);

  const handleAddToShoppingList = () => {
    console.log('Current servings:', servings);
    console.log('Original serves:', recipe.Serves);
    selectedIngredients.forEach(ingredient => {
      const shoppingListItem = {
        item: ingredient,
        recipeName: recipe.Name,
        calculatedServes: servings.toString(),
        originalServes: recipe.Serves
      };
      console.log('Adding to shopping list:', shoppingListItem);
      addToShoppingList(shoppingListItem);
    });
    setSelectedIngredients([]);
    setSelectionState('none');
  };

  const generateShareableLink = useCallback(() => {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split('?')[0];
    return `${baseUrl}?recipe=${recipe.id}`;
  }, [recipe.id]);

  const showCopyNotificationWithTimeout = () => {
    setShowCopyNotification(true);
    setTimeout(() => {
      setShowCopyNotification(false);
    }, 3000);
  };

  const shareLink = useCallback(() => {
    const link = generateShareableLink();
    navigator.clipboard.writeText(link)
      .then(() => {
        showCopyNotificationWithTimeout();
      })
      .catch(err => {
        setShareableLink(link);
        setShowShareModal(true);
      });
  }, [generateShareableLink]);

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text).then(() => {
      showCopyNotificationWithTimeout();
    }).catch(err => {
      prompt('Copy this link:', text);
    });
  }, []);

  const updateRatingsStats = useCallback(async (recipeId, newRating, userId) => {
    const userRatingRef = doc(db, 'ratings', `${userId}_${recipeId}`);
    const mstTimestamp = new Date(new Date().toLocaleString("en-US", {timeZone: "America/Phoenix"}));
    
    const newRatingData = {
      rating: newRating,
      userId: userId,
      recipeId: recipeId,
      timestamp: Timestamp.fromDate(mstTimestamp)
    };

    try {
      await setDoc(userRatingRef, newRatingData, { merge: true });
      //console.log('User rating updated successfully');
      return true;
    } catch (error) {
      //console.error('Error updating user rating:', error);
      return false;
    }
  }, []);

  const handleRatingChange = async (newRating) => {
    if (!user) {
      //console.log('User not logged in');
      return;
    }

    const success = await updateRatingsStats(recipe.id, newRating, user.uid);
    if (success) {
      setUserRating(newRating);
      
      // Calculate new average rating and number of ratings
      const oldRatingTotal = localAverageRating * localNumberOfRatings;
      const newNumberOfRatings = localNumberOfRatings + (userRating === 0 ? 1 : 0);
      const newRatingTotal = oldRatingTotal - userRating + newRating;
      const newAverageRating = newRatingTotal / newNumberOfRatings;

      setLocalAverageRating(newAverageRating);
      setLocalNumberOfRatings(newNumberOfRatings);

      // Notify parent component of the rating change
      onRatingChange(recipe.id, newAverageRating, newNumberOfRatings);
    }
  };

  // Remove the unused handleClose function
  // const handleClose = async () => {
  //   onClose();
  // };

  const handleFavoriteToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    //console.log('Favorite toggle clicked, user:', user);
    if (user) {
      onFavoriteToggle(e, recipe.id);
    } else {
      //console.log('Showing login modal for favorite');
      setShowLoginModal(true);
    }
  };

  const handleVideoClick = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent the popup from closing
    if (recipe.videoUrl) {
      window.open(recipe.videoUrl, '_blank');
    }
  };

  const handleRatingClick = () => {
    //console.log('Rating clicked, user:', user);
    if (!user) {
      //console.log('Showing login modal for rating');
      setShowLoginModal(true);
    }
  };

  useEffect(() => {
    //console.log('showLoginModal state:', showLoginModal);
  }, [showLoginModal]);

  const closeLoginModal = () => {
    //console.log('Closing login modal');
    setShowLoginModal(false);
  };

  const handleLoginClick = () => {
    closeLoginModal();
    openLoginModal(); // This should trigger the login process
  };

  console.log('Recipe data:', recipe);

  return (
    <>
      <Helmet>
        <title data-testid="recipe-title">{recipe.Name || 'Recipe Details'} - Boone and Crockett Club's Wild Recipes</title>
        <meta data-testid="recipe-description" name="description" content={recipe.Description || 'View this delicious wild game recipe'} />
        <meta data-testid="recipe-keywords" name="keywords" content={`wild game, recipes, ${recipe['Food Type'] || ''}, ${recipe['Cooking Method'] || ''}`} />
      </Helmet>
      <PopupOverlay onClick={onClose}>
        <GlobalStyle />
        <PrintStyle />
        <PopupContent onClick={(e) => e.stopPropagation()}>
          <PopupHeader>
            <LogoContainer>
              <LogoImage 
                src="https://aws.boone-crockett.org/s3fs-public/styles/12-col_848px/public/thumbnails/image/wild_recipes_logo_6.png" 
                alt="Wild Recipes" 
              />
            </LogoContainer>
            <CenteredRecipeTitle title={recipe.Name || 'Untitled Recipe'}>
              {recipe.Name || 'Untitled Recipe'}
            </CenteredRecipeTitle>
            <SubmittedByHeader>Submitted by: {recipe.Submitter || 'Unknown'}</SubmittedByHeader>
            <ActionBar>
              <FavoriteButton
                onClick={handleFavoriteToggle}
                $isFavorited={isFavorite}
                title={isFavorite ? "Remove from favorites" : "Add to favorites"}
              >
                {isFavorite ? <FaHeart /> : <FaRegHeart />}
              </FavoriteButton>
              <ActionButton onClick={shareLink} title="Copy share link">
                <FaLink />
              </ActionButton>
              <ActionButton onClick={onClose} title="Close recipe">
                <FaTimes />
              </ActionButton>
            </ActionBar>
          </PopupHeader>
          <PopupBody id="recipe-content">
            <RecipeMetadata>
              {recipe['Cooking Method'] && (
                <MetadataItem $isMethod={true}>
                  <FaFire /> {recipe['Cooking Method']}
                </MetadataItem>
              )}
              {recipe['Food Type'] && (
                <MetadataItem>
                  <FaUtensils />
                  Game: {typeof recipe['Food Type'] === 'object' 
                    ? Object.keys(recipe['Food Type']).join(', ') 
                    : recipe['Food Type']}
                </MetadataItem>
              )}
              {recipe['Prep Time'] && (
                <MetadataItem>
                  <FaClock />
                  Prep: {recipe['Prep Time']}
                </MetadataItem>
              )}
              {recipe['Cooking Time'] && (
                <MetadataItem>
                  <FaClock />
                  Cook: {recipe['Cooking Time']}
                </MetadataItem>
              )}
              {recipe['Serves'] && (
                <MetadataItem>
                  <FaUsers />
                  Serves: {recipe['Serves']}
                </MetadataItem>
              )}
              {recipe['Difficulty'] && (
                <MetadataItem>
                  <FaThermometerHalf />
                  Difficulty: {recipe['Difficulty']}
                </MetadataItem>
              )}
              {recipe['State/Province'] && (
                <MetadataItem>
                  <FaMapMarkerAlt />
                  Location: {recipe['State/Province']}
                </MetadataItem>
              )}
            </RecipeMetadata>
            {recipe.Description && <RecipeDescription>{recipe.Description}</RecipeDescription>}
            <ServingControlContainer>
              <ServingControl>
                <ServingButton 
                  onClick={decreaseServings} 
                  $active={servings > parseInt(recipe.Serves)}
                >
                  <span>-</span>
                </ServingButton>
                <ServingDisplay>{servings} Servings</ServingDisplay>
                <ServingButton 
                  onClick={increaseServings}
                  $active={servings < parseInt(recipe.Serves)}
                >
                  <span>+</span>
                </ServingButton>
              </ServingControl>
            </ServingControlContainer>
            <ContentLayout>
              <TopSection>
                <LeftColumn>
                  <RecipeSection>
                    <SectionTitle>Ingredients</SectionTitle>
                    <IngredientList>
          {scaledIngredients.map((ingredient, index) => (
            <IngredientItem key={index}>
              <IngredientCheckbox
                type="checkbox"
                checked={selectedIngredients.includes(`${ingredient.amount} ${ingredient.unit} ${ingredient.name}`.trim())}
                onChange={() => handleIngredientToggle(`${ingredient.amount} ${ingredient.unit} ${ingredient.name}`.trim())}
              />
              <IngredientContent>
                <IngredientAmount>{ingredient.amount}</IngredientAmount>
                {ingredient.unit ? (
                  <>
                    <IngredientUnit>{ingredient.unit}</IngredientUnit>
                    <IngredientName>{ingredient.name}</IngredientName>
                  </>
                ) : (
                  <IngredientName>{ingredient.unit} {ingredient.name}</IngredientName>
                )}
              </IngredientContent>
            </IngredientItem>
          ))}
        </IngredientList>
                    <SelectAllContainer onClick={toggleAllIngredients}>
                      <SelectAllCheckbox
                        type="checkbox"
                        checked={selectionState === 'all'}
                        onChange={toggleAllIngredients}
                        ref={(input) => {
                          if (input) {
                            input.indeterminate = selectionState === 'partial';
                          }
                        }}
                      />
                      <SelectAllLabel>Select All</SelectAllLabel>
                    </SelectAllContainer>
                    <ShoppingListButton onClick={handleAddToShoppingList}>
                      <FaFileAlt /> Add to Shopping List
                    </ShoppingListButton>
                  </RecipeSection>
                </LeftColumn>
                <RightColumn>
                  {recipe.imageUrl && (
                    <RecipeImageContainer $hasVideo={!!recipe.videoUrl}>
                      <RecipeImage src={recipe.imageUrl} alt={recipe.Name || 'Recipe Image'} />
                      {recipe.videoUrl && (
                        <VideoButton
                          onClick={handleVideoClick}
                          title="Watch video on YouTube"
                        >
                          <span>Watch</span>
                          <FaYoutube />
                        </VideoButton>
                      )}
                    </RecipeImageContainer>
                  )}
                </RightColumn>
              </TopSection>
              <InstructionsSection>
                <SectionTitle>Preparation Steps</SectionTitle>
                {recipe['Preparation Steps'] ? (
                  <StepList>
                    {recipe['Preparation Steps'].split('\n')
                      .filter(step => step.trim() !== '')
                      .map((step, index) => (
                        <StepItem key={index}>
                          {step.trim()}
                        </StepItem>
                      ))}
                  </StepList>
                ) : (
                  <p>No instructions available.</p>
                )}
              </InstructionsSection>
              {(recipe.Difficulty || recipe.Notes) && (
                <RecipeSection>
                  <SectionTitle>Additional Information</SectionTitle>
                  {recipe.Notes && <p>Notes: {recipe.Notes}</p>}
                </RecipeSection>
              )}
              <RatingsContainer>
                <RatingSection>
                  <RatingTitle>Recipe Rating</RatingTitle>
                  <StarRating>
                    {[1, 2, 3, 4, 5].map((star) => (
                      <StarIcon key={star} $filled={star <= localAverageRating} />
                    ))}
                    <RatingText>
                      {localAverageRating.toFixed(1)} ({localNumberOfRatings} {localNumberOfRatings === 1 ? 'rating' : 'ratings'})
                    </RatingText>
                  </StarRating>
                </RatingSection>
                <RatingSection>
                  <RatingTitle>{userRating > 0 ? 'Your Rating' : 'Rate this recipe'}</RatingTitle>
                  <StarRating>
                    {[1, 2, 3, 4, 5].map((star) => (
                      <StarIcon
                        key={star}
                        $filled={star <= userRating}
                        onClick={user ? () => handleRatingChange(star) : handleRatingClick}
                      />
                    ))}
                    {userRating > 0 && <RatingValue>{userRating}</RatingValue>}
                  </StarRating>
                </RatingSection>
              </RatingsContainer>
            </ContentLayout>
          </PopupBody>
        </PopupContent>
        {showShareModal && (
          <Modal>
            <h3>Share this recipe</h3>
            <LinkDisplay>{shareableLink}</LinkDisplay>
            <ModalButton onClick={() => copyToClipboard(shareableLink)}>
              Copy Link
            </ModalButton>
            <ModalButton onClick={() => setShowShareModal(false)}>
              Close
            </ModalButton>
          </Modal>
        )}
        <CopyNotification $show={showCopyNotification}>
          Link copied to clipboard!
        </CopyNotification>
        {showLoginModal && (
          <Modal onClose={closeLoginModal}>
            <LoginModal>
              <LoginModalTitle>Login Required</LoginModalTitle>
              <LoginModalText>You must be logged in to use this feature.</LoginModalText>
              <LoginModalButtonContainer>
                <LoginNowButton onClick={handleLoginClick}>Log In Now</LoginNowButton>
                <LoginModalButton onClick={closeLoginModal}>Close</LoginModalButton>
              </LoginModalButtonContainer>
            </LoginModal>
          </Modal>
        )}
      </PopupOverlay>
    </>
  );
};

export default RecipeDetailPopup;