export const parseIngredients = (ingredientsString) => {
    if (!ingredientsString) return [{ amount: '', measurement: '', name: '' }];

    return ingredientsString.split('|').map(ingredient => {
        const trimmedIngredient = ingredient.trim();
        const parts = trimmedIngredient.split(' ');
        
        // Check if the first part is an integer or a fraction
        const isValidAmount = (part) => {
            return /^\d+$/.test(part) || /^\d+\/\d+$/.test(part);
        };
        
        if (isValidAmount(parts[0])) {
            // If there's a valid amount, check if the second part is a measurement
            const hasMeasurement = parts.length > 2 && isNaN(parseFloat(parts[1]));
            if (hasMeasurement) {
                return {
                    amount: parts[0],
                    measurement: parts[1],
                    name: parts.slice(2).join(' ')
                };
            } else {
                return {
                    amount: parts[0],
                    measurement: '',
                    name: parts.slice(1).join(' ')
                };
            }
        } else {
            // If there's no valid amount, put the entire string in the name field
            return {
                amount: '',
                measurement: '',
                name: trimmedIngredient
            };
        }
    });
};