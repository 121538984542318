import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  fetchUserRecipesWithDetails, 
  updateRecipeApproval,  // Add this
  updateRecipe, 
  deleteRecipe, 
  fetchUserMessages, 
  sendMessageReply, 
  updateMessageReadStatus, 
  sendMessageToAdmin, 
  uploadRecipeImage, 
  auth,
  getUserDocument,  // Change this from getUserProfile to getUserDocument
  updateUserDocument  // Add this import
} from '../utils/firebaseUtils';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { FaPlus, FaClock, FaFilter, FaEdit, FaPaperPlane, FaImage, FaEye, FaTrash, FaEnvelope, FaReply, FaSync, FaChevronDown, FaChevronUp, FaFire, FaStar, FaStarHalfAlt, FaUpload, FaYoutube } from 'react-icons/fa';
import RecipeDetailPopup from './RecipeDetailPopup';
import Modal from './Modal';
import UploadProgressModal from './UploadProgressModal';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDownloadURL } from 'firebase/storage';
import { processImage, isValidImageType } from '../utils/imageUtils';
import VideoLinkModal from './VideoLinkModal';
import { getYouTubeThumbnail } from '../utils/youtubeApi';
import { getRecipeStatus, unescapeCommas } from '../utils/recipeUtils';

  // Declare styled components here, inside the component function
  const TabAndContentWrapper = styled.div`
    background-color: #2a2b2f;
    margin: 0;
    border-radius: 0;
    overflow: hidden;
    padding-top: 1px;
    padding-bottom: 20px;

    @media (max-width: 768px) {
      background-color: transparent;
    }
  `;

  const ContentArea = styled.div`
    background-color: #cec3b1;
    padding: 10px 20px 20px 23px;
    border-radius: 15px 15px 15px 15px;
    margin-top: -10px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    min-height: 500px;
    position: relative;
    z-index: 10;

    @media (max-width: 768px) {
      width: 100%;
      padding: 10px;
      border-radius: 0;
    }
  `;

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #2a2b2f;
  }
`;

const InfoItem = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.9rem;
  
  svg {
    margin-right: 5px;
  }
`;

const CookingInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const RecipeDescription = styled.p`
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;


const StarRating = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #f39c12;
  margin-top: 5px;
`;

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #2a2b2f;
  display: flex;
  flex-direction: column;
`;

const PageSubheader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 50px;
  background-color: #2a2b2f;
`;

const SubheaderControls = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledActionButton = styled(Link)`
  background-color: #9eb529;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  white-space: nowrap;
  flex: 1;
  min-width: 120px;

  &:hover {
    background-color: #8ca120;
  }

  svg {
    margin-right: 5px;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    padding: 4px 8px;
  }
`;

const VideoLinkRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const VideoLinkButton = styled(StyledActionButton)`
  background-color: #e74c3c;
  flex: 2;
  
  &:hover {
    background-color: #c0392b;
  }
`;

const EditButton = styled(StyledActionButton)`
  background-color: #3498db;
  flex: 2;
  &:hover {
    background-color: #2980b9;
  }
`;

const ThumbnailCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  white-space: nowrap;
  flex: 1;

  input {
    margin-right: 5px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const MyRecipesButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-top: 1px solid #ddd;
`;

const ImageUploadButton = styled(StyledActionButton)`
  background-color: #3498db;
  
  &:hover {
    background-color: #2980b9;
  }
`;

const StyledRefreshButton = styled(StyledActionButton)`
  background-color: #3498db;
  margin-bottom: 10px;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  width: auto;
`;

const AddNewRecipeButton = styled(StyledRefreshButton)`
  background-color: #9eb529;

  &:hover {
    background-color: #8ca120;
  }
`;

const RecipeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const RecipeCard = styled.div`
  position: relative;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ImageContainer = styled.div`
  height: 200px;
  overflow: hidden;
`;

const RecipeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RecipeContent = styled.div`
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const RecipeTitle = styled.h3`
  margin: 0 0 10px 0;
  color: #2a2b2f;
  font-size: 1.2rem;
`;

const SubmittedBy = styled.div`
  font-size: 0.7rem;
  color: #666;
  margin-top: 2px;
  margin-bottom: 8px;
`;

const FilterHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

const FilterTitle = styled.h4`
  margin-bottom: 0;
  color: #2a2b2f;
  margin-left: 10px;
`;

const Button = styled.button`
  background-color: #9eb529;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 0.9rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #8ca120;
  }

  svg {
    margin-right: 5px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  background-color: transparent;
  padding: 0 0 0 5px; // Added 3px left padding
  padding-top: 5px;
  margin-top: 5px;
  margin-bottom: -1px;
  overflow-x: auto;
  min-height: 55px;
  position: relative;
  z-index: 0;
  width: 95%; // Match the width of ContentArea
  margin-left: auto;
  margin-right: auto;
`;

const Tab = styled.button`
  background-color: ${props => props.$active ? '#cec3b1' : '#beb5a3'};
  color: ${props => props.$ctive ? '#2a2b2f' : '#2a2b2f'};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1rem;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  border-radius: 10px 10px 0 0;
  margin-right: 2px;
  margin-bottom: 5px;
  outline: none;
  position: relative; // Add this line

  &:hover {
    background-color: ${props => props.active ? '#cec3b1' : '#c8bfad'};
    color: #2a2b2f;
  }
`;

const CollapsibleSection = styled.div`
  margin-bottom: 20px;
`;

const CollapsibleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
`;

const CollapsibleTitle = styled.h4`
  margin: 0;
`;

const CollapsibleContent = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 5px 5px;
`;

const MessageCount = styled.span`
  background-color: #007bff;
  color: white;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.8rem;
  margin-left: 10px;
`;

const UnreadMessagesSection = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h4`
  margin-bottom: 10px;
  color: #2a2b2f;
`;

const ReplyItem = styled.div`
  background-color: ${props => props.isAdminReply ? '#e6f3ff' : '#f0f0f0'};
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  font-size: 0.9rem;
`;

const ReplyText = styled.p`
  margin: 0 0 5px 0;
`;

const ReplyTimestamp = styled.span`
  font-size: 0.8rem;
  color: #666;
`;

const TabCount = styled.span`
  background-color: #2a2b2f;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8rem;
  margin-left: 8px;
`;

const MessageSection = styled.div`
  margin-top: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
`;

const MessageTitle = styled.h3`
  color: #2a2b2f;
  margin-bottom: 15px;
`;

const MessageItem = styled.li`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
`;

const ReadCheckboxContainer = styled.div`
  position: absolute;
  top: 35px;
  right: 10px;
  display: flex;
`;

const ReadCheckbox = styled.input`
  margin-right: 5px;
`;

const ReadLabel = styled.label`
  font-size: 0.8rem;
  color: #666;
  margin-right: 5px;
`;

const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const MessageDate = styled.span`
  font-size: 0.9rem;
  color: #666;
`;

const MessageContent = styled.p`
  margin-bottom: 10px;
`;

const ReplyForm = styled.form`
  display: flex;
  margin-top: 10px;
`;

const ReplyInput = styled.input`
  flex-grow: 1;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
`;

const ReplyButton = styled.button`
  background-color: #9eb529;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;

  &:hover {
    background-color: #8ca120;
  }
`;

const AdminTag = styled.span`
  background-color: #e74c3c;
  color: white;
  border-radius: 5px;
  padding: 2px 6px;
  font-size: 0.8rem;
  margin-left: 8px;
`;

const UnreadIndicator = styled.div`
  position: absolute;
  top: 4px;  // Changed from 0 to 4px
  right: 4px;  // Changed from 0 to 4px
  width: 12px;
  height: 12px;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(0, 150, 0, 0.9) 90%,
    rgba(0, 55, 0, 0.6) 50%,
    rgba(0, 5, 0, 0.2) 10%
  );
  border-radius: 50%;
  transform: translate(0, 0);  // Removed the translation

  &::after {
    content: '';
    position: absolute;
    top: 2.4px;
    left: 3.1px;
    width: 6px;
    height: 6px;
    background: radial-gradient(
      circle at 50% 50%,
      rgba(255, 255, 255, .5) 10%,
      rgba(255, 255, 255, .4) 15%,
      rgba(255, 255, 255, .3) 20%,
      rgba(255, 255, 255, .2) 40%,
      rgba(255, 255, 255, .1) 90%
    );
    border-radius: 50%;
  }
`;

const PreviewButton = styled(StyledActionButton)`
  background-color: #2a6496;
  &:hover {
    background-color: #1e4b70;
  }
`;

const DeleteButton = styled(StyledActionButton)`
  background-color: #2a2b2f;
  flex: 1;
  &:hover {
    background-color: #c0392b;
  }
`;

const StatusBanner = styled.div`
  position: absolute;
  top: 25px;
  right: -50px;
  color: white;
  padding: 5px 0;
  width: 200px;
  text-align: center;
  transform: rotate(45deg);
  font-size: 0.8rem;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  z-index: 1;
`;

const AcceptedBanner = styled(StatusBanner)`
  background: linear-gradient(
    to right,
    rgba(0, 150, 0, 0.9) 0%,
    rgba(0, 150, 0, 0.9) 60%,
    rgba(0, 55, 0, 0.6) 100%
  );
  box-shadow: 0 0 10px rgba(0, 150, 0, 0.6);
`;

const InProgressBanner = styled(StatusBanner)`
  background-color: #3498db;
`;

const PendingBanner = styled(StatusBanner)`
  background-color: #f39c12;
`;

const RejectedBanner = styled(StatusBanner)`
  background-color: #e74c3c;
`;

const MessageThread = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
`;

const MessageThreadHeader = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MessageThreadTitle = styled.span`
  font-weight: bold;
`;

const MessageThreadDate = styled.span`
  font-size: 0.8rem;
  color: #666;
`;

const MessageThreadContent = styled.div`
  padding: 10px;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const ApprovalModal = styled.div`
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  animation: ${fadeIn} 0.3s ease-out;
  max-width: 80%;
  width: 400px;

  &.fade-out {
    animation: ${fadeOut} 0.3s ease-in forwards;
  }
`;

const ApprovalModalText = styled.p`
  font-size: 1.1rem;
  color: #2a2b2f;
  margin-bottom: 20px;
`;

const ApprovalModalIcon = styled.div`
  font-size: 3rem;
  color: #9eb529;
  margin-bottom: 15px;
`;

const OkButton = styled.button`
  background-color: #9eb529;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #8ca120;
  }
`;

const MyRecipes = () => {
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [activeTab, setActiveTab] = useState('inProgress');
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [recipeToDelete, setRecipeToDelete] = useState(null);
  const [messages, setMessages] = useState([]);
  const [replyTexts, setReplyTexts] = useState({});
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const location = useLocation();
  const [isReadMessagesOpen, setIsReadMessagesOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadTask, setUploadTask] = useState(null);
  const [uploadCancelled, setUploadCancelled] = useState(false);
  const [showVideoLinkModal, setShowVideoLinkModal] = useState(false);
  const [selectedRecipeForVideo, setSelectedRecipeForVideo] = useState(null);
  const [useVideoThumbnail, setUseVideoThumbnail] = useState({});
  const [openThreads, setOpenThreads] = useState({});
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [isTrustedUser, setIsTrustedUser] = useState(false);

  // Add this effect to log state changes
  useEffect(() => {
    //console.log('showDeleteWarning:', showDeleteWarning);
    //console.log('recipeToDelete:', recipeToDelete);
  }, [showDeleteWarning, recipeToDelete]);

  useEffect(() => {
    const checkUserTrustedStatus = async () => {
      if (user && user.uid) {
        try {
          const userDoc = await getUserDocument(user.uid);
          console.log('Full user document:', userDoc);
          const trusted = userDoc?.trusted || false;
          console.log('User trusted status:', trusted);
          setIsTrustedUser(trusted);

          // If the user doesn't have a trusted field, set it to true (for testing purposes)
          if (userDoc && !userDoc.hasOwnProperty('trusted')) {
            console.log('Setting trusted status to true for testing');
            await updateUserDocument(user.uid, { trusted: true });
            setIsTrustedUser(true);
          }
        } catch (error) {
          //console.error('Error fetching user document:', error);
        }
      }
    };

    checkUserTrustedStatus();
  }, [user]);

  const getYouTubeVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const fetchRecipes = useCallback(async () => {
    if (!user || !user.uid) {
      //console.log("No user or user ID available. Skipping data fetch.");
      return;
    }

    try {
      setLoading(true);
      const userRecipes = await fetchUserRecipesWithDetails(user.uid);
      //console.log('Fetched user recipes:', userRecipes);
      setRecipes(userRecipes.map(recipe => {
        //console.log('Processing recipe:', recipe);
        return {
          ...recipe,
          youtubeLink: recipe.videoUrl
        };
      }));

      // Initialize useVideoThumbnail state based on fetched recipes
      const thumbnailState = {};
      userRecipes.forEach(recipe => {
        if (recipe.imageUrl && recipe.videoUrl) {
          const videoId = getYouTubeVideoId(recipe.videoUrl);
          if (videoId) {
            thumbnailState[recipe.id] = recipe.imageUrl.includes('img.youtube.com');
          }
        }
      });
      setUseVideoThumbnail(thumbnailState);

    } catch (error) {
      //console.error('Error fetching user data:', error);
      setError('Failed to fetch data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchRecipes();
    }
  }, [user, fetchRecipes, isTrustedUser]); // Add isTrustedUser here

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setLoading(false);
      } else {
        // Redirect to login if not authenticated
        navigate('/loginscreen');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const loadMessages = async () => {
      if (user && user.uid) {
        try {
          //console.log('Loading messages for user:', user.uid);
          const fetchedMessages = await fetchUserMessages(user.uid);
          //console.log('Fetched messages:', fetchedMessages);
          setMessages(fetchedMessages);
          setUnreadMessages(fetchedMessages.filter(msg => !msg.isRead).length);
        } catch (error) {
          //console.error('Error fetching messages:', error);
        }
      }
    };

    if (user) {
      loadMessages();
    }
  }, [user]);

  const updateRecipeStatus = async (recipeId, updates) => {
    try {
      const updatedRecipe = {
        ...updates,
        approved: false,
        approvalRequested: false,
        rejected: false,
        status: 'inProgress'
      };

      await updateRecipe(recipeId, updatedRecipe);

      setRecipes(prevRecipes =>
        prevRecipes.map(recipe =>
          recipe.id === recipeId ? {
            ...recipe,
            ...updatedRecipe
          } : recipe
        )
      );

      //console.log('Recipe status updated to In Progress and requires approval');
    } catch (error) {
      //console.error('Error updating recipe status:', error);
      alert('Failed to update recipe status. Please try again.');
    }
  };

  const handleImageUpload = async (recipeId) => {
    if (!auth.currentUser) {
      alert('You must be logged in to upload images.');
      return;
    }

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        //console.log('Selected file:', file.name, 'Size:', file.size);

        if (!isValidImageType(file)) {
          alert('Invalid file type. Please upload a JPEG, PNG, GIF, or WebP image.');
          return;
        }

        try {
          setIsUploading(true);
          setUploadProgress(0);
          setUploadCancelled(false);

          //console.log('Processing image...');
          const processedFile = await processImage(file);
          //console.log('Processed file size:', processedFile.size);

          //console.log('Starting upload for file:', processedFile.name);
          //console.log('Recipe ID:', recipeId);

          const { uploadTask, cancelled } = await uploadRecipeImage(processedFile, recipeId, (progress) => {
            if (!uploadCancelled) {
              //console.log('Upload progress:', progress);
              setUploadProgress(progress);
            }
          });

          setUploadTask(uploadTask);

          if (cancelled) {
            //console.log('Upload was cancelled');
            return;
          }

          const downloadURL = await new Promise((resolve, reject) => {
            uploadTask.on('state_changed',
              null,
              reject,
              async () => {
                try {
                  const url = await getDownloadURL(uploadTask.snapshot.ref);
                  resolve(url);
                } catch (error) {
                  reject(error);
                }
              }
            );
          });

          if (!uploadCancelled) {
            //console.log('Download URL:', downloadURL);
            await updateRecipeStatus(recipeId, { imageUrl: downloadURL });
            alert('Image uploaded successfully! The recipe status has been set to In Progress.');
          }
        } catch (error) {
          if (!uploadCancelled) {
            //console.error('Error in handleImageUpload:', error);
            alert(`Failed to upload image: ${error.message}`);
          }
        } finally {
          setIsUploading(false);
          setUploadTask(null);
          setUploadProgress(0);
          setUploadCancelled(false);
        }
      }
    };
    input.click();
  };

  const handleCancelUpload = () => {
    if (uploadTask) {
      setUploadCancelled(true);
      uploadTask.cancel();
      setIsUploading(false);
      setUploadProgress(0);
      setUploadTask(null);
      //console.log('Upload cancelled by user');
    } else {
      console.warn('No upload task to cancel');
    }
  };

  const handlePreview = (recipe) => {
    setSelectedRecipe(recipe);
  };

  const closePopup = () => {
    setSelectedRecipe(null);
  };

  const handleRequestApproval = async (recipeId) => {
    try {
      //console.log('Requesting approval for recipe:', recipeId);
      const lastUpdated = new Date().toISOString();

      // Update the recipe status
      await updateRecipeApproval(recipeId, {
        approvalRequested: true,
        approved: null,
        rejected: false,
        lastUpdated
      });

      //console.log('Approval requested successfully');

      // Update local state
      setRecipes(prevRecipes => 
        prevRecipes.map(recipe => 
          recipe.id === recipeId ? { 
            ...recipe, 
            approvalRequested: true,
            approved: null,
            rejected: false,
            lastUpdated
          } : recipe
        )
      );
      
      // Send message to admin
      const recipe = recipes.find(r => r.id === recipeId);
      if (recipe) {
        //console.log('Sending message to admin for recipe:', recipe.Name);
        await sendMessageToAdmin({
          subject: recipe.Name,
          content: `User ${user.displayName || user.email} has requested approval for recipe: ${recipe.Name}`,
          recipeId: recipeId,
          recipeName: recipe.Name
        });
        //console.log('Approval request message sent to admin');
      } else {
        //console.error('Recipe not found for sending approval request');
      }

      // Show the approval modal
      setShowApprovalModal(true);
      // Removed the automatic closing setTimeout
    } catch (error) {
      //console.error('Error requesting approval:', error);
      alert(`Failed to request approval: ${error.message}`);
    }
  };

  const handlePublishToSite = async (recipeId) => {
    try {
      await updateRecipeApproval(recipeId, {
        approvalRequested: false,
        approved: true,
        lastUpdated: new Date().toISOString()
      });

      setRecipes(prevRecipes => 
        prevRecipes.map(recipe => 
          recipe.id === recipeId ? { 
            ...recipe, 
            approvalRequested: false,
            approved: true,
            lastUpdated: new Date().toISOString()
          } : recipe
        )
      );

      alert('Recipe published successfully!');
    } catch (error) {
      //console.error('Error publishing recipe:', error);
      alert(`Failed to publish recipe: ${error.message}`);
    }
  };

  const getRecipeCount = (status) => {
    return recipes.filter(recipe => getRecipeStatus(recipe) === status).length;
  };

  const handleDelete = useCallback((recipe) => {
    //console.log('handleDelete called', recipe);
    setRecipeToDelete(recipe);
    setShowDeleteWarning(true);
  }, []);

  const confirmDelete = useCallback(async () => {
    //console.log('confirmDelete called', recipeToDelete);
    if (recipeToDelete && recipeToDelete.id) {
      try {
        await deleteRecipe(recipeToDelete.id);
        setRecipes(prevRecipes => prevRecipes.filter(r => r.id !== recipeToDelete.id));
        //console.log('Recipe deleted successfully');
      } catch (error) {
        //console.error('Error deleting recipe:', error);
        alert('Failed to delete recipe. Please try again.');
      } finally {
        setShowDeleteWarning(false);
        setRecipeToDelete(null);
      }
    } else {
      //console.error('Cannot delete: recipeToDelete is null or missing id');
    }
  }, [recipeToDelete]);

  const cancelDelete = useCallback(() => {
    //console.log('cancelDelete called');
    setShowDeleteWarning(false);
    setRecipeToDelete(null);
  }, []);

  const handleReplyChange = (messageId, text) => {
    setReplyTexts(prev => ({ ...prev, [messageId]: text }));
  };

  const handleReplySubmit = async (e, messageId) => {
    e.preventDefault();
    const replyText = replyTexts[messageId];
    if (!replyText || !replyText.trim()) {
      //console.log('Reply text is empty, not sending.');
      return;
    }

    try {
      const currentUser = auth.currentUser;
      await sendMessageReply(messageId, replyText, {
        uid: currentUser.uid,
        isAdmin: false
      });
      //console.log('Reply sent successfully');

      // Fetch updated messages
      const updatedMessages = await fetchUserMessages(currentUser.uid);
      setMessages(updatedMessages);
      setReplyTexts(prev => ({ ...prev, [messageId]: '' }));
      //console.log('Local state updated');
    } catch (error) {
      //console.error('Error sending reply:', error);
      alert('Failed to send reply. Please try again.');
    }
  };

  const handleReadChange = async (messageId, isRead) => {
    try {
      await updateMessageReadStatus(messageId, isRead);
      setMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.id === messageId ? { ...msg, isRead } : msg
        )
      );
      setUnreadMessages(prevUnread => isRead ? prevUnread - 1 : prevUnread + 1);
    } catch (error) {
      //console.error('Error updating message read status:', error);
      alert('Failed to update message status. Please try again.');
    }
  };

  const handleVideoLinkClick = (recipe) => {
    setSelectedRecipeForVideo(recipe);
    setShowVideoLinkModal(true);
  };

  const handleVideoLinkSave = async (recipeId, videoUrl) => {
    try {
      await updateRecipeStatus(recipeId, { 
        videoUrl,
        approved: false,
        approvalRequested: false,
        status: 'inProgress'
      });
      setShowVideoLinkModal(false);
      alert('Video link updated successfully! The recipe status has been set to In Progress and requires approval.');
    } catch (error) {
      //console.error('Error updating video link:', error);
      alert('Failed to update video link. Please try again.');
    }
  };

  const handleVideoLinkDelete = async (recipeId) => {
    try {
      await updateRecipeStatus(recipeId, { videoUrl: null });
      setShowVideoLinkModal(false);
      alert('Video link deleted successfully! The recipe status has been set to In Progress.');
    } catch (error) {
      //console.error('Error deleting video link:', error);
      alert('Failed to delete video link. Please try again.');
    }
  };

  const checkImageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  const handleUseVideoThumbnailChange = async (recipeId, youtubeLink, isChecked) => {
    try {
      let thumbnailUrl = null;
      if (isChecked) {
        thumbnailUrl = await getYouTubeThumbnail(youtubeLink);
        //console.log('Fetched thumbnail URL:', thumbnailUrl);
        
        // Check if the maxresdefault.jpg exists
        const imageExists = await checkImageExists(thumbnailUrl);
        
        if (!imageExists) {
          //console.log('maxresdefault.jpg not found, falling back to hqdefault.jpg');
          thumbnailUrl = thumbnailUrl.replace('maxresdefault.jpg', 'hqdefault.jpg');
        }
        
        // Update the recipe with the new thumbnail URL
        await updateRecipe(recipeId, { imageUrl: thumbnailUrl });
      } else {
        // If unchecked, revert to a default image or remove the imageUrl
        await updateRecipe(recipeId, { imageUrl: null });
      }

      // Update local state
      setUseVideoThumbnail(prev => ({ ...prev, [recipeId]: isChecked }));
      setRecipes(prevRecipes => prevRecipes.map(recipe => 
        recipe.id === recipeId 
          ? { ...recipe, imageUrl: isChecked ? thumbnailUrl : null } 
          : recipe
      ));

    } catch (error) {
      //console.error('Error updating recipe with YouTube thumbnail:', error);
      alert('Failed to update recipe with YouTube thumbnail. Please try again.');
    }
  };

  const toggleThread = (threadId) => {
    setOpenThreads(prev => ({
      ...prev,
      [threadId]: !prev[threadId]
    }));
  };

  const sortAndGroupMessages = (messages) => {
    // Group messages by recipe name
    const threads = messages.reduce((acc, message) => {
      const recipeName = message.recipeName || 'Uncategorized';
      if (!acc[recipeName]) {
        acc[recipeName] = [];
      }
      acc[recipeName].push(message);
      return acc;
    }, {});

    // Sort threads by the latest message date
    return Object.entries(threads).sort((a, b) => {
      const lastDateA = Math.max(...a[1].map(m => m.timestamp.toMillis()));
      const lastDateB = Math.max(...b[1].map(m => m.timestamp.toMillis()));
      return lastDateB - lastDateA;
    });
  };

  const renderMessageThreads = (messages) => {
    const sortedThreads = sortAndGroupMessages(messages);

    return sortedThreads.map(([recipeName, threadMessages]) => {
      const latestMessage = threadMessages[threadMessages.length - 1];
      const threadId = recipeName;
      const isOpen = openThreads[threadId] || false;

      return (
        <MessageThread key={threadId}>
          <MessageThreadHeader onClick={() => toggleThread(threadId)}>
            <MessageThreadTitle>{recipeName}</MessageThreadTitle>
            <MessageThreadDate>{formatTimestamp(latestMessage.timestamp)}</MessageThreadDate>
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </MessageThreadHeader>
          <MessageThreadContent isOpen={isOpen}>
            {threadMessages.map(message => renderMessageItem(message))}
          </MessageThreadContent>
        </MessageThread>
      );
    });
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'messages':
        //console.log('Rendering messages:', messages);
        return (
          <MessageSection>
            <MessageTitle><FaEnvelope /> Messages</MessageTitle>
            {messages.length === 0 ? (
              <p>No messages found.</p>
            ) : (
              <>
                <UnreadMessagesSection>
                  <SectionTitle>Unread Messages</SectionTitle>
                  {renderMessageThreads(messages.filter(message => !message.isRead))}
                </UnreadMessagesSection>

                <CollapsibleSection>
                  <CollapsibleHeader onClick={() => setIsReadMessagesOpen(!isReadMessagesOpen)}>
                    <CollapsibleTitle>
                      Read Messages
                      <MessageCount>{messages.filter(message => message.isRead).length}</MessageCount>
                    </CollapsibleTitle>
                    {isReadMessagesOpen ? <FaChevronUp /> : <FaChevronDown />}
                  </CollapsibleHeader>
                  <CollapsibleContent isOpen={isReadMessagesOpen}>
                    {renderMessageThreads(messages.filter(message => message.isRead))}
                  </CollapsibleContent>
                </CollapsibleSection>
              </>
            )}
          </MessageSection>
        );
      default:
        const filteredRecipes = recipes.filter(recipe => 
          activeTab === 'all' || getRecipeStatus(recipe) === activeTab
        );
        return (
          <>
            <FilterHeader>
              <FilterTitle><FaFilter /> My Recipes</FilterTitle>
            </FilterHeader>

            {filteredRecipes.length > 0 ? (
              <RecipeGrid>
                {filteredRecipes.map(recipe => renderRecipeCard(recipe))}
              </RecipeGrid>
            ) : (
              <p>No recipes found in this category. {activeTab === 'all' ? "Click 'Add New Recipe' to get started!" : ""}</p>
            )}
          </>
        );
    }
  };

  const renderRecipeCard = (recipe) => {
    const cookingMethod = recipe['Cooking Method'];
    const displayCookingMethod = Array.isArray(cookingMethod) 
      ? cookingMethod.join(', ') 
      : (typeof cookingMethod === 'string' ? cookingMethod : 'Not specified');

    const truncatedDescription = (recipe.Description || recipe.description || 'No description available').slice(0, 150) + (recipe.Description?.length > 150 ? '...' : '');

    const renderStatusBanner = () => {
      if (recipe.approved) {
        return <AcceptedBanner>Accepted</AcceptedBanner>;
      } else if (recipe.rejected) {
        return <RejectedBanner>Rejected</RejectedBanner>;
      } else if (recipe.approvalRequested) {
        return <PendingBanner>Pending</PendingBanner>;
      } else {
        return <InProgressBanner>In Progress</InProgressBanner>;
      }
    };

    console.log('Rendering recipe card:', recipe.Name); // Add this log
    console.log('Is trusted user:', isTrustedUser); // Add this log
    console.log('Recipe approval status:', { 
      approved: recipe.approved, 
      approvalRequested: recipe.approvalRequested, 
      rejected: recipe.rejected 
    }); // Add this log

    return (
      <RecipeCard key={recipe.id}>
        {renderStatusBanner()}
        <ImageContainer>
          {recipe.imageUrl ? (
            <RecipeImage src={recipe.imageUrl} alt={recipe.Name} />
          ) : (
            <div style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#e0e0e0'
            }}>
              <FaImage size={50} color="#999" />
            </div>
          )}
        </ImageContainer>
        <RecipeContent>
          <RecipeTitle>{unescapeCommas(recipe.Name) || 'Untitled Recipe'}</RecipeTitle>
          <SubmittedBy>
            Submitted by: {recipe.Submitter || recipe.displayName || 'Unknown'}
          </SubmittedBy>
          <CookingInfo>
            <InfoItem>
              <FaFire style={{ color: '#ba5216' }} />
              {displayCookingMethod}
            </InfoItem>
            <InfoItem>
              <FaClock style={{ color: '#2a2b2f' }} />
              Prep: {recipe['Prep Time'] || 'N/A'}
            </InfoItem>
            <InfoItem>
              <FaClock style={{ color: '#2a2b2f' }} />
              Cook: {recipe['Cooking Time'] || 'N/A'}
            </InfoItem>
          </CookingInfo>
          <RecipeDescription>
            {truncatedDescription}
          </RecipeDescription>
          <StarRating>
            {recipe.averageRating ? renderStarRating(recipe.averageRating) : 'Not rated'} 
            <span style={{ marginLeft: '5px' }}>({recipe.numberOfRatings || 0})</span>
          </StarRating>
        </RecipeContent>
        <MyRecipesButtonsContainer>
          <PreviewButton 
            as="button"
            onClick={() => handlePreview(recipe)}
          >
            <FaEye /> Preview
          </PreviewButton>
          {(!recipe.approved && !recipe.approvalRequested && !recipe.rejected) && (
            isTrustedUser ? (
              <StyledActionButton 
                as="button"
                onClick={() => handlePublishToSite(recipe.id)}
              >
                <FaPaperPlane /> Publish to Site
              </StyledActionButton>
            ) : (
              <StyledActionButton 
                as="button"
                onClick={() => handleRequestApproval(recipe.id)}
              >
                <FaPaperPlane /> Request Approval
              </StyledActionButton>
            )
          )}
          <ImageUploadButton 
            as="button"
            onClick={() => handleImageUpload(recipe.id)}
          >
            <FaUpload /> Upload Image
          </ImageUploadButton>
          <VideoLinkRow>
            <VideoLinkButton 
              as="button"
              onClick={() => handleVideoLinkClick(recipe)}
            >
              <FaYoutube /> {recipe.videoUrl ? "Video Linked!" : "Link Video"}
            </VideoLinkButton>
            {recipe.videoUrl && (
              <ThumbnailCheckboxContainer>
                <input
                  type="checkbox"
                  id={`use-video-thumbnail-${recipe.id}`}
                  checked={useVideoThumbnail[recipe.id] || false}
                  onChange={(e) => handleUseVideoThumbnailChange(recipe.id, recipe.videoUrl, e.target.checked)}
                />
                <label htmlFor={`use-video-thumbnail-${recipe.id}`}>Use Thumbnail</label>
              </ThumbnailCheckboxContainer>
            )}
          </VideoLinkRow>
          <ButtonGroup>
            <EditButton 
              to={`/recipe/edit/${recipe.id}`} 
              state={{ recipe: recipe }}
            >
              <FaEdit /> Edit
            </EditButton>
            <DeleteButton 
              as="button"
              onClick={() => handleDelete(recipe)}
            >
              <FaTrash /> Delete
            </DeleteButton>
          </ButtonGroup>
          {isUploading && (
            <StyledActionButton as="button" onClick={handleCancelUpload}>
              <FaTrash /> Cancel Upload
            </StyledActionButton>
          )}
        </MyRecipesButtonsContainer>
      </RecipeCard>
    );
  };

  const renderStarRating = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return (
      <>
        {[...Array(fullStars)].map((_, i) => <FaStar key={`full-${i}`} />)}
        {hasHalfStar && <FaStarHalfAlt />}
        {[...Array(emptyStars)].map((_, i) => <FaStar key={`empty-${i}`} />)}
        <span style={{ marginLeft: '5px' }}>({rating.toFixed(1)})</span>
      </>
    );
  };

  const handleTabChange = (tab, recipeId = null) => {
    //console.log('Switching to tab:', tab);
    setActiveTab(tab);
    if (tab === 'messages' && recipeId) {
      setTimeout(() => {
        const messageElement = document.getElementById(`message-${recipeId}`);
        if (messageElement) {
          messageElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  };

  const refreshRecipes = async () => {
    try {
      setIsRefreshing(true);
      const userRecipes = await fetchUserRecipesWithDetails(user.uid);
      //console.log('Refreshed user recipes:', userRecipes);
      setRecipes(userRecipes);
    } catch (error) {
      //console.error('Error refreshing recipes:', error);
      setError('Failed to refresh recipes. Please try again.');
    } finally {
      setIsRefreshing(false);
    }
  };

  const renderMessageItem = (message) => (
    <MessageItem key={message.id} id={`message-${message.recipeId}`}>
      <MessageHeader>
        <strong>{message.subject}</strong>
        <MessageDate>{formatTimestamp(message.timestamp)}</MessageDate>
      </MessageHeader>
      <ReadCheckboxContainer>
        <ReadCheckbox
          id={`read-checkbox-${message.id}`}
          type="checkbox"
          checked={message.isRead}
          onChange={(e) => handleReadChange(message.id, e.target.checked)}
        />
        <ReadLabel htmlFor={`read-checkbox-${message.id}`}>Mark as Read</ReadLabel>
      </ReadCheckboxContainer>
      <MessageContent>{message.content}</MessageContent>
      {message.recipeId && (
        <MessageContent>
          Related Recipe: <Link 
            to={`/recipe/${message.recipeId}`}
            style={{
              color: '#2a2b2f',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            View Recipe
          </Link>
        </MessageContent>
      )}
      {message.from === 'admin' && <AdminTag>From Admin</AdminTag>}
      {message.replies && message.replies.map((reply, index) => (
        <ReplyItem key={index} isAdminReply={reply.isAdminReply}>
          <ReplyText>{reply.text}</ReplyText>
          <ReplyTimestamp>{formatTimestamp(reply.timestamp)}</ReplyTimestamp>
        </ReplyItem>
      ))}
      <ReplyForm onSubmit={(e) => handleReplySubmit(e, message.id)}>
        <ReplyInput
          type="text"
          value={replyTexts[message.id] || ''}
          onChange={(e) => handleReplyChange(message.id, e.target.value)}
          placeholder="Type your reply..."
        />
        <ReplyButton type="submit"><FaReply /> Reply</ReplyButton>
      </ReplyForm>
    </MessageItem>
  );

  const handleCloseApprovalModal = () => {
    setShowApprovalModal(false);
  };

  if (loading) return <PageContainer><PageSubheader>Loading...</PageSubheader></PageContainer>;
  if (error) return <PageContainer><PageSubheader>Error: {error}</PageSubheader></PageContainer>;
  if (!user) {
    //console.log("No user found. Redirecting to login.");
    navigate('/loginscreen');
    return null;
  }

  const handleAddNewRecipe = () => {
    navigate('/recipe/new');
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };
  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <PageSubheader>
          <SubheaderControls>
            <StyledRefreshButton onClick={refreshRecipes} disabled={isRefreshing}>
              {isRefreshing ? 'Refreshing...' : <><FaSync /> Refresh</>}
            </StyledRefreshButton>
            <AddNewRecipeButton as="button" onClick={handleAddNewRecipe}>
              <FaPlus /> Add New Recipe
            </AddNewRecipeButton>
          </SubheaderControls>
        </PageSubheader>

        <TabAndContentWrapper>
          <TabContainer>
            <Tab 
              $active={activeTab === 'inProgress'}
              onClick={() => handleTabChange('inProgress')}
            >
              In Progress <TabCount>{getRecipeCount('inProgress')}</TabCount>
            </Tab>
            <Tab 
              $active={activeTab === 'pending'}
              onClick={() => handleTabChange('pending')}
            >
              Pending <TabCount>{getRecipeCount('pending')}</TabCount>
            </Tab>
            <Tab 
              $active={activeTab === 'accepted'}
              onClick={() => handleTabChange('accepted')}
            >
              Accepted <TabCount>{getRecipeCount('accepted')}</TabCount>
            </Tab>
            <Tab 
              $active={activeTab === 'rejected'}
              onClick={() => handleTabChange('rejected')}
            >
              Rejected <TabCount>{getRecipeCount('rejected')}</TabCount>
            </Tab>
            <Tab 
              $active={activeTab === 'all'}
              onClick={() => handleTabChange('all')}
            >
              All Recipes <TabCount>{recipes.length}</TabCount>
            </Tab>
            <Tab 
              $active={activeTab === 'messages'}
              onClick={() => handleTabChange('messages')}
            >
              Messages <TabCount>{messages.length}</TabCount>
              {unreadMessages > 0 && <UnreadIndicator />}
            </Tab>
          </TabContainer>

          <ContentArea>
            {renderContent()}
          </ContentArea>
        </TabAndContentWrapper>

        {selectedRecipe && (
          <PopupOverlay onClick={closePopup}>
            <RecipeDetailPopup 
              recipe={selectedRecipe} 
              onClose={closePopup}
              user={user}
            />
          </PopupOverlay>
        )}

        <Modal isOpen={showDeleteWarning} onClose={cancelDelete}>
          <h2>Confirm Delete</h2>
          <p>Are you sure you want to delete the recipe "{recipeToDelete?.Name || 'Untitled Recipe'}"? This action cannot be undone.</p>
          <ButtonGroup>
            <Button onClick={cancelDelete}>Cancel</Button>
            <Button onClick={confirmDelete} style={{backgroundColor: '#e74c3c'}}>Delete</Button>
          </ButtonGroup>
        </Modal>

        {isUploading && (
          <UploadProgressModal 
            progress={uploadProgress} 
            onCancel={handleCancelUpload}
          />
        )}

        <VideoLinkModal
          isOpen={showVideoLinkModal}
          onClose={() => setShowVideoLinkModal(false)}
          recipe={selectedRecipeForVideo}
          onSave={handleVideoLinkSave}
          onDelete={handleVideoLinkDelete}
        />

        {showApprovalModal && (
          <ApprovalModal>
            <ApprovalModalIcon>
              <FaPaperPlane />
            </ApprovalModalIcon>
            <ApprovalModalText>
              Your recipe is now pending. It has been sent to admin for approval. Once approved it will be published.
            </ApprovalModalText>
            <OkButton onClick={handleCloseApprovalModal}>OK</OkButton>
          </ApprovalModal>
        )}
      </PageContainer>
    </>
  );
};

export default MyRecipes;