import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fetchDropdownOptions, updateDropdownOptions, fetchCookingTypes, updateCookingTypes } from '../utils/firebaseUtils';
import styled from 'styled-components';
import CookingMethodsManager from './CookingMethodsManager';

const Container = styled.div`
  padding: 15px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
`;

const Title = styled.h2`
  margin-bottom: 15px;
  font-size: 1.2rem;
`;

const GameTypeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 15px;
`;

const GameTypeColumn = styled.div`
  width: 135px;
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 6px;
`;

const GameTypeTitle = styled.h4`
  margin-bottom: 6px;
  font-size: 0.85rem;
`;

const DraggableItem = styled.div`
  padding: 4px;
  margin-bottom: 4px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddItemForm = styled.form`
  display: flex;
  margin-bottom: 6px;
  align-items: center;
`;

const AddItemInput = styled.input`
  flex-grow: 1;
  padding: 2px 4px;
  margin-right: 2px;
  font-size: 0.7rem;
  width: calc(100% - 24px); // Adjust width to leave space for the button
  border: 1px solid #ccc;
  border-radius: 2px;
`;

const AddItemButton = styled.button`
  padding: 2px 4px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.7rem;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-size: 9px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SaveButton = styled.button`
  padding: 6px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
`;

const AddColumnForm = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const AddColumnInput = styled.input`
  padding: 4px 8px;
  margin-right: 8px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const AddColumnButton = styled.button`
  padding: 4px 8px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
`;

const CancelButton = styled.button`
  padding: 6px 12px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
`;

const SectionTitle = styled.h3`
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 1.1rem;
`;

const AdminDropdownManager = () => {
  const [gameSettings, setGameSettings] = useState({});
  const [cookingTypes, setCookingTypes] = useState([]);
  const [newItems, setNewItems] = useState({});
  const [newColumnName, setNewColumnName] = useState('');
  const [originalGameSettings, setOriginalGameSettings] = useState({});
  const [originalCookingTypes, setOriginalCookingTypes] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const gameOptions = await fetchDropdownOptions();
        setGameSettings(gameOptions);
        setOriginalGameSettings(gameOptions);

        const cookingTypesData = await fetchCookingTypes();
        //console.log('Fetched cooking types:', cookingTypesData);
        setCookingTypes(cookingTypesData);
        setOriginalCookingTypes(cookingTypesData);
      } catch (error) {
        //console.error("Failed to load data:", error);
      }
    };

    loadData();
  }, []);

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const newGameSettings = { ...gameSettings };
    const sourceGameType = source.droppableId;
    const destinationGameType = destination.droppableId;

    if (sourceGameType === destinationGameType) {
      const items = Array.from(newGameSettings[sourceGameType]);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
      newGameSettings[sourceGameType] = items;
    } else {
      const sourceItems = Array.from(newGameSettings[sourceGameType]);
      const destItems = Array.from(newGameSettings[destinationGameType]);
      const [movedItem] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, movedItem);
      newGameSettings[sourceGameType] = sourceItems;
      newGameSettings[destinationGameType] = destItems;
    }

    setGameSettings(newGameSettings);
  };

  const handleAddItem = (gameType, e) => {
    e.preventDefault();
    const newItem = newItems[gameType].trim();
    if (newItem && !gameSettings[gameType].includes(newItem)) {
      setGameSettings(prevSettings => ({
        ...prevSettings,
        [gameType]: [...prevSettings[gameType], newItem]
      }));
      setNewItems(prevNewItems => ({
        ...prevNewItems,
        [gameType]: ''
      }));
    }
  };

  const handleDeleteItem = (gameType, item) => {
    setGameSettings(prevSettings => ({
      ...prevSettings,
      [gameType]: prevSettings[gameType].filter(i => i !== item)
    }));
  };

  const handleNewItemChange = (gameType, value) => {
    setNewItems(prevNewItems => ({
      ...prevNewItems,
      [gameType]: value
    }));
  };

  const handleAddColumn = (e) => {
    e.preventDefault();
    if (newColumnName && !gameSettings.hasOwnProperty(newColumnName)) {
      setGameSettings(prevSettings => ({
        ...prevSettings,
        [newColumnName]: []
      }));
      setNewColumnName('');
    }
  };

  const handleSave = async () => {
    try {
      //console.log("Saving game settings:", gameSettings);
      await updateDropdownOptions(gameSettings);
      //console.log("Saving cooking types:", cookingTypes);
      await updateCookingTypes(cookingTypes);
      alert('Settings saved successfully!');
      setOriginalGameSettings(gameSettings);
      setOriginalCookingTypes(cookingTypes);
    } catch (error) {
      //console.error("Failed to save settings:", error);
      alert(`Failed to save settings: ${error.message}. Please try again.`);
    }
  };

  const handleCancel = () => {
    setGameSettings(originalGameSettings);
    setCookingTypes(originalCookingTypes);
    setNewItems({});
    setNewColumnName('');
  };

  return (
    <>
      <Container>
        <Title>Admin Dropdown Manager</Title>
        
        <DragDropContext onDragEnd={handleDragEnd}>
          <SectionTitle>Game Types</SectionTitle>
          <AddColumnForm onSubmit={handleAddColumn}>
            <AddColumnInput
              type="text"
              value={newColumnName}
              onChange={(e) => setNewColumnName(e.target.value)}
              placeholder="New game type"
            />
            <AddColumnButton type="submit">Add Game Type</AddColumnButton>
          </AddColumnForm>
          <GameTypeContainer>
            {Object.entries(gameSettings).map(([gameType, foodTypes]) => (
              <Droppable droppableId={gameType} key={gameType}>
                {(provided) => (
                  <GameTypeColumn
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <GameTypeTitle>{gameType}</GameTypeTitle>
                    <AddItemForm onSubmit={(e) => handleAddItem(gameType, e)}>
                      <AddItemInput
                        type="text"
                        value={newItems[gameType] || ''}
                        onChange={(e) => handleNewItemChange(gameType, e.target.value)}
                        placeholder="Add item"
                      />
                      <AddItemButton type="submit">+</AddItemButton>
                    </AddItemForm>
                    {foodTypes.map((item, index) => (
                      <Draggable key={`${gameType}-${item}`} draggableId={`${gameType}-${item}`} index={index}>
                        {(provided) => (
                          <DraggableItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <span>{item}</span>
                            <DeleteButton onClick={() => handleDeleteItem(gameType, item)}>
                              ×
                            </DeleteButton>
                          </DraggableItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </GameTypeColumn>
                )}
              </Droppable>
            ))}
          </GameTypeContainer>
        </DragDropContext>

        <SectionTitle>Cooking Methods</SectionTitle>
        <CookingMethodsManager cookingTypes={cookingTypes} setCookingTypes={setCookingTypes} />

        <ButtonContainer>
          <CancelButton onClick={handleCancel}>Cancel</CancelButton>
          <SaveButton onClick={handleSave}>Save Changes</SaveButton>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default AdminDropdownManager;
