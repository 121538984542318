import React, { useState, useEffect, useCallback } from 'react';
import { fetchAllUsers, updateUserAdminStatus, updateUserBlockStatus, getLastAccessTime, updateUserTrustedStatus, getApprovedRecipesCount, removeUser, setPendingAndReassignRecipes } from '../utils/firebaseUtils';
import styled from 'styled-components';
import { FaEnvelope, FaSync, FaLock, FaLockOpen, FaTrash, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { format, isWithinInterval } from 'date-fns';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const AdminUsersContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const UserList = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 15px;
  font-size: 0.85rem;
`;

const UserRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const UserCell = styled.td`
  padding: 12px 8px;
  border-bottom: 1px solid #e0e0e0;
`;

const UserHeader = styled.th`
  padding: 12px 8px;
  text-align: left;
  background-color: #f0f0f0;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
`;

const Select = styled.select`
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ddd;
`;

const ActionButton = styled.button`
  margin: 0 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
`;

const MessageButton = styled(ActionButton)`
  color: #2196F3;
  &:hover {
    background-color: rgba(33, 150, 243, 0.1);
  }
`;

const RefreshButton = styled(ActionButton)`
  color: #3498db;
  margin-bottom: 20px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const BlockButton = styled(ActionButton)`
  color: ${props => props.$isBlocked ? '#e74c3c' : '#2ecc71'};
  &:hover {
    background-color: ${props => props.$isBlocked ? 'rgba(231, 76, 60, 0.1)' : 'rgba(46, 204, 113, 0.1)'};
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Checkbox = styled.input`
  margin: 0;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const LeftFilters = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FilterRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SearchInput = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 200px;
`;

const FilterToggle = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
`;

const ToggleCheckbox = styled.input`
  margin-right: 8px;
`;

const ApprovedRecipesCell = styled(UserCell)`
  text-align: center;
`;

const RemoveButton = styled(ActionButton)`
  color: #e74c3c;
  &:hover {
    background-color: rgba(231, 76, 60, 0.1);
  }
`;

const ConfirmationModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ModalText = styled.p`
  margin-bottom: 15px;
`;

const ModalWarning = styled(ModalText)`
  color: #e74c3c;
  font-weight: bold;
`;

const ModalSelect = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ModalButton = styled.button`
  flex: 1;
  margin: 0 5px;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: ${props => props.confirm ? '#e74c3c' : '#3498db'};
  color: white;
  &:hover {
    opacity: 0.8;
  }
`;

const ModalLabel = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  align-self: flex-start;
`;

const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const DatePickerLabel = styled.label`
  margin-right: 10px;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 10px;
`;

const ClearDateButton = styled.button`
  margin-left: 10px;
  padding: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const PageInfo = styled.span`
  margin: 0 10px;
`;

const PageSizeSelect = styled.select`
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const TotalUsersInfo = styled.span`
  margin-left: 20px;
`;

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [emailFilter, setEmailFilter] = useState('');
  const [showAdminOnly, setShowAdminOnly] = useState(false);
  const [showTrustedOnly, setShowTrustedOnly] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [adminUsers, setAdminUsers] = useState([]);
  const [selectedAdminForReassign, setSelectedAdminForReassign] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [totalUserCount, setTotalUserCount] = useState(0);

  const loadUsers = useCallback(async () => {
    try {
      setLoading(true);
      const allUsers = await fetchAllUsers(usersPerPage);
      
      const usersWithDetails = await Promise.all(allUsers.map(async (user) => {
        const lastAccess = await getLastAccessTime(user.uid);
        const approvedRecipesCount = await getApprovedRecipesCount(user.uid);
        
        return { 
          ...user, 
          lastAccess,
          approvedRecipesCount,
          lastLogin: user.lastLogin,
          createdAt: user.createdAt,
          updatedAt: user.updatedAt
        };
      }));
      
      setUsers(usersWithDetails);
      setTotalUserCount(usersWithDetails.length);
      setError(null);
    } catch (error) {
      //console.error('Error in loadUsers:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [usersPerPage]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  useEffect(() => {
    // Log users whenever the users state changes
    //console.log('Users state updated:', users);
    users.forEach((user, index) => {
      //console.log(`User ${index}:`, user);
      //console.log(`User ${index} email:`, user?.email);
    });
  }, [users]);

  useEffect(() => {
    const admins = users.filter(user => user.isAdmin);
    setAdminUsers(admins);
  }, [users]);

  const handleAdminStatusChange = async (userId, newAdminStatus) => {
    try {
      await updateUserAdminStatus(userId, newAdminStatus);
      setUsers(users.map(user => 
        user.uid === userId ? { ...user, isAdmin: newAdminStatus } : user
      ));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleBlockStatusChange = async (userId, currentBlockStatus) => {
    try {
      const newBlockStatus = !currentBlockStatus;
      await updateUserBlockStatus(userId, newBlockStatus);
      setUsers(users.map(user => 
        user.uid === userId ? { ...user, isBlocked: newBlockStatus } : user
      ));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleMessageClick = (user) => {
    // Implement message functionality here
    //console.log('Send message to', user.email);
  };

  const refreshUsers = async () => {
    setIsRefreshing(true);
    await loadUsers();
    setIsRefreshing(false);
  };

  const handleTrustedStatusChange = async (userId, newTrustedStatus) => {
    try {
      await updateUserTrustedStatus(userId, newTrustedStatus);
      setUsers(users.map(user => 
        user.uid === userId ? { ...user, isTrusted: newTrustedStatus } : user
      ));
    } catch (error) {
      setError(error.message);
    }
  };

  // Updated function to format the date with time
  const formatDate = (dateValue) => {
    if (!dateValue) return 'N/A';
    const date = dateValue.toDate ? dateValue.toDate() : new Date(dateValue);
    return format(date, 'MM/dd/yyyy HH:mm:ss');
  };

  const filteredUsers = users.filter(user => {
    const matchesEmail = user.email.toLowerCase().includes(emailFilter.toLowerCase());
    const matchesAdmin = !showAdminOnly || user.isAdmin;
    const matchesTrusted = !showTrustedOnly || user.isTrusted;
    const matchesCreatedDate = (!startDate && !endDate) || 
      (user.createdAt && isWithinInterval(user.createdAt.toDate(), { 
        start: startDate || new Date(0), 
        end: endDate || new Date() 
      }));
    return matchesEmail && matchesAdmin && matchesTrusted && matchesCreatedDate;
  });

  const handleRemoveUser = (user) => {
    setUserToRemove(user);
    setSelectedAdminForReassign(adminUsers[0]?.uid || null);
    setShowConfirmation(true);
  };

  const confirmRemoveUser = async () => {
    if (!userToRemove || !selectedAdminForReassign) return;

    try {
      await removeUser(userToRemove.uid);
      await setPendingAndReassignRecipes(userToRemove.uid, selectedAdminForReassign);
      
      setUsers(users.filter(user => user.uid !== userToRemove.uid));
      setShowConfirmation(false);
      setUserToRemove(null);
      setSelectedAdminForReassign(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const clearDateRange = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleUsersPerPageChange = (event) => {
    const newUsersPerPage = Number(event.target.value);
    setUsersPerPage(newUsersPerPage);
    setCurrentPage(1);
    loadUsers();
  };

  if (loading) return <div>Loading users...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <AdminUsersContainer>
      <h2>User Management</h2>
      <RefreshButton onClick={refreshUsers} disabled={isRefreshing} aria-label="Refresh Users">
        <FaSync /> {isRefreshing ? 'Refreshing...' : 'Refresh Users'}
      </RefreshButton>
      {error && <div style={{color: 'red', marginBottom: '15px'}}>{error}</div>}
      
      <FilterContainer>
        <LeftFilters>
          <FilterRow>
            <FilterToggle>
              <ToggleCheckbox
                type="checkbox"
                checked={showAdminOnly}
                onChange={(e) => setShowAdminOnly(e.target.checked)}
              />
              Show Admin Only
            </FilterToggle>
            <FilterToggle>
              <ToggleCheckbox
                type="checkbox"
                checked={showTrustedOnly}
                onChange={(e) => setShowTrustedOnly(e.target.checked)}
              />
              Show Trusted Only
            </FilterToggle>
          </FilterRow>
          <DatePickerContainer>
            <DatePickerLabel>Created Date Range:</DatePickerLabel>
            <StyledDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="MM/dd/yyyy"
              placeholderText="Start Date"
            />
            <StyledDatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="MM/dd/yyyy"
              placeholderText="End Date"
            />
            {(startDate || endDate) && (
              <ClearDateButton onClick={clearDateRange}>
                Clear
              </ClearDateButton>
            )}
          </DatePickerContainer>
        </LeftFilters>
        <SearchInput
          type="text"
          placeholder="Search by email"
          value={emailFilter}
          onChange={(e) => setEmailFilter(e.target.value)}
        />
      </FilterContainer>

      <UserList>
        <thead>
          <tr>
            <UserHeader>Email</UserHeader>
            <UserHeader>Created Date</UserHeader>
            <UserHeader>Last Login</UserHeader>
            <UserHeader>Last Access</UserHeader>
            <UserHeader>Last Updated</UserHeader>
            <UserHeader>Admin Status</UserHeader>
            <UserHeader>Block Status</UserHeader>
            <UserHeader>Actions</UserHeader>
            <UserHeader>Approved Recipes</UserHeader>
            <UserHeader>Trusted</UserHeader>
            <UserHeader>Remove</UserHeader>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map(user => (
            <UserRow key={user.uid}>
              <UserCell>{user.email}</UserCell>
              <UserCell>{formatDate(user.createdAt)}</UserCell>
              <UserCell>{formatDate(user.lastLogin)}</UserCell>
              <UserCell>{formatDate(user.lastAccess)}</UserCell>
              <UserCell>{formatDate(user.updatedAt)}</UserCell>
              <UserCell>
                <Select
                  value={user.isAdmin ? 'admin' : 'user'}
                  onChange={(e) => handleAdminStatusChange(user.uid, e.target.value === 'admin')}
                >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </Select>
              </UserCell>
              <UserCell>
                <BlockButton
                  onClick={() => handleBlockStatusChange(user.uid, user.isBlocked)}
                  $isBlocked={user.isBlocked}
                  aria-label={`${user.isBlocked ? 'Unblock' : 'Block'} ${user.email}`}
                >
                  {user.isBlocked ? <FaLock /> : <FaLockOpen />}
                  {user.isBlocked ? 'Blocked' : 'Active'}
                </BlockButton>
              </UserCell>
              <UserCell>
                <MessageButton onClick={() => handleMessageClick(user)} aria-label={`Send message to ${user.email}`}>
                  <FaEnvelope />
                </MessageButton>
              </UserCell>
              <ApprovedRecipesCell>{user.approvedRecipesCount || 0}</ApprovedRecipesCell>
              <UserCell>
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    checked={user.isTrusted || false}
                    onChange={(e) => handleTrustedStatusChange(user.uid, e.target.checked)}
                    aria-label={`Mark ${user.email} as trusted`}
                  />
                </CheckboxLabel>
              </UserCell>
              <UserCell>
                <RemoveButton
                  onClick={() => handleRemoveUser(user)}
                  aria-label={`Remove ${user.email}`}
                >
                  <FaTrash />
                </RemoveButton>
              </UserCell>
            </UserRow>
          ))}
        </tbody>
      </UserList>

      <PaginationContainer>
        <div>
          <PageSizeSelect value={usersPerPage} onChange={handleUsersPerPageChange}>
            <option value={10}>10 per page</option>
            <option value={25}>25 per page</option>
            <option value={50}>50 per page</option>
            <option value={100}>100 per page</option>
          </PageSizeSelect>
          <TotalUsersInfo>
            Total Users: {filteredUsers.length} (Fetched Users: {totalUserCount})
          </TotalUsersInfo>
        </div>
        <div>
          <PageButton 
            onClick={() => handlePageChange(currentPage - 1)} 
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </PageButton>
          <PageInfo>
            Page {currentPage} of {totalPages}
          </PageInfo>
          <PageButton 
            onClick={() => handlePageChange(currentPage + 1)} 
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </PageButton>
        </div>
      </PaginationContainer>

      {showConfirmation && (
        <ConfirmationModal>
          <ModalContent>
            <ModalText>Are you sure you want to remove {userToRemove?.email}?</ModalText>
            <ModalWarning>THIS CAN NOT BE UNDONE</ModalWarning>
            <ModalText>This action will set all their recipes to pending and reassign them to an admin.</ModalText>
            <ModalLabel htmlFor="adminSelect">Assign recipes to:</ModalLabel>
            <ModalSelect 
              id="adminSelect"
              value={selectedAdminForReassign} 
              onChange={(e) => setSelectedAdminForReassign(e.target.value)}
            >
              {adminUsers.map(admin => (
                <option key={admin.uid} value={admin.uid}>{admin.email}</option>
              ))}
            </ModalSelect>
            <ModalButtonContainer>
              <ModalButton onClick={() => setShowConfirmation(false)}>Cancel</ModalButton>
              <ModalButton confirm onClick={confirmRemoveUser}>Confirm</ModalButton>
            </ModalButtonContainer>
          </ModalContent>
        </ConfirmationModal>
      )}
    </AdminUsersContainer>
  );
};

export default AdminUsers;