import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
`;

const Progress = styled.div`
  width: ${props => props.progress}%;
  height: 20px;
  background-color: #4CAF50;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
`;

const CancelButton = styled.button`
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 15px;

  &:hover {
    background-color: #c0392b;
  }
`;

const UploadProgressModal = ({ progress, onCancel }) => (
  <ModalOverlay>
    <ModalContent>
      <h2>Uploading Image</h2>
      <ProgressBar>
        <Progress progress={progress} />
      </ProgressBar>
      <p>{progress.toFixed(0)}% Uploaded</p>
      <CancelButton onClick={onCancel}>Cancel Upload</CancelButton>
    </ModalContent>
  </ModalOverlay>
);

export default UploadProgressModal;