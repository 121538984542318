import React, { useState, useEffect } from 'react';
import { getAuth, updateProfile } from 'firebase/auth';
import { getUserDocument, updateUserDocument, uploadProfilePicture, subscribeToNewsletter, unsubscribeFromNewsletter } from '../utils/firebaseUtils';
import styled, { createGlobalStyle } from 'styled-components';
import { FaCamera } from 'react-icons/fa';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import SubscriptionModal from './SubscriptionModal';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #2a2b2f;
    font-family: Arial, sans-serif;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #2a2b2f;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  color: #2a2b2f;
  text-align: center;
  margin-bottom: 20px;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileImageContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #9eb529;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: white;
  overflow: hidden;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CameraIcon = styled(FaCamera)`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #2a2b2f;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 1rem;
  cursor: pointer;
`;

const UserInfo = styled.div`
  margin-left: 20px;
`;

const UserEmail = styled.span`
  font-size: 1.2rem;
  color: #2a2b2f;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #2c3e50;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.6rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.6rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  resize: vertical;
`;

const SaveButton = styled.button`
  background-color: #2a2b2f;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #1e4b70;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  color: #2c3e50;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const ModalMessage = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
`;

const CloseButton = styled.button`
  background-color: #2a2b2f;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #1e4b70;
  }
`;

const SaveProfileModal = ({ isOpen, message, onClose }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalMessage>{message}</ModalMessage>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

const MyProfile = () => {
  const [user, setUser] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [bio, setBio] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [city, setCity] = useState('');
  const [stateProvince, setStateProvince] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isNewsletterFeatureAvailable, setIsNewsletterFeatureAvailable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [saveModalMessage, setSaveModalMessage] = useState('');
  const auth = getAuth();

  useEffect(() => {
    //console.log('Auth state changed');
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        //console.log('Auth user:', authUser);
        const userDoc = await getUserDocument(authUser.uid);
        //console.log('User document from getUserDocument:', userDoc);
        setUser(userDoc);
        setIsSubscribed(userDoc.isSubscribed ?? true);
      } else {
        //console.log('No auth user');
        setUser(null);
        setIsSubscribed(true);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && !isLoaded) {
        //console.log('Fetching user data for:', user);
        setProfilePicture(user.photoURL || null);
        setDisplayName(user.displayName || '');
        setFirstName(user.firstName || '');
        setLastName(user.lastName || '');
        setBio(user.bio || '');
        setCity(user.city || '');
        setStateProvince(user.stateProvince || '');
        setIsSubscribed(user.isSubscribed ?? true);
        setIsLoaded(true);
        /*
        console.log('User data set in state:', {
          displayName: user.displayName,
          firstName: user.firstName,
          lastName: user.lastName,
          bio: user.bio,
          city: user.city,
          stateProvince: user.stateProvince,
          isSubscribed: user.isSubscribed
        });
        */
      }
    };

    const checkNewsletterFeature = async () => {
      try {
        const db = getFirestore();
        const settingsDoc = await getDoc(doc(db, 'settings', 'mailchimp'));
        if (settingsDoc.exists()) {
          setIsNewsletterFeatureAvailable(settingsDoc.data().isConfigured || false);
        } else {
          setIsNewsletterFeatureAvailable(false);
        }
      } catch (error) {
        //console.error('Error checking newsletter feature:', error);
        setIsNewsletterFeatureAvailable(false);
      }
    };

    fetchUserData();
    checkNewsletterFeature();
  }, [user, isLoaded]);

  const handleSave = async () => {
    if (!user || !auth.currentUser) {
      setSaveModalMessage('You must be logged in to save your profile.');
      setIsSaveModalOpen(true);
      return;
    }

    try {
      // Use auth.currentUser.uid directly since we know it exists from the check above
      const userId = auth.currentUser.uid;
      
      // Update auth profile
      await updateProfile(auth.currentUser, { 
        displayName: displayName ? displayName.trim() : ''
      });
      
      // Update Firestore using the userId from auth
      await updateUserDocument(userId, { 
        firstName,
        lastName,
        displayName,
        email: auth.currentUser.email,
        bio,
        city,
        stateProvince,
        isSubscribed
      });
      
      setSaveModalMessage('Profile updated successfully!');
      setIsSaveModalOpen(true);
    } catch (error) {
      console.error('Profile update error:', error);
      setSaveModalMessage(`Failed to update profile. Error: ${error.message}`);
      setIsSaveModalOpen(true);
    }
  };

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const downloadURL = await uploadProfilePicture(file, user.uid);
        setProfilePicture(downloadURL);
        alert('Profile picture updated successfully!');
      } catch (error) {
        //console.error('Error uploading profile picture:', error);
        alert('Failed to upload profile picture. Please try again.');
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleProfilePictureChange(file);
    }
  };

  const handleSubscriptionChange = async (event) => {
    const newSubscriptionState = event.target.checked;
    
    if (!user || !auth.currentUser) {
      setSaveModalMessage('You must be logged in to change subscription settings.');
      setIsSaveModalOpen(true);
      return;
    }

    try {
      setIsUpdatingSubscription(true);
      const userEmail = auth.currentUser.email;

      // Update UI state immediately
      setIsSubscribed(newSubscriptionState);

      if (newSubscriptionState) {
        await subscribeToNewsletter(userEmail, firstName, lastName);
        setSaveModalMessage('You are now subscribed to the newsletter!');
      } else {
        console.log('Attempting to unsubscribe:', userEmail);
        await unsubscribeFromNewsletter(userEmail);
        console.log('Unsubscribe successful');
        setSaveModalMessage('You are now unsubscribed from the newsletter.');
      }

      await updateUserDocument(auth.currentUser.uid, { 
        isSubscribed: newSubscriptionState
      });
      
      setIsSaveModalOpen(true);
    } catch (error) {
      console.error('Detailed subscription error:', error);
      setIsSubscribed(!newSubscriptionState);
      setSaveModalMessage(`Failed to update subscription. Error: ${error.message}`);
      setIsSaveModalOpen(true);
    } finally {
      setIsUpdatingSubscription(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseSaveModal = () => {
    setIsSaveModalOpen(false);
  };

  if (!user) {
    //console.log('No user found');
    return <div>Please log in to view your profile.</div>;
  }

  //console.log('Rendering profile with state:', { firstName, lastName, displayName, bio, city, stateProvince, isSubscribed });
  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <ContentContainer>
          <Container>
            <Title>My Profile</Title>
            
            <ProfileSection>
              <ProfileImageContainer>
                {profilePicture ? (
                  <ProfileImage src={profilePicture} alt="Profile" />
                ) : (
                  user.email.charAt(0).toUpperCase()
                )}
                <label htmlFor="profile-picture-input">
                  <CameraIcon />
                </label>
                <input
                  id="profile-picture-input"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </ProfileImageContainer>
              <UserInfo>
                <UserEmail>{user.email}</UserEmail>
              </UserInfo>
            </ProfileSection>

            <FormGroup>
              <Label htmlFor="displayName">Display Name</Label>
              <Input
                id="displayName"
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="firstName">First Name</Label>
              <Input
                id="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="lastName">Last Name</Label>
              <Input
                id="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="city">City</Label>
              <Input
                id="city"
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="stateProvince">State/Province</Label>
              <Input
                id="stateProvince"
                type="text"
                value={stateProvince}
                onChange={(e) => setStateProvince(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="bio">Bio</Label>
              <TextArea
                id="bio"
                rows="4"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              />
            </FormGroup>
            {isNewsletterFeatureAvailable && (
              <CheckboxContainer>
                <Checkbox
                  id="newsletter"
                  type="checkbox"
                  checked={isSubscribed}
                  onChange={handleSubscriptionChange}
                  disabled={isUpdatingSubscription}
                />
                <CheckboxLabel htmlFor="newsletter">
                  {isUpdatingSubscription ? 'Updating...' : 'Subscribe to the Boone and Crockett Club newsletter'}
                </CheckboxLabel>
              </CheckboxContainer>
            )}
            <SaveButton onClick={handleSave}>
              Save Profile
            </SaveButton>
          </Container>
        </ContentContainer>
      </PageContainer>
      <SubscriptionModal
        isOpen={isModalOpen}
        message={saveModalMessage}
        onClose={handleCloseModal}
      />
      <SaveProfileModal
        isOpen={isSaveModalOpen}
        message={saveModalMessage}
        onClose={handleCloseSaveModal}
      />
    </>
  );
};

export default MyProfile;