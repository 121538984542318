import { useEffect } from 'react';
import { countRecipes } from '../utils/firebaseUtils';

const RecipeCountLogger = () => {
  useEffect(() => {
    const logRecipeCount = async () => {
      try {
        await countRecipes();
      } catch (error) {
        //console.error('Error logging recipe count:', error);
      }
    };

    logRecipeCount();
  }, []);

  return null; // This component does not render anything
};

export default RecipeCountLogger;
