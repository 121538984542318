import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { fetchPublicRecipes, addFavorite, removeFavorite, fetchUserFavorites } from '../utils/firebaseUtils';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaStar, FaStarHalfAlt, FaFilter, FaHeart, FaRegHeart, FaFire, FaClock, FaThermometerHalf, FaYoutube, FaSortAmountDown, FaTimes } from 'react-icons/fa';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import RecipeDetailPopup from './RecipeDetailPopup';
import ErrorBoundary from './ErrorBoundary';
import { motion, AnimatePresence } from 'framer-motion';
import { getRecipeStatus } from '../utils/recipeUtils';

// Define the glowAnimation keyframes before using it
const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px rgba(186, 82, 22, 0.5); }
  50% { box-shadow: 0 0 20px rgba(186, 82, 22, 0.8); }
  100% { box-shadow: 0 0 5px rgba(186, 82, 22, 0.5); }
`;

const NoResultsModal = styled(motion.div)`
  position: fixed;
  top: 25%;
  left: 22%;
  transform: translate(-50%, -50%);
  background-color: rgba(41, 42, 47, 0.5);
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(186, 82, 22, 0.1);
  z-index: 1000;
  text-align: center;
  animation: ${glowAnimation} 2s infinite;
`;

const NoResultsText = styled.p`
  margin: 0;
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 500;
`;

const MobileSortingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 20px 0;
  padding: 0;
`;

const MobileCookingTypeFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 5px;
  margin-top: 10px;
`;

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #2a2b2f;
  }
`;

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #2a2b2f;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    min-height: auto;
  }
`;

const Header = styled.header`
  background-color: #2a2b2f;
  padding: 15px 20px;
  position: relative;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: -10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

const LogoAndFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SortingContainer = styled.div`
  padding: 5px;
  margin-bottom: 5px;
  margin-top: -15px;
  margin-left: -20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1000;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SortLabel = styled.label`
  font-size: 0.8rem;
  margin-right: 10px;
  color: #fff;
  display: flex;
  align-items: center;
`;

const SortSelect = styled.select`
  padding: 5px;
  margin-left: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.7rem;

  option {
    background-color: #2a2b2f;
    color: #fff;
  }
`;

const CookingTypeFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 3px;
  margin-top: 5px;
  margin-left: -30px;
  width: 100%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const CookingTypeCheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 5px;
  padding: 5px;
  background-color: rgba(41, 42, 47, 0.1);
  border-radius: 5px;
`;

const CookingTypeLabel = styled.label`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 0.75rem;
  margin-right: 3px;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2px 5px;
  border-radius: 3px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.3);

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px rgba(230, 126, 34, 0.9);
    transform: scale(1.05);
  }

  input:checked + & {
    background-color: rgba(186, 82, 22, .9);
    box-shadow: 0 0 8px rgba(186, 82, 22, 0.6);
    border-color: rgba(186, 82, 22, 0.9);
  }
`;

const CookingTypeCheckbox = styled.input`
  margin-right: 3px;
  width: 12px;
  height: 12px;
  display: none;
`;

const TabContainer = styled.div`
  display: flex;
  background-color: transparent;
  padding: 0 0 0 5px;
  padding-top: 5px;
  margin-top: 5px;
  margin-bottom: -1px;
  overflow-x: auto;
  min-height: 50px;
  position: relative;
  z-index: 0;
  width: 95%;
  margin-left: auto;
  margin-right: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  @media (max-width: 768px) {
    display: none;
  }
`;

// ... (existing imports and styled components)

const MobileFilterButton = styled.button`
  display: none;
  background-color: ${props => props.$isActive ? 'rgba(186, 82, 22, 0.9)' : 'rgba(255, 255, 255, 0.1)'};
  color: #fff;
  border: 1px solid ${props => props.$isActive ? 'rgba(186, 82, 22, 0.9)' : 'rgba(255, 255, 255, 0.3)'};
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  z-index: 999;

  &:hover {
    background-color: ${props => props.$isActive ? 'rgba(186, 82, 22, 1)' : 'rgba(255, 255, 255, 0.2)'};
    box-shadow: 0 0 10px rgba(230, 126, 34, 0.9);
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -10px;
    top: 15px;
  }
`;

const FilterContent = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background-color: rgba(42, 43, 47, 0.5);
  padding: 20px;
  border-radius: 0 0 10px 10px;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  z-index: 998;
  display: ${props => props.$isOpen ? 'block' : 'none'};
  backdrop-filter: blur(5px);

  @media (max-width: 768px) {
    top: 155px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
`;

// ... (rest of the existing code)

const MobileTabContainer = styled.div`
  display: none;
  flex-direction: column;
  background-color: #2a2b2f;
  padding: 5px 10px;

  @media (max-width: 768px) {
    display: flex;
    margin-top: 50px;
  }
`;

const MobileDivider = styled.div`
  height: .5px;
  background-color: #ba5216;
  margin: 5px -10px 0;
  width: 100%;
  align-self: center;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileFooterSpacer = styled.div`
  height: 5px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileTab = styled.button`
  background-color: ${props => props.$active ? '#cec3b1' : '#beb5a3'};
  color: ${props => props.$active ? '#000000' : '#2a2b2f'};
  border: none;
  padding: ${props => props.$active ? '12px 15px' : '9px 15px'};
  margin-bottom: ${props => props.$active ? '0' : '5px'};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, padding 0.3s;
  font-size: ${props => props.$active ? '1.1rem' : '0.9rem'};
  font-weight: ${props => props.$active ? '300' : '200'};
  font-family: 'Permanent Marker', cursive, sans-serif ;
  border-radius: ${props => props.$active ? '5px 5px 0 0' : '5px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.$active ? 'auto' : '75%'};
`;

const MobileTabContent = styled.div`
  background-color: #cec3b1;
  padding: 15px;
  border-radius: 0 0 5px 5px;
  margin-bottom: 5px;
  display: ${props => props.$active ? 'block' : 'none'};
  max-height: 75vh;
  overflow-y: auto;
`;

const Tab = styled.button`
  background-color: ${props => props.$active ? '#cec3b1' : '#beb5a3'};
  color: ${props => props.$active ? '#000000' : '#2a2b2f'};
  border: none;
   padding: 8px 15px 16px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
  transform-origin: bottom;
  font-size: 1.1rem;
  font-weight: ${props => props.$active ? '300' : '200'};
  font-family: 'Permanent Marker', cursive, sans-serif;
  border-radius: 10px 10px 0 0;
  margin: 0 3px 0 0;
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${props => props.$active ? 'none' : '0 2px 4px rgba(0,0,0,0.1)'};
  height: 100%;
  border-bottom: none;
  margin-bottom: 0;
  
  &:hover {
    background-color: ${props => props.$active ? '#cec3b1' : '#c8bfad'};
    color: #000000;
    transform: ${props => props.$active ? 'scale(1.05)' : 'scale(1.02)'};
  }

  &:last-child {
    border-right: none;
    margin-right: 0;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 10px 12px;
    flex: 0 0 auto;
    min-width: 120px;
    border-radius: 10px 10px 0 0;
    height: auto;
    margin: 2px 2px 0 0;
  }

  ${props => props.$active && `
    background-color: #cec3b1;
    border-bottom: none;
    position: relative;
    z-index: 1;
    transform: scale(1.05);
  `}

  @media (max-width: 768px) {
    border-bottom: 0px solid #2a2b2f;
    margin-bottom: 0;
    box-shadow: ${props => props.$active ? 'none' : '0 2px 4px rgba(0,0,0,0.1)'};
  }
`;

const RecipeCount = styled.span`
  color: ${props => props.$active ? '#000000' : '#2a2b2f'};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.$active ? '1.1rem' : '1.0rem'};
  font-weight: 700;
  font-family: 'Permanent Marker', cursive, sans-serif;
  margin-left: 8px;
  transform: rotate(-5deg);

  @media (max-width: 768px) {
    font-size: ${props => props.$active ? '0.7rem' : '0.6rem'};
    margin-left: 4px;
  }
`;

const RecipeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const RecipeCard = styled(Link)`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  aspect-ratio: 3 / 4;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  }
`;
const PLACEHOLDER_IMAGE_URL = 'https://aws.boone-crockett.org/s3fs-public/styles/12-col_848px/public/thumbnails/image/wild_recipes_logo_6.png';

const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  background-color: #2a2b2f;
  position: relative;
  overflow: hidden;
`;

const RecipeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  background-color: #2a2b2f;

  ${props => !props.$isPlaceholder && `
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  `}

  ${props => props.$isPlaceholder && `
    object-fit: contain;
    padding: 20px;
  `}
`;

const RecipeContent = styled.div`
  padding: 10px 15px 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const RecipeTitle = styled.h3`
  margin: 0;
  color: #2a2b2f;
  font-size: 1.2rem;
  line-height: 1.0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 3px;
  margin-top: -10px;
  text-decoration-thickness: 0.5px;
  text-underline-offset: 5px;
`;

const SubmittedBy = styled.div`
  font-size: 0.65rem;
  color: #5f4d4d;
  margin-bottom: 3px;
  font-weight: 550;
  letter-spacing: 0.02em;
`;

const CookingInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  line-height: .5;
  color: #666;
  margin-bottom: 10px;
  margin-top: 2px;
  padding: 0;
  width: 100%;
`;

const InfoItem = styled.span`
  display: flex;
  align-items: center;
  margin: 0 3px 5px;
  white-space: nowrap;
  font-size: 0.5rem;
  svg {
    margin-right: 3px;
    min-width: 12px;
  }
`;

const RecipeDescription = styled.p`
  margin: 5px 0;
  color: #5f4d4d;
  font-size: .9rem;
  line-height: 1.1;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

const StarRating = styled.div`
  color: #ba5216;
  font-size: 0.9rem;
  margin-top: 15px;
  margin-bottom: -20px;
  display: flex;
  align-items: center;
`;

const StarIcon = styled(FaStar)`
  color: #f1c40f;
`;

const StarHalfIcon = styled(FaStarHalfAlt)`
  color: #f1c40f;
`;

const EmptyStarIcon = styled(FaStar)`
  color: #bdc3c7;
`;

const FilterSection = styled.div`
  margin-bottom: 20px;
`;

const FilterTitle = styled.h4`
  margin-bottom: 10px;
  font-size: .9rem;
  color: #2a2b2f;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 5px;
  padding: 5px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 0.75rem;
  margin-right: 3px;
  white-space: nowrap;
  background-color: rgba(95, 77, 77, 0.6);
  padding: 2px 5px;
  border-radius: 3px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: rgba(41, 42, 47, 0.8);
    box-shadow: 0 0 10px rgba(230, 126, 34, 0.9);
    transform: scale(1.02);
  }

  input:checked + & {
    background-color: rgba(186, 82, 22, 0.9);
    box-shadow: 0 0 8px rgba(230, 126, 34, .9);
  }
`;

const Checkbox = styled.input`
  margin-right: 3px;
  width: 12px;
  height: 12px;
  display: none;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;


const VideoButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 65px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #FF0000;
  transition: color 0.2s;
  z-index: 1;

  &:hover {
    color: #CC0000;
  }
`;

const FavoriteButtonWrapper = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer; // Change this line
  font-size: 1.5rem;
  color: ${props => props.$isFavorited ? '#e74c3c' : '#bdc3c7'};
  transition: color 0.2s;
  z-index: 1;

  &:hover {
    color: ${props => props.$isFavorited ? '#e74c3c' : '#c0392b'};
  }
`;

const TabAndContentWrapper = styled.div`
  background-color: #2a2b2f;
  margin: 0;
  border-radius: 0;
  overflow: hidden;
  padding-top: 1px;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    background-color: transparent;
  }
`;

const ContentArea = styled.div`
  background-color: #cec3b1;
  padding: 10px 20px 20px 23px;
  border-radius: 15px 15px 15px 15px;
  margin-top: -10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    display: none;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${props => props.fadeOut ? fadeOut : fadeIn} 0.3s ease-in-out;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
`;

const PublicRecipes = ({ user, addToShoppingList }) => {
  const [recipes, setRecipes] = useState([]);
  const [activeTab, setActiveTab] = useState('All');
  const [filters, setFilters] = useState({});
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [cookingMethodFilters, setCookingMethodFilters] = useState({});
  const [gameCategories, setGameCategories] = useState({});
  const [gameCategoryOrder, setGameCategoryOrder] = useState([]);
  const [activeSubcategories, setActiveSubcategories] = useState({});
  const [activeCookingMethods, setActiveCookingMethods] = useState([]);
  const [showNoResultsModal, setShowNoResultsModal] = useState(false);
  const [sortOption, setSortOption] = useState('newest');
  const [showLoginModal, setShowLoginModal] = useState(false);

  const [expandedMobileTab, setExpandedMobileTab] = useState(null);
  const [isFilterOverlayOpen, setIsFilterOverlayOpen] = useState(false);
  const isAnyCookingMethodFilterActive = useCallback(() => {
    return Object.values(cookingMethodFilters).some(value => value);
  }, [cookingMethodFilters]);
  const toggleFilterOverlay = () => {
    setIsFilterOverlayOpen(prevState => !prevState);
  };
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const publicRecipes = await fetchPublicRecipes();
        setRecipes(publicRecipes);
      } catch (error) {
        // Handle error
      }
    };

    fetchRecipes().catch(error => {
      // Handle error
    });
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userFavorites = await fetchUserFavorites(currentUser.uid);
          setFavorites(userFavorites.map(fav => fav.id));
        } catch (error) {
          setFavorites([]);
        }
      } else {
        setFavorites([]);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (gameCategories[activeTab]) {
      setFilters(
        gameCategories[activeTab].reduce((acc, type) => {
          acc[type] = false;
          return acc;
        }, {})
      );
    }
  }, [activeTab, gameCategories]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const db = getFirestore();
        const foodTypesRef = doc(db, 'settings', 'foodDropdown');
        const cookingMethodsRef = doc(db, 'settings', 'methodDropdown');

        const [foodTypesSnap, cookingMethodsSnap] = await Promise.all([
          getDoc(foodTypesRef),
          getDoc(cookingMethodsRef)
        ]);

        if (foodTypesSnap.exists()) {
          const data = foodTypesSnap.data();
          const parsedGameCategories = {};
          const categoryOrder = [];
          Object.entries(data).forEach(([category, types]) => {
            parsedGameCategories[category] = types.split(',').map(item => item.trim());
            categoryOrder.push(category);
          });
          setGameCategories(parsedGameCategories);
          setGameCategoryOrder(categoryOrder);
        }

        if (cookingMethodsSnap.exists()) {
          const data = cookingMethodsSnap.data();
          if (data.Method) {
            const methods = data.Method.split(',').map(method => method.trim());
            setActiveCookingMethods(methods);
          }
        }
      } catch (error) {
        // Handle error
      }
    };

    fetchCategories();
  }, []);

  const determineActiveSubcategories = useCallback(() => {
    const active = {};
    Object.keys(gameCategories).forEach(category => {
      active[category] = gameCategories[category].filter(subType => 
        recipes.some(recipe => {
          const foodType = recipe['Food Type'];
          if (!foodType || !Array.isArray(foodType)) return false;
          return foodType.some(type => type.category === category && type.label === subType);
        })
      );
    });
    setActiveSubcategories(active);
  }, [recipes, gameCategories]);

  useEffect(() => {
    determineActiveSubcategories();
  }, [determineActiveSubcategories]);

  useEffect(() => {
    const determineActiveCookingMethods = () => {
      const methods = new Set();
      recipes.forEach(recipe => {
        const recipeCookingMethod = recipe['Cooking Method'];
        if (recipeCookingMethod) {
          const cookingMethodArray = Array.isArray(recipeCookingMethod)
            ? recipeCookingMethod
            : recipeCookingMethod.split('|').map(method => method.trim());
          cookingMethodArray.forEach(method => methods.add(method));
        }
      });
      setActiveCookingMethods(Array.from(methods));
    };

    determineActiveCookingMethods();
  }, [recipes]);

  const filteredRecipes = useMemo(() => {
    const filtered = recipes.filter(recipe => {
      if (!recipe.approved) return false;

      if (activeTab === 'All') {
        const activeCookingMethods = Object.entries(cookingMethodFilters)
          .filter(([_, isActive]) => isActive)
          .map(([method]) => method);
        if (activeCookingMethods.length === 0) return true;

        const recipeCookingMethod = recipe['Cooking Method'];
        const cookingMethodArray = Array.isArray(recipeCookingMethod) 
          ? recipeCookingMethod 
          : (typeof recipeCookingMethod === 'string' ? recipeCookingMethod.split('|').map(method => method.trim()) : []);

        return activeCookingMethods.some(method => cookingMethodArray.includes(method));
      }

      const foodType = recipe['Food Type'];
      if (!foodType || !Array.isArray(foodType)) return false;
      
      const matchesActiveTab = foodType.some(type => type.category === activeTab);
      if (!matchesActiveTab) return false;

      const activeFilters = Object.entries(filters).filter(([, value]) => value).map(([key]) => key);
      const matchesGameTypeFilter = activeFilters.length === 0 || 
        foodType.some(type => activeFilters.includes(type.label));

      const activeCookingMethods = Object.entries(cookingMethodFilters)
        .filter(([_, isActive]) => isActive)
        .map(([method]) => method);
      
      const recipeCookingMethod = recipe['Cooking Method'];
      const cookingMethodArray = Array.isArray(recipeCookingMethod) 
        ? recipeCookingMethod 
        : (typeof recipeCookingMethod === 'string' ? recipeCookingMethod.split('|').map(method => method.trim()) : []);

      const matchesCookingMethodFilter = activeCookingMethods.length === 0 || 
        activeCookingMethods.some(method => cookingMethodArray.includes(method));

      return matchesGameTypeFilter && matchesCookingMethodFilter;
    });

    return filtered;
  }, [recipes, activeTab, filters, cookingMethodFilters]);

  useEffect(() => {
    const areFiltersActive = Object.values(filters).some(value => value) || Object.values(cookingMethodFilters).some(value => value);

    if (filteredRecipes.length === 0 && areFiltersActive) {
      setShowNoResultsModal(true);
      const timer = setTimeout(() => {
        setShowNoResultsModal(false);
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      setShowNoResultsModal(false);
    }
  }, [filteredRecipes, filters, cookingMethodFilters]);

  const handleFilterChange = (type) => {
    setFilters(prev => {
      const newFilters = { ...prev, [type]: !prev[type] };
      return newFilters;
    });
  };

  const handleCookingMethodFilterChange = (method) => {
    setCookingMethodFilters(prev => {
      const newFilters = { ...prev, [method]: !prev[method] };
      return newFilters;
    });
  };

  const renderStarRating = useCallback((rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return (
      <>
        {[...Array(fullStars)].map((_, i) => <StarIcon key={`full-${i}`} />)}
        {hasHalfStar && <StarHalfIcon />}
        {[...Array(emptyStars)].map((_, i) => <EmptyStarIcon key={`empty-${i}`} />)}
        <span style={{ marginLeft: '5px' }}>({rating.toFixed(1)})</span>
      </>
    );
  }, []);

  const handleRecipeClick = useCallback((e, recipe) => {
    e.preventDefault();
    const sanitizedRecipe = {
      ...recipe,
      Ingredients: (() => {
        if (Array.isArray(recipe.Ingredients)) {
          return recipe.Ingredients;
        } else if (typeof recipe.Ingredients === 'string') {
          return recipe.Ingredients.split('|').map(ing => ing.trim());
        } else if (typeof recipe.Ingredients === 'object') {
          return Object.entries(recipe.Ingredients).map(([key, value]) => `${key}: ${value}`);
        } else {
          return [];
        }
      })(),
      'Cooking Method': Array.isArray(recipe['Cooking Method'])
        ? recipe['Cooking Method'].map(method => method.label || method).join(', ')
        : recipe['Cooking Method'],
      'Food Type': (() => {
        if (Array.isArray(recipe['Food Type'])) {
          return recipe['Food Type'].map(type => type.label || type).join(', ');
        } else if (typeof recipe['Food Type'] === 'object') {
          return Object.keys(recipe['Food Type']).join(', ');
        } else {
          return recipe['Food Type'];
        }
      })(),
    };
    setSelectedRecipe(sanitizedRecipe);
  }, [setSelectedRecipe]);

  const closePopup = () => {
    setSelectedRecipe(null);
  };

  const showLoginMessage = () => {
    setShowLoginModal(true);
    setTimeout(() => {
      setShowLoginModal(false);
    }, 2000);
  };

  const handleFavoriteToggle = useCallback(async (e, recipeId) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!user) {
      showLoginMessage();
      return;
    }

    try {
      if (favorites.includes(recipeId)) {
        await removeFavorite(user.uid, recipeId);
        setFavorites(favorites.filter(id => id !== recipeId));
      } else {
        await addFavorite(user.uid, recipeId);
        setFavorites([...favorites, recipeId]);
      }
      
      if (selectedRecipe && selectedRecipe.id === recipeId) {
        setSelectedRecipe(prevRecipe => ({
          ...prevRecipe,
          isFavorite: !favorites.includes(recipeId)
        }));
      }
    } catch (error) {
      alert(`Failed to update favorite. Error: ${error.message}`);
    }
  }, [user, favorites, setFavorites, selectedRecipe]);

  const handleRatingChange = useCallback((recipeId, newAverageRating, newNumberOfRatings) => {
    setRecipes(prevRecipes => 
      prevRecipes.map(recipe => 
        recipe.id === recipeId 
          ? { ...recipe, averageRating: newAverageRating, numberOfRatings: newNumberOfRatings }
          : recipe
      )
    );
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const db = getFirestore();
        const foodTypesRef = doc(db, 'settings', 'foodDropdown');
        const foodTypesSnap = await getDoc(foodTypesRef);

        if (foodTypesSnap.exists()) {
          const data = foodTypesSnap.data();
          const parsedGameCategories = {};
          const categoryOrder = [];
          Object.entries(data).forEach(([category, types]) => {
            parsedGameCategories[category] = types.split(',').map(item => item.trim());
            categoryOrder.push(category);
          });
          setGameCategories(parsedGameCategories);
          setGameCategoryOrder(categoryOrder);
        }
      } catch (error) {
        // Handle error
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const determineActiveSubcategories = () => {
      const active = {};
      Object.keys(gameCategories).forEach(category => {
        active[category] = gameCategories[category].filter(subType => 
          recipes.some(recipe => {
            const foodType = recipe['Food Type'];
            if (!foodType || !Array.isArray(foodType)) return false;
            return foodType.some(type => type.category === category && type.label === subType);
          })
        );
      });
      setActiveSubcategories(active);
    };

    determineActiveSubcategories();
  }, [recipes, gameCategories]);

  const countRecipesByGameType = useMemo(() => {
    const counts = {
      'All': recipes.filter(recipe => recipe.approved).length,
    };
    Object.keys(gameCategories).forEach(category => {
      counts[category] = recipes.filter(recipe => {
        if (!recipe.approved) return false;
        const foodType = recipe['Food Type'];
        if (!foodType || !Array.isArray(foodType)) return false;
        return foodType.some(type => type.category === category);
      }).length;
    });
    return counts;
  }, [recipes, gameCategories]);

  useEffect(() => {
    const loadFavorites = async () => {
      if (user && user.uid) {
        try {
          const userFavorites = await fetchUserFavorites(user.uid);
          setFavorites(userFavorites);
        } catch (error) {
          // Handle error
        }
      }
    };

    loadFavorites();
  }, [user]);

  const favoriteRecipes = useMemo(() => {
    return recipes.filter(recipe => favorites.includes(recipe.id));
  }, [recipes, favorites]);

  const renderRecipeCard = useCallback((recipe) => {
    const description = recipe.Description || recipe.description || 'No description available';
    const truncatedDescription = description.length > 220 ? `${description.slice(0, 220)}...` : description;
  
    const cookingMethod = recipe['Cooking Method'] || 'N/A';
    const difficulty = recipe['Difficulty'] || 'N/A';

    return (
      <RecipeCard 
        key={recipe.id} 
        onClick={(e) => handleRecipeClick(e, recipe)}
        to="#"
      >
        {recipe.videoUrl && (
          <VideoButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(recipe.videoUrl, '_blank');
            }}
            title="Watch video on YouTube"
          >
            <FaYoutube />
          </VideoButton>
        )}
        <FavoriteButtonWrapper
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleFavoriteToggle(e, recipe.id);
          }}
          $isFavorited={favorites.includes(recipe.id)}
        >
          {favorites.includes(recipe.id) ? <FaHeart /> : <FaRegHeart />}
        </FavoriteButtonWrapper>
        <ImageContainer>
          {recipe.imageUrl ? (
            <RecipeImage src={recipe.imageUrl} alt={recipe.Name} />
          ) : (
            <RecipeImage 
              src={PLACEHOLDER_IMAGE_URL} 
              alt={recipe.Name || 'Recipe'} 
              $isPlaceholder={true}
            />
          )}
        </ImageContainer>
        <RecipeContent>
          <div>
            <CookingInfo>
              <InfoItem>
                <FaFire style={{ color: '#ba5216' }} />
                {cookingMethod}
              </InfoItem>
              <InfoItem>
                <FaClock style={{ color: '#2a2b2f' }} />
                Prep: {recipe['Prep Time'] || 'N/A'}
              </InfoItem>
              <InfoItem>
                <FaClock style={{ color: '#2a2b2f' }} />
                Cook: {recipe['Cooking Time'] || 'N/A'}
              </InfoItem>
              <InfoItem>
                <FaThermometerHalf style={{ color: '#2a2b2f' }} />
                Diff: {difficulty}
              </InfoItem>
            </CookingInfo>
            <RecipeTitle>{recipe.Name || 'Untitled Recipe'}</RecipeTitle>
            <SubmittedBy>Submitted by: {recipe.Submitter || recipe.displayName || 'Unknown'}</SubmittedBy>
            <RecipeDescription>
              {truncatedDescription}
            </RecipeDescription>
          </div>
          <StarRating>
            {recipe.averageRating ? renderStarRating(recipe.averageRating) : 'Not rated'} 
            <span style={{ marginLeft: '5px' }}>({recipe.numberOfRatings || 0})</span>
          </StarRating>
        </RecipeContent>
      </RecipeCard>
    );
  }, [handleRecipeClick, favorites, handleFavoriteToggle, renderStarRating]);

  const sortRecipes = useCallback((recipesToSort) => {
    switch (sortOption) {
      case 'newest':
        return [...recipesToSort].sort((a, b) => b.createdAt - a.createdAt);
      case 'oldest':
        return [...recipesToSort].sort((a, b) => a.createdAt - b.createdAt);
      case 'highestRated':
        return [...recipesToSort].sort((a, b) => (b.averageRating || 0) - (a.averageRating || 0));
      case 'mostRatings':
        return [...recipesToSort].sort((a, b) => (b.numberOfRatings || 0) - (a.numberOfRatings || 0));
      case 'mostFavorited':
        return [...recipesToSort].sort((a, b) => (b.favoriteCount || 0) - (a.favoriteCount || 0));
      case 'highestRatedMostRatings':
        return [...recipesToSort].sort((a, b) => {
          const scoreA = (a.averageRating || 0) * Math.log(a.numberOfRatings + 1);
          const scoreB = (b.averageRating || 0) * Math.log(b.numberOfRatings + 1);
          return scoreB - scoreA;
        });
      case 'mostFavoritedHighestRated':
        return [...recipesToSort].sort((a, b) => {
          const scoreA = (a.favoriteCount || 0) * (a.averageRating || 0);
          const scoreB = (b.favoriteCount || 0) * (b.averageRating || 0);
          return scoreB - scoreA;
        });
      default:
        return recipesToSort;
    }
  }, [sortOption]);

  const sortedAndFilteredRecipes = useMemo(() => {
    return sortRecipes(filteredRecipes);
  }, [filteredRecipes, sortRecipes]);

  const handleMobileTabClick = (tabName) => {
    if (expandedMobileTab === tabName) {
      setExpandedMobileTab(null); // Close the tab if it's already open
    } else {
      setExpandedMobileTab(tabName); // Open the clicked tab
    }
    setActiveTab(tabName);
  };

  useEffect(() => {
    const loadRecipes = async () => {
      try {
        const fetchedRecipes = await fetchPublicRecipes();
        // Filter to only show approved recipes
        const approvedRecipes = fetchedRecipes.filter(recipe => getRecipeStatus(recipe) === 'approved');
        setRecipes(approvedRecipes);
      } catch (error) {
        //console.error('Error fetching recipes:', error);
        // Handle error (e.g., set an error state)
      }
    };

    loadRecipes();
  }, []);

  const renderMobileTabContent = (tabName) => {
    let recipesToRender;
    if (tabName === 'All') {
      recipesToRender = sortedAndFilteredRecipes;
    } else if (tabName === 'My Favorites') {
      recipesToRender = favoriteRecipes;
    } else {
      recipesToRender = sortedAndFilteredRecipes.filter(recipe => {
        const foodType = recipe['Food Type'];
        return Array.isArray(foodType) && foodType.some(type => type.category === tabName);
      });
    }

    return (
      <RecipeGrid>
        {recipesToRender.map(renderRecipeCard)}
      </RecipeGrid>
    );
  };

  return (
    <ErrorBoundary>
      <GlobalStyle />
      <PageContainer>
        <Header>
          <HeaderContent>
            <MobileFilterButton 
              onClick={toggleFilterOverlay} 
              $isActive={isAnyCookingMethodFilterActive()}
            >
              <FaFilter style={{ marginRight: '5px' }} /> Filters
            </MobileFilterButton>
            <LogoAndFiltersContainer>
              <SortingContainer>
                <SortLabel>
                  <FaSortAmountDown style={{ marginRight: '5px' }} />
                  Sort by: 
                </SortLabel>
                <SortSelect value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                  <option value="newest">Newest First</option>
                  <option value="oldest">Oldest First</option>
                  <option value="highestRated">Highest Rated</option>
                  <option value="mostRatings">Most Ratings</option>
                  <option value="highestRatedMostRatings">High Rating + Most Rated</option>
                </SortSelect>
              </SortingContainer>
              <CookingTypeFilters>
                <CookingTypeCheckboxContainer>
                  {activeCookingMethods.map(method => (
                    <div key={method}>
                      <CookingTypeCheckbox
                        type="checkbox"
                        id={`cooking-method-${method}`}
                        checked={cookingMethodFilters[method] || false}
                        onChange={() => handleCookingMethodFilterChange(method)}
                      />
                      <CookingTypeLabel htmlFor={`cooking-method-${method}`}>
                        {method}
                      </CookingTypeLabel>
                    </div>
                  ))}
                </CookingTypeCheckboxContainer>
              </CookingTypeFilters>
            </LogoAndFiltersContainer>
          </HeaderContent>
        </Header>

        <FilterContent $isOpen={isFilterOverlayOpen}>
          <CloseButton onClick={toggleFilterOverlay}>
            <FaTimes />
          </CloseButton>
          <MobileSortingContainer>
            <SortLabel>
              <FaSortAmountDown /> Sort by:
              <SortSelect value={sortOption} onChange={handleSortChange}>
                <option value="newest">Newest First</option>
                <option value="oldest">Oldest First</option>
                <option value="highestRated">Highest Rated</option>
                <option value="mostRatings">Most Ratings</option>
                <option value="highestRatedMostRatings">High Rating + Most Rated</option>
              </SortSelect>
            </SortLabel>
          </MobileSortingContainer>
          <MobileCookingTypeFilters>
            <CookingTypeCheckboxContainer>
              {activeCookingMethods.map(method => (
                <div key={method}>
                  <CookingTypeCheckbox
                    type="checkbox"
                    id={`mobile-cooking-method-${method}`}
                    checked={cookingMethodFilters[method] || false}
                    onChange={() => handleCookingMethodFilterChange(method)}
                  />
                  <CookingTypeLabel htmlFor={`mobile-cooking-method-${method}`}>
                    {method}
                  </CookingTypeLabel>
                </div>
              ))}
            </CookingTypeCheckboxContainer>
          </MobileCookingTypeFilters>
        </FilterContent>

        <TabAndContentWrapper>
          <TabContainer>
            <Tab 
              key="All"
              $active={activeTab === 'All'}
              onClick={() => setActiveTab('All')}
            >
              All <RecipeCount $active={activeTab === 'All'}>{countRecipesByGameType['All']}</RecipeCount>
            </Tab>
            {gameCategoryOrder.map(category => (
              <Tab 
                key={category} 
                $active={activeTab === category}
                onClick={() => setActiveTab(category)}
              >
                {category} <RecipeCount $active={activeTab === category}>{countRecipesByGameType[category]}</RecipeCount>
              </Tab>
            ))}
            {user && (
              <Tab
                $active={activeTab === 'My Favorites'}
                onClick={() => setActiveTab('My Favorites')}
              >
                Favorites <RecipeCount $active={activeTab === 'My Favorites'}>{favoriteRecipes.length}</RecipeCount>
              </Tab>
            )}
          </TabContainer>

          <MobileTabContainer>
            <MobileTab 
              $active={expandedMobileTab === 'All'}
              onClick={() => handleMobileTabClick('All')}
            >
              All <RecipeCount $active={expandedMobileTab === 'All'}>{countRecipesByGameType['All']}</RecipeCount>
            </MobileTab>
            <MobileTabContent $active={expandedMobileTab === 'All'}>
              {renderMobileTabContent('All')}
            </MobileTabContent>

            {gameCategoryOrder.map(category => (
              <React.Fragment key={category}>
                <MobileTab
                  $active={expandedMobileTab === category}
                  onClick={() => handleMobileTabClick(category)}
                >
                  {category} <RecipeCount $active={expandedMobileTab === category}>{countRecipesByGameType[category]}</RecipeCount>
                </MobileTab>
                <MobileTabContent $active={expandedMobileTab === category}>
                  {renderMobileTabContent(category)}
                </MobileTabContent>
              </React.Fragment>
            ))}

            {user && (
              <>
                <MobileTab
                  $active={expandedMobileTab === 'My Favorites'}
                  onClick={() => handleMobileTabClick('My Favorites')}
                >
                  Favorites <RecipeCount $active={expandedMobileTab === 'My Favorites'}>{favoriteRecipes.length}</RecipeCount>
                </MobileTab>
                <MobileTabContent $active={expandedMobileTab === 'My Favorites'}>
                  {renderMobileTabContent('My Favorites')}
                </MobileTabContent>
              </>
            )}
            <MobileDivider />
            <MobileFooterSpacer />
          </MobileTabContainer>

          {/* Desktop Content Area */}
          <ContentArea>
            {window.innerWidth > 768 && (
              <>
                {activeTab !== 'My Favorites' && (
                  <>
                    {activeTab !== 'All' && activeTab !== 'My Favorites' && (
                      <FilterSection>
                        <FilterTitle><FaFilter /> Filter by {activeTab} Type:</FilterTitle>
                        <CheckboxContainer>
                          {activeSubcategories[activeTab] && activeSubcategories[activeTab].map(subType => (
                            <div key={subType}>
                              <Checkbox
                                type="checkbox"
                                id={`subcategory-${subType}`}
                                checked={filters[subType] || false}
                                onChange={() => handleFilterChange(subType)}
                              />
                              <CheckboxLabel htmlFor={`subcategory-${subType}`}>
                                {subType}
                              </CheckboxLabel>
                            </div>
                          ))}
                        </CheckboxContainer>
                      </FilterSection>
                    )}

                    <RecipeGrid>
                      {sortedAndFilteredRecipes.map(renderRecipeCard)}
                    </RecipeGrid>
                  </>
                )}

                {activeTab === 'My Favorites' && (
                  <>
                    <FilterSection>
                      <FilterTitle>My Favorites</FilterTitle>
                    </FilterSection>
                    {favoriteRecipes.length > 0 ? (
                      <RecipeGrid>
                        {favoriteRecipes.map(renderRecipeCard)}
                      </RecipeGrid>
                    ) : (
                      <p>You haven't favorited any recipes yet. Browse recipes and click the heart icon to add favorites!</p>
                    )}
                  </>
                )}
              </>
            )}
          </ContentArea>
        </TabAndContentWrapper>

        {selectedRecipe && (
          <PopupOverlay onClick={closePopup}>
            <RecipeDetailPopup 
              recipe={selectedRecipe} 
              onClose={closePopup}
              isFavorite={favorites.includes(selectedRecipe.id)}
              onFavoriteToggle={handleFavoriteToggle}
              onRatingChange={handleRatingChange}
              user={user}
              addToShoppingList={addToShoppingList}
            />
          </PopupOverlay>
        )}

        <AnimatePresence>
          {showNoResultsModal && (
            <NoResultsModal
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <NoResultsText>No Results. Try a Different Filter Combination.</NoResultsText>
            </NoResultsModal>
          )}
        </AnimatePresence>

        {showLoginModal && (
          <ModalOverlay fadeOut={!showLoginModal}>
            <ModalContent>
              You must be logged in to use this feature.
            </ModalContent>
          </ModalOverlay>
        )}
      </PageContainer>
    </ErrorBoundary>
  );
};

export default PublicRecipes;