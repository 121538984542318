import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { 
  fetchAllRecipes, 
  updateRecipeApproval, 
  sendMessageToUser, 
  deleteRecipe, 
  rejectRecipe,
  getUserProfile, // Add this new import
} from '../utils/firebaseUtils';
import styled from 'styled-components';
import { format, isValid, parseISO } from 'date-fns';
import { FaEnvelope, FaEdit, FaTrash, FaSync, FaChevronLeft, FaChevronRight, FaEye, FaSort, FaSortUp, FaSortDown, FaUserShield, FaFlag } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import RecipeDetailPopup from './RecipeDetailPopup';
import { auth } from '../utils/firebaseUtils';
import { getRecipeStatus } from '../utils/recipeUtils';

const AdminRecipesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const RecipeList = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 15px;
  font-size: 0.75rem; // Reduce overall font size
`;

const RecipeRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const RecipeCell = styled.td`
  padding: 8px 6px;
  border-bottom: 1px solid #e0e0e0;
  max-width: 180px;
  overflow-wrap: break-word;
  word-wrap: break-word;

  /* Target the first cell in each row (Name column) */
  &:first-child {
    min-width: 160px;
  }
`;

const RecipeHeader = styled.th`
  padding: 8px 6px;
  text-align: left;
  background-color: #f0f0f0;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ActionButton = styled.button`
  margin: 0 2px;
  padding: 3px 6px;
  cursor: pointer;
  font-size: 0.9rem;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
`;

const PreviewButton = styled(ActionButton)`
  color: #4CAF50;
  &:hover {
    background-color: rgba(76, 175, 80, 0.1);
  }
`;

const MessageButton = styled(ActionButton)`
  color: #2196F3;
  &:hover {
    background-color: rgba(33, 150, 243, 0.1);
  }
`;

const EditButton = styled(ActionButton)`
  color: #3498db;
  &:hover {
    background-color: rgba(52, 152, 219, 0.1);
  }
`;

const DeleteButton = styled(ActionButton)`
  color: #e74c3c;
  &:hover {
    background-color: rgba(231, 76, 60, 0.1);
  }
`;

const StatusRadioGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const StatusRadio = styled.input`
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #ccc;
  
  &:checked {
    border: 2px solid #333;
  }

  &.red {
    background-color: ${({ checked }) => checked ? '#ff6b6b' : '#ffb3b3'};
  }

  &.yellow {
    background-color: ${({ checked }) => checked ? '#feca57' : '#fff0b3'};
  }

  &.green {
    background-color: ${({ checked }) => checked ? '#5cd859' : '#b3ffb3'};
  }
`;

const RefreshButton = styled(ActionButton)`
  color: #3498db;
  margin-bottom: 20px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const SectionHeader = styled.h3`
  margin-top: 30px;
  margin-bottom: 15px;
  color: #333;
  font-size: 1.2rem;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  float: right;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

const MessageModal = styled(Modal)``;

const MessageModalContent = styled(ModalContent)`
  max-width: 500px;
`;

const MessageInput = styled.textarea`
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const SendButton = styled(ActionButton)`
  background-color: #4CAF50;
`;

const StatusChangedModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 20px;
  border-radius: 5px;
  opacity: ${({ show }) => show ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  background-color: ${({ status }) => {
    switch(status) {
      case 'rejected': return '#ff6b6b';
      case 'pending': return '#feca57';
      case 'approved': return '#5cd859';
      default: return '#4CAF50';
    }
  }};
  z-index: 1000;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ConfirmationModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ConfirmationContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
`;

const ConfirmationButton = styled.button`
  margin: 0 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
`;

const FilterInputContainer = styled.div`
  position: relative;
  flex: 1;
`;

const FilterInput = styled.input`
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
`;

const PredictionList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

const PredictionItem = styled.li`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const PageInfo = styled.span`
  margin: 0 10px;
`;

const PageSizeSelect = styled.select`
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const TotalRecipesInfo = styled.span`
  margin-left: 20px;
`;



const TrustedUserIcon = styled(FaUserShield)`
  color: #4CAF50;
  margin-left: 5px;
  vertical-align: middle;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 0.9rem;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const FlagIcon = styled(FaFlag)`
  color: #f39c12;
  margin-left: 5px;
`;

const TrustedUserCell = styled(RecipeCell)`
  text-align: center;
`;

const formatDate = (dateValue) => {
  if (!dateValue) return 'N/A';
  
  let date;
  if (typeof dateValue === 'string') {
    date = parseISO(dateValue);
  } else if (dateValue instanceof Date) {
    date = dateValue;
  } else if (dateValue.toDate && typeof dateValue.toDate === 'function') {
    // Handle Firestore Timestamp
    date = dateValue.toDate();
  } else if (dateValue.seconds) {
    // Handle object with seconds
    date = new Date(dateValue.seconds * 1000);
  } else {
    return 'N/A';
  }
  
  return isValid(date) ? format(date, 'MM/dd/yyyy') : 'N/A';
};

const AdminRecipes = () => {
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageRecipient, setMessageRecipient] = useState(null);
  const [messageContents, setMessageContents] = useState({});
  const [messageSubjects, setMessageSubjects] = useState({});
  const [statusChangedModalVisible, setStatusChangedModalVisible] = useState(false);
  const [changedStatus, setChangedStatus] = useState('');
  const [selectedRecipeForDetail, setSelectedRecipeForDetail] = useState(null);
  const [recipeToDelete, setRecipeToDelete] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const navigate = useNavigate();
  const [nameFilter, setNameFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [namePredictions, setNamePredictions] = useState([]);
  const [emailPredictions, setEmailPredictions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recipesPerPage, setRecipesPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const loadRecipesAndUsers = async () => {
      if (!isOnline) {
        setError('You are offline. Please check your internet connection.');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const fetchedRecipes = await fetchAllRecipes();
        
        const uniqueSubmitterIds = [...new Set(fetchedRecipes.map(recipe => recipe.submitterId).filter(id => id))];
        console.log('Unique submitter IDs:', uniqueSubmitterIds);

        const userMap = {};
        for (const id of uniqueSubmitterIds) {
          const userData = await getUserProfile(id);
          if (userData) {
            userMap[id] = userData;
          }
        }

        console.log('User map:', userMap);
        setUsers(userMap);
        setRecipes(fetchedRecipes);
      } catch (err) {
        //console.error('Error in loadRecipesAndUsers:', err);
        setError('Failed to load recipes and user data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    loadRecipesAndUsers();
  }, [isOnline]);

  const filterAndSortRecipes = useCallback(() => {
    let filtered = recipes;
    
    // Apply name filter
    if (nameFilter) {
      filtered = filtered.filter(recipe => 
        recipe.Name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }
    
    // Apply email filter
    if (emailFilter) {
      filtered = filtered.filter(recipe => 
        recipe.Email.toLowerCase().includes(emailFilter.toLowerCase())
      );
    }
    
    // Sort recipes
    if (sortConfig.key) {
      filtered.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Special handling for date fields
        if (sortConfig.key === 'createdAt' || sortConfig.key === 'lastUpdated') {
          aValue = parseDateValue(aValue);
          bValue = parseDateValue(bValue);
        }

        if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
    
    setFilteredRecipes(filtered);
  }, [recipes, nameFilter, emailFilter, sortConfig]);

  useEffect(() => {
    filterAndSortRecipes();
  }, [filterAndSortRecipes]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (name) => {
    if (sortConfig.key === name) {
      return sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort />;
  };

  const handleApprovalChange = async (recipeId, newStatus) => {
    try {
      const lastUpdated = new Date().toISOString();
      let updateData;

      if (newStatus === 'pending') {
        updateData = {
          approvalRequested: true,
          approved: null,
          rejected: false,
          lastUpdated
        };
      } else if (newStatus === 'approved') {
        updateData = {
          approvalRequested: false,
          approved: true,
          rejected: false,
          lastUpdated
        };
      } else if (newStatus === 'rejected') {
        const adminNote = prompt("Please provide a reason for rejecting this recipe:");
        if (adminNote) {
          await rejectRecipe(recipeId, adminNote);
          updateData = {
            approvalRequested: false,
            approved: false,
            rejected: true,
            lastUpdated
          };
        } else {
          return;
        }
      }

      if (updateData) {
        await updateRecipeApproval(recipeId, updateData);

        // Update local state
        setRecipes(prevRecipes => prevRecipes.map(recipe => 
          recipe.id === recipeId ? { ...recipe, ...updateData } : recipe
        ));

        setChangedStatus(newStatus);
        setStatusChangedModalVisible(true);
        setTimeout(() => setStatusChangedModalVisible(false), 3000);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleMessageClick = (e, recipe) => {
    e.stopPropagation();
    setMessageRecipient(recipe.submitterId);
    setSelectedRecipe(recipe);
    // Set the default subject to the recipe name
    setMessageSubjects(prev => ({ ...prev, [recipe.id]: `Regarding Recipe: ${recipe.Name}` }));
    setMessageModalOpen(true);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!selectedRecipe || !messageRecipient) {
      alert('Please select a recipe and ensure recipient is set before sending a message.');
      return;
    }
    
    const subject = messageSubjects[selectedRecipe.id] || `Regarding Recipe: ${selectedRecipe.Name}`;
    const content = messageContents[selectedRecipe.id] || '';
    
    if (!content.trim()) {
      alert('Please enter a message.');
      return;
    }
    
    try {
      await sendMessageToUser(messageRecipient, subject, content, selectedRecipe.id, selectedRecipe.Name);
      alert('Message sent successfully');
      closeMessageModal();
    } catch (error) {
      alert('Failed to send message. Please try again.');
    }
  };

  const closeMessageModal = () => {
    setMessageModalOpen(false);
    setMessageRecipient(null);
    setSelectedRecipe(null);
    // Preserving message drafts
  };

  const handleSubjectChange = (recipeId, subject) => {
    setMessageSubjects(prev => ({ ...prev, [recipeId]: subject }));
  };

  const handleContentChange = (recipeId, content) => {
    setMessageContents(prev => ({ ...prev, [recipeId]: content }));
  };

  const handleRecipeNameClick = (recipe) => {
    setSelectedRecipeForDetail(recipe);
  };

  const closeRecipeDetailPopup = () => {
    setSelectedRecipeForDetail(null);
  };

  const handleEditClick = (recipeId) => {
    const editUrl = `/admin-edit-recipe/${recipeId}`;
    navigate(editUrl);
  };

  const handleDeleteClick = (recipe) => {
    setRecipeToDelete(recipe);
  };

  const confirmDelete = async () => {
    if (recipeToDelete) {
      try {
        await deleteRecipe(recipeToDelete.id);
        setRecipes(recipes.filter(recipe => recipe.id !== recipeToDelete.id));
        setRecipeToDelete(null);
      } catch (error) {
        alert('Failed to delete recipe. Please try again.');
      }
    }
  };

  const cancelDelete = () => {
    setRecipeToDelete(null);
  };

  const refreshRecipes = async () => {
    if (!isOnline) {
      setError('You are offline. Please check your internet connection.');
      return;
    }

    setIsRefreshing(true);
    try {
      const fetchedRecipes = await fetchAllRecipes();
      setRecipes(fetchedRecipes);
      setError(null);
    } catch (err) {
      setError('Failed to refresh recipes. Please try again.');
    } finally {
      setIsRefreshing(false);
    }
  };

  const totalPages = Math.ceil(filteredRecipes.length / recipesPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRecipesPerPageChange = (e) => {
    setRecipesPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const hasOtherData = (recipe) => {
    console.log('Recipe:', recipe);
    console.log('Other Method:', recipe['Other Method']);
    console.log('Other Food Type:', recipe['Other Food Type']);
    return (recipe['Other Method'] && recipe['Other Method'].trim() !== '') ||
           (recipe['Other Food Type'] && recipe['Other Food Type'].trim() !== '');
  };

  const renderRecipeSection = (status) => {
    let statusFilteredRecipes = filteredRecipes.filter(recipe => getRecipeStatus(recipe) === status);

    if (statusFilteredRecipes.length === 0) return null;

    // Calculate pagination
    const indexOfLastRecipe = currentPage * recipesPerPage;
    const indexOfFirstRecipe = indexOfLastRecipe - recipesPerPage;
    const currentRecipes = statusFilteredRecipes.slice(indexOfFirstRecipe, indexOfLastRecipe);

    return (
      <>
        <SectionHeader>{status.charAt(0).toUpperCase() + status.slice(1)} Recipes</SectionHeader>
        <RecipeList>
          <thead>
            <tr>
              <RecipeHeader onClick={() => requestSort('Name')}>
                Name {getSortIcon('Name')}
              </RecipeHeader>
              <RecipeHeader onClick={() => requestSort('Submitter')}>
                Submitter {getSortIcon('Submitter')}
              </RecipeHeader>
              <RecipeHeader onClick={() => requestSort('Email')}>
                Email {getSortIcon('Email')}
              </RecipeHeader>
              <RecipeHeader onClick={() => requestSort('createdAt')}>
                Date Submitted {getSortIcon('createdAt')}
              </RecipeHeader>
              <RecipeHeader onClick={() => requestSort('lastUpdated')}>
                Last Updated {getSortIcon('lastUpdated')}
              </RecipeHeader>
              <RecipeHeader>Trusted User</RecipeHeader>
              <RecipeHeader>Other Data</RecipeHeader>
              <RecipeHeader>Status</RecipeHeader>
              <RecipeHeader>Actions</RecipeHeader>
            </tr>
          </thead>
          <tbody>
            {currentRecipes.map(recipe => {
              const userData = users[recipe.submitterId];
              const isTrusted = userData?.isTrusted || false;
              return (
                <RecipeRow key={recipe.id}>
                  <RecipeCell>{recipe.Name}</RecipeCell>
                  <RecipeCell>{recipe.Submitter}</RecipeCell>
                  <RecipeCell>{recipe.Email}</RecipeCell>
                  <RecipeCell>{formatDate(recipe.createdAt)}</RecipeCell>
                  <RecipeCell>{formatDate(recipe.lastUpdated)}</RecipeCell>
                  <TrustedUserCell>
                    {isTrusted ? (
                      <>
                        <TrustedUserIcon title="Trusted User" />
                        {' Trusted'}
                      </>
                    ) : (
                      'Not Trusted'
                    )}
                  </TrustedUserCell>
                  <RecipeCell>
                    {hasOtherData(recipe) ? (
                      <FlagIcon title={`Has other data: ${recipe['Other Food Type'] ? 'Food Type' : ''} ${recipe['Other Method'] ? 'Method' : ''}`.trim()} />
                    ) : '-'}
                  </RecipeCell>
                  <RecipeCell>
                    <StatusRadioGroup>
                      <StatusRadio
                        type="radio"
                        className="red"
                        checked={getRecipeStatus(recipe) === 'rejected'}
                        onChange={() => handleApprovalChange(recipe.id, 'rejected')}
                        aria-label="Reject Recipe"
                      />
                      <StatusRadio
                        type="radio"
                        className="yellow"
                        checked={getRecipeStatus(recipe) === 'pending'}
                        onChange={() => handleApprovalChange(recipe.id, 'pending')}
                        aria-label="Set Recipe as Pending"
                      />
                      <StatusRadio
                        type="radio"
                        className="green"
                        checked={getRecipeStatus(recipe) === 'approved'}
                        onChange={() => handleApprovalChange(recipe.id, 'approved')}
                        aria-label="Approve Recipe"
                      />
                    </StatusRadioGroup>
                  </RecipeCell>
                  <RecipeCell>
                    <PreviewButton onClick={() => handleRecipeNameClick(recipe)} aria-label={`Preview ${recipe.Name}`}>
                      <FaEye size={16} />
                    </PreviewButton>
                    <EditButton onClick={() => handleEditClick(recipe.id)} aria-label={`Edit ${recipe.Name}`}>
                      <FaEdit size={16} />
                    </EditButton>
                    <MessageButton onClick={(e) => handleMessageClick(e, recipe)} aria-label={`Message about ${recipe.Name}`}>
                      <FaEnvelope size={16} />
                    </MessageButton>
                    <DeleteButton 
                      onClick={() => handleDeleteClick(recipe)} 
                      aria-label={`Delete ${recipe.Name}`}
                    >
                      <FaTrash size={16} />
                    </DeleteButton>
                  </RecipeCell>
                </RecipeRow>
              );
            })}
          </tbody>
        </RecipeList>
      </>
    );
  };

  const handleAddToShoppingList = (ingredient, recipeName, servings, originalServings) => {
    // Implement this function or pass it as a prop if it's defined elsewhere
  };

  const handleShareLink = (recipeId) => {
    // Implement this function to generate a shareable link
    return `${window.location.origin}/recipe/${recipeId}`;
  };

  // These functions will be implemented when edit proposal functionality is added


  const uniqueRecipeNames = useMemo(() => {
    return [...new Set(recipes.map(recipe => recipe.Name))];
  }, [recipes]);

  const uniqueEmails = useMemo(() => {
    return [...new Set(recipes.map(recipe => recipe.Email).filter(Boolean))];
  }, [recipes]);

  const updatePredictions = (input, allOptions, setPredictions) => {
    if (input.length === 0) {
      setPredictions([]);
    } else {
      const filtered = allOptions.filter(option => 
        option.toLowerCase().includes(input.toLowerCase())
      ).slice(0, 5); // Limit to 5 predictions
      setPredictions(filtered);
    }
  };

  const handleNameFilterChange = (e) => {
    const value = e.target.value;
    setNameFilter(value);
    updatePredictions(value, uniqueRecipeNames, setNamePredictions);
  };

  const handleEmailFilterChange = (e) => {
    const value = e.target.value;
    setEmailFilter(value);
    updatePredictions(value, uniqueEmails, setEmailPredictions);
  };

  const handlePredictionClick = (value, setFilter, setPredictions) => {
    setFilter(value);
    setPredictions([]);
  };

  const parseDateValue = (dateValue) => {
    if (!dateValue) return new Date(0); // Return earliest possible date if no value
    
    if (typeof dateValue === 'string') {
      const parsedDate = parseISO(dateValue);
      return isValid(parsedDate) ? parsedDate : new Date(dateValue);
    }
    
    if (dateValue instanceof Date) {
      return dateValue;
    }
    
    if (dateValue.toDate && typeof dateValue.toDate === 'function') {
      // Handle Firestore Timestamp
      return dateValue.toDate();
    }
    
    if (dateValue.seconds) {
      // Handle object with seconds
      return new Date(dateValue.seconds * 1000);
    }
    
    return new Date(0); // Return earliest possible date if parsing fails
  };

  if (loading) return <div>Loading recipes...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <AdminRecipesContainer>
      <h2 style={{ fontSize: '1.5rem' }}>Admin Recipes</h2>
      {!isOnline && <div>You are currently offline</div>}
      <RefreshButton onClick={refreshRecipes} disabled={isRefreshing} aria-label="Refresh Recipes">
        <FaSync /> {isRefreshing ? 'Refreshing...' : 'Refresh Recipes'}
      </RefreshButton>
      {error && <div style={{color: 'red', marginBottom: '15px'}}>{error}</div>}
      
      <Legend>
        <LegendItem>
          <FlagIcon /> Has other cooking method or food type data
        </LegendItem>
        <LegendItem>
          <TrustedUserIcon /> Trusted User
        </LegendItem>
      </Legend>
      
      <FilterContainer>
        <FilterInputContainer>
          <FilterInput 
            type="text" 
            placeholder="Filter by recipe name" 
            value={nameFilter} 
            onChange={handleNameFilterChange}
            aria-label="Filter by recipe name"
          />
          {namePredictions.length > 0 && (
            <PredictionList>
              {namePredictions.map((prediction, index) => (
                <PredictionItem 
                  key={index} 
                  onClick={() => handlePredictionClick(prediction, setNameFilter, setNamePredictions)}
                >
                  {prediction}
                </PredictionItem>
              ))}
            </PredictionList>
          )}
        </FilterInputContainer>
        <FilterInputContainer>
          <FilterInput 
            type="text" 
            placeholder="Filter by email" 
            value={emailFilter} 
            onChange={handleEmailFilterChange}
            aria-label="Filter by email"
          />
          {emailPredictions.length > 0 && (
            <PredictionList>
              {emailPredictions.map((prediction, index) => (
                <PredictionItem 
                  key={index} 
                  onClick={() => handlePredictionClick(prediction, setEmailFilter, setEmailPredictions)}
                >
                  {prediction}
                </PredictionItem>
              ))}
            </PredictionList>
          )}
        </FilterInputContainer>
      </FilterContainer>

      {recipes.length === 0 ? (
        <p>No recipes found.</p>
      ) : (
        <>
          {renderRecipeSection('pending')}
          {renderRecipeSection('approved')}
          {renderRecipeSection('rejected')}
        </>
      )}

      <PaginationContainer>
        <div>
          <PageSizeSelect value={recipesPerPage} onChange={handleRecipesPerPageChange}>
            <option value={10}>10 per page</option>
            <option value={25}>25 per page</option>
            <option value={50}>50 per page</option>
            <option value={100}>100 per page</option>
          </PageSizeSelect>
          <TotalRecipesInfo>
            Total Recipes: {filteredRecipes.length}
          </TotalRecipesInfo>
        </div>
        <div>
          <PageButton 
            onClick={() => handlePageChange(currentPage - 1)} 
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </PageButton>
          <PageInfo>
            Page {currentPage} of {totalPages}
          </PageInfo>
          <PageButton 
            onClick={() => handlePageChange(currentPage + 1)} 
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </PageButton>
        </div>
      </PaginationContainer>

      {/* Message Modal */}
      {messageModalOpen && selectedRecipe && (
        <MessageModal onClick={closeMessageModal} role="dialog" aria-modal="true" aria-labelledby="send-message-title">
          <MessageModalContent onClick={e => e.stopPropagation()}>
            <CloseButton onClick={closeMessageModal} aria-label="Close Message Modal">&times;</CloseButton>
            <h2 id="send-message-title">Send Message to User</h2>
            <p>Regarding Recipe: {selectedRecipe.Name}</p>
            <input
              type="text"
              value={messageSubjects[selectedRecipe.id] || `Regarding Recipe: ${selectedRecipe.Name}`}
              onChange={(e) => handleSubjectChange(selectedRecipe.id, e.target.value)}
              placeholder="Enter message subject..."
              style={{width: '100%', marginBottom: '10px', padding: '5px'}}
              aria-label="Message Subject"
            />
            <MessageInput
              value={messageContents[selectedRecipe.id] || ''}
              onChange={(e) => handleContentChange(selectedRecipe.id, e.target.value)}
              placeholder="Type your message here..."
              aria-label="Message Content"
            />
            <SendButton onClick={handleSendMessage} aria-label="Send Message">Send Message</SendButton>
          </MessageModalContent>
        </MessageModal>
      )}

      {/* Recipe Detail Popup */}
      {selectedRecipeForDetail && (
        <RecipeDetailPopup
          recipe={selectedRecipeForDetail}
          onClose={closeRecipeDetailPopup}
          onRatingChange={() => {}} // Implement this if needed
          user={auth.currentUser} // Pass the current user
          addToShoppingList={handleAddToShoppingList}
          onShareLink={handleShareLink}
        />
      )}

      {/* Status Changed Modal */}
      <StatusChangedModal show={statusChangedModalVisible} status={changedStatus} role="alert">
        Status changed to {changedStatus}
      </StatusChangedModal>

      {/* Confirmation Modal for Deletion */}
      {recipeToDelete && (
        <ConfirmationModal role="dialog" aria-modal="true" aria-labelledby="confirm-delete-title">
          <ConfirmationContent onClick={(e) => e.stopPropagation()}>
            <h2 id="confirm-delete-title">Confirm Deletion</h2>
            <p>Are you sure you want to delete the recipe "{recipeToDelete.Name}"?</p>
            <p>This action cannot be undone.</p>
            <ConfirmationButton 
              onClick={confirmDelete} 
              style={{backgroundColor: '#e74c3c', color: 'white'}}
              aria-label="Confirm Delete"
            >
              Delete
            </ConfirmationButton>
            <ConfirmationButton 
              onClick={cancelDelete} 
              style={{backgroundColor: '#3498db', color: 'white'}}
              aria-label="Cancel Delete"
            >
              Cancel
            </ConfirmationButton>
          </ConfirmationContent>
        </ConfirmationModal>
      )}
    </AdminRecipesContainer>
  );
};

export default AdminRecipes;