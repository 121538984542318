import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchRecipeById } from '../utils/firebaseUtils';
import RecipeForm from './RecipeForm';

const AdminEditRecipe = () => {
  const { recipeId } = useParams();
  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadRecipe = async () => {
      try {
        const recipeData = await fetchRecipeById(recipeId);
        setRecipe(recipeData);
      } catch (err) {
        //console.error('Error loading recipe:', err);
        setError('Failed to load recipe. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    loadRecipe();
  }, [recipeId]);

  //console.log('AdminEditRecipe rendered with recipeId:', recipeId);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!recipe) return <div>No recipe found</div>;

  return (
    <div>
      <h2>Edit Recipe: {recipe.Name}</h2>
      <p>Original Submitter: {recipe.Submitter}</p>
      <p>Submitter Email: {recipe.Email}</p>
      <RecipeForm 
        initialRecipe={recipe} 
        recipeId={recipeId} 
        isAdminEdit={true}
        originalSubmitter={recipe.Submitter}
        originalEmail={recipe.Email}
        originalSubmitterId={recipe.submitterId}
      />
    </div>
  );
};

export default AdminEditRecipe;