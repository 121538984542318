import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaSignOutAlt, FaUser, FaSignInAlt, FaBars, FaTimes, FaHome, FaInfoCircle } from 'react-icons/fa';

const HeaderContainer = styled.header`
  background-color: #2a2b2f;
  padding: 4px 10px 50px 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
  position: relative;
 

  @media (max-width: 768px) {
    padding-bottom: 50px; // Reduced from 30px to 5px (25px reduction)
    flex-direction: column; // Stack logo and nav vertically on mobile
  }
`;

const HeaderInnerContainer = styled.div`
  // max-width: 1200px;  or whatever maximum width you want
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  max-width: 40%;
`;

const LogoImage = styled.img`
  max-height: 115px;
  margin-top: 15px;
  margin-bottom: -10px;
  width: auto;
  max-width: 100%;
  height: auto;

  @media (max-width: 768px) {
    max-height: 201.25px; // 115px * 1.75
    transform: scale(1.75);
    transform-origin: left top;
    margin-bottom: 10px; // Add some margin to prevent overlap
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 769px) {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  @media (max-width: 768px) {
    display: ${({ $isOpen }) => $isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: fixed; // Change to fixed
    top: 50px; // Changed from 60px to 50px
    right: 10px;
    background-color: #000000; // Changed to black
    width: 180px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 11000 !important; // Significantly increased z-index
    align-items: flex-start;
    border-radius: 5px; // Optional: add rounded corners
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin-right: 15px; // Changed from margin-left to margin-right and increased to 20px
  font-size: 0.9rem;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin: 8px 0;
    color: #ffffff; // Ensure text is white for better contrast on black background
  }
`;

const NavButton = styled.button`
  background: none;
  border: none;
  color: white;
  text-decoration: none;
  margin-right: 0px; // Changed from margin-left to margin-right and increased to 20px
  padding: 0;
  font: inherit;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px; // Keep this to make room for the ItemCount

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin: 8px 0;
    text-align: left;
    width: 100%;
    color: #ffffff; // Ensure text is white for better contrast on black background
  }
`;

const ItemCount = styled.span`
  background-color: #ba5216;
  color: white;
  border-radius: 50%;
  padding: 4px 4px;
  font-size: 0.5rem;
  position: absolute;
  top: -2px;
  right: 8px; // Change from left to right positioning
  min-width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .8;
`;

const ActionButton = styled.button`
  background-color: #ba5216;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 3px;
  margin-left: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9rem;

  &:hover {
    background-color: #9a4512;
  }

  svg {
    margin-right: 4px;
    font-size: 0.8rem;
  }

  @media (max-width: 768px) {
    margin: 8px 0;
    width: 100%;
    justify-content: center;
    background-color: #ba5216; // Ensure it stands out on the black background
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  cursor: pointer;
  font-size: 1.2rem;
  z-index: 10000; // Significantly increased z-index
  color: #ba5216; // Add this line to change the color

  @media (max-width: 768px) {
    display: block;
    position: fixed; // Change to fixed
    right: 15px;
    top: 15px;
  }
`;

const AdminLink = styled(NavLink)`
  margin-left: 0px; // This creates a 10px gap between List and Admin (50% of the original 20px)
`;

const Header = ({ user, shoppingListItemCount, onOpenShoppingList, onSignOut, onOpenLoginModal }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <HeaderContainer>
        <HeaderInnerContainer>
          <LogoContainer to="/">
            <LogoImage 
              src="https://aws.boone-crockett.org/s3fs-public/styles/12-col_848px/public/thumbnails/image/wild_recipes_logo_6.png" 
              alt="Wild Recipes" 
            />
          </LogoContainer>
          <NavLinks $isOpen={isMenuOpen}>
            <NavLink to="/" onClick={() => setIsMenuOpen(false)}><FaHome /> Home</NavLink>
            <NavLink to="/about" onClick={() => setIsMenuOpen(false)}><FaInfoCircle /> About</NavLink> {/* Move this line here */}
            {user ? (
              <>
                <NavButton onClick={() => { onOpenShoppingList(); setIsMenuOpen(false); }}>
                  List
                  {shoppingListItemCount > 0 && <ItemCount>{shoppingListItemCount}</ItemCount>}
                </NavButton>
                <NavLink to="/my-recipes" onClick={() => setIsMenuOpen(false)}>My Recipes</NavLink>
                {user.isAdmin && (
                  <AdminLink to="/admin-dashboard" onClick={() => setIsMenuOpen(false)}>
                    Admin
                  </AdminLink>
                )}
                <NavLink to="/my-profile" onClick={() => setIsMenuOpen(false)}><FaUser /> {user.displayName || user.email}</NavLink>
                <ActionButton onClick={() => { onSignOut(); setIsMenuOpen(false); }}>
                  <FaSignOutAlt /> Sign Out
                </ActionButton>
              </>
            ) : (
              <ActionButton onClick={() => { onOpenLoginModal(); setIsMenuOpen(false); }}>
                <FaSignInAlt /> Sign In
              </ActionButton>
            )}
          </NavLinks>
        </HeaderInnerContainer>
      </HeaderContainer>
      <HamburgerIcon onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </HamburgerIcon>
    </>
  );
};

export default Header;