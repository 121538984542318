import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;

const Label = styled.label`
  margin-bottom: 5px;
`;

const Input = styled.input`
  margin-bottom: 5px;
  padding: 5px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #2a2b2f;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #1e4b70;
  }
`;

const MailchimpAPI = () => {
  const [mailchimpApiKey, setMailchimpApiKey] = useState('');
  const [mailchimpListId, setMailchimpListId] = useState('');
  const [mailchimpServerPrefix, setMailchimpServerPrefix] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchIntegrationSettings = async () => {
      const db = getFirestore();
      const settingsDoc = await getDoc(doc(db, 'settings', 'mailchimp'));
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setMailchimpApiKey(data.apiKey || '');
        setMailchimpListId(data.listId || '');
        setMailchimpServerPrefix(data.serverPrefix || '');
      }
    };

    fetchIntegrationSettings();
  }, []);

  useEffect(() => {
    //console.log('MailchimpAPI component rendered');
  }, []);

  const handleSave = async () => {
    if (!user) {
      //console.error('No user is signed in');
      alert('You must be signed in to save settings.');
      return;
    }

    //console.log('handleSave function called');
    //console.log('=== SAVING MAILCHIMP SETTINGS ===');
    //console.log('Attempting to save Mailchimp settings...');
    const db = getFirestore();
    try {
      const docRef = doc(db, 'settings', 'mailchimp');
      const data = {
        apiKey: mailchimpApiKey,
        listId: mailchimpListId,
        serverPrefix: mailchimpServerPrefix,
        isConfigured: true
      };
      //console.log('Data to be saved:', data);
      await setDoc(docRef, data);
      //console.log('Mailchimp settings saved successfully!');
      

      
      alert('Mailchimp settings saved successfully!');
    } catch (error) {
      //console.error('Error saving Mailchimp settings:', error);
      //console.error('Error details:', JSON.stringify(error));
      alert(`Failed to save Mailchimp settings. Error: ${error.message}`);
    }
  };

  return (
    <Container>
      <Title>Mailchimp Integration Settings</Title>
      <Form onSubmit={(e) => {
        e.preventDefault();
        handleSave();
      }}>
        <Label>API Key:</Label>
        <Input
          type="password"
          value={mailchimpApiKey}
          onChange={(e) => setMailchimpApiKey(e.target.value)}
        />
        <Label>List ID:</Label>
        <Input
          type="text"
          value={mailchimpListId}
          onChange={(e) => setMailchimpListId(e.target.value)}
        />
        <Label>Server Prefix:</Label>
        <Input
          type="text"
          value={mailchimpServerPrefix}
          onChange={(e) => setMailchimpServerPrefix(e.target.value)}
        />
        <Button type="submit">Save Mailchimp Settings</Button>
      </Form>
    </Container>
  );
};

export default MailchimpAPI;
