import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaVideo, FaTrash } from 'react-icons/fa';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
`;

const Title = styled.h2`
  margin-top: 0;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SaveButton = styled(Button)`
  background-color: #2ecc71;
  color: white;

  &:hover {
    background-color: #27ae60;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #e74c3c;
  color: white;

  &:hover {
    background-color: #c0392b;
  }
`;

const CancelButton = styled(Button)`
  background-color: #95a5a6;
  color: white;

  &:hover {
    background-color: #7f8c8d;
  }
`;

const VideoLinkModal = ({ isOpen, onClose, recipe, onSave, onDelete }) => {
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    if (recipe && recipe.videoUrl) {
      setVideoUrl(recipe.videoUrl);
    } else {
      setVideoUrl('');
    }
  }, [recipe]);

  if (!isOpen || !recipe) return null;

  const handleSave = () => {
    onSave(recipe.id, videoUrl);
  };

  const handleDelete = () => {
    onDelete(recipe.id);
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Title>Video Link for {recipe.Name}</Title>
        <Input
          type="url"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
          placeholder="Enter video URL"
        />
        <ButtonGroup>
          <SaveButton onClick={handleSave}>
            <FaVideo /> Save
          </SaveButton>
          {recipe.videoUrl && (
            <DeleteButton onClick={handleDelete}>
              <FaTrash /> Delete
            </DeleteButton>
          )}
          <CancelButton onClick={onClose}>Cancel</CancelButton>
        </ButtonGroup>
      </ModalContent>
    </ModalOverlay>
  );
};

export default VideoLinkModal;
