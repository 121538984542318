import React from 'react';
import styled from 'styled-components';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

const RecipeCard = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 200px;
  overflow: hidden;
`;

const RecipeTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const RecipeInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const RecipeDescription = styled.p`
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  white-space: normal;
`;

// Remove or comment out this line
// const RatingCount = styled.span`
//   font-size: 0.8rem;
//   color: #666;
//   margin-left: 5px;
// `;

const VoteCount = styled.span`
  font-size: 0.8rem;
  color: #666;
  margin-left: 5px;
`;

const StarRating = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`;

const Star = styled(FaStar)`
  color: #f1c40f;
  margin-right: 2px;
`;

const HalfStar = styled(FaStarHalfAlt)`
  color: #f1c40f;
  margin-right: 2px;
`;

const EmptyStar = styled(FaStar)`
  color: #ddd;
  margin-right: 2px;
`;

const RatingNumber = styled.span`
  font-size: 0.9rem;
  color: #555;
  margin-left: 5px;
`;

const RecipeDetail = ({ recipe }) => {
  const renderStarRating = (rating, numberOfRatings) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = 5 - Math.ceil(rating);
  
    return (
      <StarRating>
        {[...Array(fullStars)].map((_, index) => (
          <Star key={index} />
        ))}
        {hasHalfStar && <HalfStar />}
        {[...Array(emptyStars)].map((_, index) => (
          <EmptyStar key={index} />
        ))}
        <RatingNumber>{rating.toFixed(1)}</RatingNumber>
        <VoteCount>({numberOfRatings} {numberOfRatings === 1 ? 'vote' : 'votes'})</VoteCount>
      </StarRating>
    );
  };

  return (
    <RecipeCard>
      <RecipeTitle>{recipe.Name}</RecipeTitle>
      <RecipeInfo>
        <span>{recipe.GameType}</span>
        <span>{recipe.CookingTime} min</span>
      </RecipeInfo>
      <RecipeDescription>{recipe.Description}</RecipeDescription>
      {renderStarRating(recipe.averageRating || 0, recipe.numberOfRatings || 0)}
    </RecipeCard>
  );
};

export default RecipeDetail;