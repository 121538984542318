import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { fetchAboutContent } from '../utils/firebaseUtils';

// Styled Components
const PageWrapper = styled.div`
  background-color: #2a2b2f;
  min-height: 100vh;
  padding: 2rem;
`;

const AboutContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Content = styled.div`
  /* Optional: Add styles for your HTML content */
  
  h1 {
    color: #2a2b2f;
    margin-bottom: 1rem;
  }

  p {
    color: #555;
    line-height: 1.6;
  }
`;

const Loading = styled.p`
  text-align: center;
  color: #555;
`;

const ErrorMessage = styled.p`
  text-align: center;
  color: red;
`;

const About = () => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getContent = async () => {
      try {
        const fetchedContent = await fetchAboutContent();
        setContent(fetchedContent);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getContent();
  }, []);

  return (
    <PageWrapper>
      {loading ? (
        <Loading>Loading...</Loading>
      ) : error ? (
        <ErrorMessage>Failed to load About page content.</ErrorMessage>
      ) : (
        <AboutContainer>
          <Content dangerouslySetInnerHTML={{ __html: content }} />
        </AboutContainer>
      )}
    </PageWrapper>
  );
};

export default About;
