import { db } from '../utils/firebase';
import { doc, getDoc } from 'firebase/firestore';
import axios from 'axios';

const YOUTUBE_API_BASE_URL = 'https://www.googleapis.com/youtube/v3';

const getYouTubeApiSettings = async () => {
  try {
    //console.log('Attempting to fetch YouTube API settings from Firestore...');
    const settingsDoc = await getDoc(doc(db, 'settings', 'youtubeAPI'));
    //console.log('Firestore query completed');
    if (settingsDoc.exists()) {
      //console.log('YouTube API settings document exists');
      const data = settingsDoc.data();
      //console.log('Document data:', data);
      if (data && data.apiKey) {
        //console.log('API key found in document');
        return { apiKey: data.apiKey };
      } else {
        console.warn('API key not found in document data');
      }
    } else {
      console.warn('YouTube API settings document does not exist in Firestore');
    }
    return null;
  } catch (error) {
    //console.error('Error fetching YouTube API settings:', error);
    return null;
  }
};

/**
 * Fetches video information from YouTube API
 * @param {string} videoId - The YouTube video ID
 * @returns {Promise<Object>} - A promise that resolves to the video information
 */
export const getYouTubeVideoInfo = async (videoId) => {
  try {
    const { apiKey } = await getYouTubeApiSettings();
    
    const response = await axios.get(`${YOUTUBE_API_BASE_URL}/videos`, {
      params: {
        part: 'snippet,contentDetails,statistics',
        id: videoId,
        key: apiKey,
      },
    });

    if (response.data.items && response.data.items.length > 0) {
      const videoInfo = response.data.items[0];
      return {
        title: videoInfo.snippet.title,
        description: videoInfo.snippet.description,
        thumbnailUrl: videoInfo.snippet.thumbnails.medium.url,
        publishedAt: videoInfo.snippet.publishedAt,
        viewCount: videoInfo.statistics.viewCount,
        likeCount: videoInfo.statistics.likeCount,
        duration: videoInfo.contentDetails.duration,
      };
    } else {
      throw new Error('Video not found');
    }
  } catch (error) {
    //console.error('Error fetching YouTube video info:', error);
    throw error;
  }
};

/**
 * Fetches the thumbnail URL for a YouTube video
 * @param {string} youtubeLink - The full YouTube video URL
 * @returns {Promise<string>} - A promise that resolves to the thumbnail URL
 */
export const getYouTubeThumbnail = async (youtubeLink) => {
  try {
    const videoId = extractVideoId(youtubeLink);
    if (!videoId) {
      throw new Error('Invalid YouTube link');
    }

    const { apiKey } = await getYouTubeApiSettings() || {};
    //console.log('Retrieved API Key:', apiKey ? 'API Key exists' : 'API Key is missing');

    if (!apiKey) {
      console.warn('YouTube API key is missing. Using fallback thumbnail URL.');
      return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    }

    //console.log('Making API request with video ID:', videoId);
    const response = await axios.get(`${YOUTUBE_API_BASE_URL}/videos`, {
      params: {
        part: 'snippet',
        id: videoId,
        key: apiKey,
      },
    });

    //console.log('API Response:', response.status, response.statusText);

    if (response.data.items && response.data.items.length > 0) {
      const thumbnails = response.data.items[0].snippet.thumbnails;
      // Prioritize maxres, then standard, high, medium, and default
      return thumbnails.maxres?.url || 
             thumbnails.standard?.url || 
             thumbnails.high?.url || 
             thumbnails.medium?.url || 
             thumbnails.default?.url ||
             `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    } else {
      throw new Error('Video not found');
    }
  } catch (error) {
    //console.error('Error fetching YouTube thumbnail:', error);
    if (error.response) {
      //console.error('Error response:', error.response.status, error.response.data);
    }
    const videoId = extractVideoId(youtubeLink);
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  }
};

/**
 * Extracts the video ID from a YouTube URL
 * @param {string} url - The full YouTube video URL
 * @returns {string|null} - The extracted video ID or null if invalid
 */
function extractVideoId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
}
