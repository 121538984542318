import React, { Suspense } from 'react';
import { NavLink, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import AdminHome from './AdminHome'; // Ensure this import is present
import AdminUsers from './AdminUsers';
import AdminRecipes from './AdminRecipes';
import AdminMessages from './AdminMessages'; // This component already exists
//*import SchemaGenerator from './SchemaGenerator'; // Add this import
import AdminDropdownManager from './AdminDropdownManager'; // Import the AdminDropdownManager component
import AdminIntegrations from './MailchimpAPI'; // Add this import
//*import MigrationTool from './MigrationTool'; // Add this import
import YouTubeApiSettings from './YouTubeApiSettings'; // Add this import

// Error boundary component

// eslint-disable-next-line no-unused-vars
import { useAuth } from '../contexts/AuthContext';

const DashboardContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 60px); // Adjust based on your header height
`;

const Sidebar = styled.nav`
  width: 200px;
  background-color: #1a4b78; // Darker shade of blue
  padding: 20px 20px 40px 20px; // Increased bottom padding
`;

const SidebarLink = styled(NavLink)`
  display: block;
  color: white;
  text-decoration: none;
  padding: 10px 15px; // Slightly reduced padding
  margin-bottom: 8px; // Slightly reduced margin
  border-radius: 5px;
  font-size: 14px; // Reduced font size from 16px to 14px
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2c5f8e;
  }

  &.active {
    background-color: #3a6fa0;
    font-weight: bold;
  }
`;

const Content = styled.main`
  flex-grow: 1;
  padding: 30px; // Increased padding for content area
  background-color: #f0f0f0;
`;

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    const errorHandler = (error) => {
      //console.error('Error caught by error boundary:', error);
      setHasError(true);
    };

    window.addEventListener('error', errorHandler);
    return () => window.removeEventListener('error', errorHandler);
  }, []);

  if (hasError) {
    return <h1>Something went wrong.</h1>;
  }

  return children;
};

const AdminDashboard = ({ user }) => {
  if (!user) {
    return <div>Loading user data...</div>;
  }

  return (
    <DashboardContainer>
      <Sidebar>
        <SidebarLink to="/admin-dashboard" end>Dashboard</SidebarLink>
        <SidebarLink to="/admin-dashboard/recipes">Recipes</SidebarLink>
        <SidebarLink to="/admin-dashboard/users">Users</SidebarLink>
        <SidebarLink to="/admin-dashboard/messages">Messages</SidebarLink>
        <SidebarLink to="/admin-dashboard/dropdowns">Dropdowns</SidebarLink>
        {/* Change Integrations to MailChimp API */}
        <SidebarLink to="/admin-dashboard/mailchimp-api">MailChimp API</SidebarLink>
        <SidebarLink to="/admin-dashboard/youtube-api">YouTube API</SidebarLink>
      </Sidebar>
      <Content>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route index element={<AdminHome />} />
              <Route path="recipes" element={<AdminRecipes />} />
              <Route path="users" element={<AdminUsers />} />
              <Route path="messages" element={<AdminMessages />} />
              <Route path="dropdowns" element={<AdminDropdownManager />} />
              {/* Update the route for MailChimp API */}
              <Route path="mailchimp-api" element={<AdminIntegrations />} />
              <Route path="youtube-api" element={<YouTubeApiSettings />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </Content>
    </DashboardContainer>
  );
};

export default AdminDashboard;
