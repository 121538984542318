import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #2a2b2f;
  color: #fff;
  text-align: center;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 5px 0;
    height: auto;
  }
`;

const FooterText = styled.p`
  margin: 0;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 10px;
    white-space: nowrap;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>
        © {new Date().getFullYear()} Boone and Crockett Club. All rights reserved.
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
