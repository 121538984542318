import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    signInWithPopup, 
    GoogleAuthProvider, 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    onAuthStateChanged,
    signOut
} from 'firebase/auth';
import { auth } from '../utils/firebase';
import { getUserDocument, createOrUpdateUserDocument } from '../utils/firebaseUtils';
import styled from 'styled-components';

const googleLogoUrl = 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg';

const LoginContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LoginContent = styled.div`
  background-color: #2a2b2f;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const LoginForm = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 340px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputContainer = styled.div`
  width: 100%;
  max-width: 280px; // Adjust this value to match the button width
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  max-width: 280px; // Match this with InputContainer max-width
  padding: 10px;
  background-color: #2a2b2f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #1e4b70;
  }
`;

const GoogleButton = styled(Button)`
  background-color: white;
  color: #757575;
  border: 1px solid #757575;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #2a2b2f;
  cursor: pointer;
  font-size: 0.9rem;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 10px;
`;

const Logo = styled.img`
  width: 100%;
  max-height: 100px;
  object-fit: contain;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  text-align: center;
  color: #2a2b2f;
  margin-bottom: 20px;
`;

const LoginScreen = ({ onClose, logoUrl, isLoggedIn }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                onClose(); // Close the login screen
                navigate('/'); // Redirect to home page on successful login
            }
        });

        return () => unsubscribe();
    }, [navigate, onClose]);

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        if (newEmail && !validateEmail(newEmail)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
    };

    const handleAuth = async () => {
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        setLoading(true);
        setError('');

        const authFunction = isSignUp ? createUserWithEmailAndPassword : signInWithEmailAndPassword;

        try {
            const userCredential = await authFunction(auth, email, password);
            //console.log(`User ${isSignUp ? 'signed up' : 'logged in'}:`, userCredential.user);
            
            // Check if user document exists
            let userDoc = await getUserDocument(userCredential.user.uid);
            let isNewUser = false;

            if (!userDoc) {
                // Create new user document
                await createOrUpdateUserDocument(userCredential.user);
                //console.log('New user document created in Firestore');
                userDoc = await getUserDocument(userCredential.user.uid);
                isNewUser = true;
            } else {
                // Update last login time for existing users
                await createOrUpdateUserDocument(userCredential.user);
                //console.log('Existing user document updated in Firestore');
            }
            
            localStorage.setItem('userDetails', JSON.stringify(userDoc));
            
            setLoading(false);
            onClose(); // Close the login screen after successful authentication

            // Navigate based on whether a new user document was created
            if (isNewUser) {
                navigate('/my-profile');
            } else {
                navigate('/');
            }
        } catch (error) {
            //console.error(`${isSignUp ? 'Sign up' : 'Login'} error:`, error);
            setError(error.code === 'auth/wrong-password' ? 'Incorrect password' : error.message);
            setLoading(false);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            setLoading(true);
            setError('');
            
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: 'select_account'  // Force account selection
            });
            
            const result = await signInWithPopup(auth, provider);
            
            // Check if user document exists
            let userDoc = await getUserDocument(result.user.uid);
            let isNewUser = false;

            if (!userDoc) {
                // Create new user document
                await createOrUpdateUserDocument(result.user);
                userDoc = await getUserDocument(result.user.uid);
                isNewUser = true;
            } else {
                // Update last login time for existing users
                await createOrUpdateUserDocument(result.user);
            }
            
            localStorage.setItem('userDetails', JSON.stringify(userDoc));
            
            // Close modal and navigate
            onClose();
            
            // Navigate based on whether it's a new user
            if (isNewUser) {
                navigate('/my-profile');
            } else {
                navigate('/');
            }
        } catch (error) {
            console.error('Google sign-in error:', error);
            // Handle specific error cases
            if (error.code === 'auth/popup-closed-by-user') {
                setError('Sign-in was cancelled');
            } else if (error.code === 'auth/popup-blocked') {
                setError('Please allow popups for this website');
            } else {
                setError(error.message || 'Failed to sign in with Google');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleToggleSignUp = () => {
        setIsSignUp(!isSignUp);
        setError('');
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            onClose(); // Close the login screen after logout
        } catch (error) {
            //console.error('Logout error:', error);
            setError('Failed to log out. Please try again.');
        }
    };

    return (
        <LoginContainer onClick={onClose}>
            <LoginContent onClick={(e) => e.stopPropagation()}>
                {logoUrl && <Logo src={logoUrl} alt="Wild Recipes Logo" />}
                <LoginForm>
                    {isLoggedIn ? (
                        <>
                            <Title>Account</Title>
                            <Button onClick={handleLogout}>Log Out</Button>
                        </>
                    ) : (
                        <>
                            <Title>{isSignUp ? 'Sign Up' : 'Login'}</Title>
                            <InputContainer>
                                <Input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
                                <Input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {isSignUp && (
                                    <Input
                                        type="password"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value);
                                            setPasswordsMatch(e.target.value === password);
                                        }}
                                    />
                                )}
                            </InputContainer>
                            {!passwordsMatch && <ErrorMessage>Passwords do not match</ErrorMessage>}
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                            <Button onClick={handleAuth} disabled={loading}>
                                {loading ? 'Loading...' : isSignUp ? 'Sign Up' : 'Login'}
                            </Button>
                            <GoogleButton onClick={handleGoogleLogin}>
                                <img src={googleLogoUrl} alt="Google logo" />
                                Sign in with Google
                            </GoogleButton>
                            <ToggleButton onClick={handleToggleSignUp}>
                                {isSignUp ? 'Already have an account? Login' : 'Need an account? Sign Up'}
                            </ToggleButton>
                        </>
                    )}
                </LoginForm>
            </LoginContent>
        </LoginContainer>
    );
};

export default LoginScreen;