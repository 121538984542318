import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { auth } from './utils/firebase';
import { checkAdminStatus, checkFirstTimeUser, fetchRecipeById } from './utils/firebaseUtils';
import Header from './components/Header';
import PublicRecipes from './components/PublicRecipes';
import MyRecipes from './components/MyRecipes';
import RecipeForm from './components/RecipeForm';
import MyProfile from './components/MyProfile';
import AdminDashboard from './components/AdminDashboard';
import ShoppingList from './components/ShoppingList';
import { signOut } from 'firebase/auth';
import LogoutModal from './components/LogoutModal';
import LoginScreen from './components/LoginScreen';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import RecipeCountLogger from './components/RecipeCountLogger';
import RecipeDetailPopup from './components/RecipeDetailPopup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RecipeDetail from './components/RecipeDetail';
import About from './pages/About';
import AdminEditRecipe from './components/AdminEditRecipe';

// Remove this line as it's a duplicate import
// import { checkAdminStatus } from './utils/firebaseUtils';

import { migrateRatings } from './utils/firebaseUtils';

import YouTubeApiSettings from './components/YouTubeApiSettings';

import { HelmetProvider } from 'react-helmet-async';

const AppContent = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [userWithAdminStatus, setUserWithAdminStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shoppingList, setShoppingList] = useState([]);
  const [isShoppingListOpen, setIsShoppingListOpen] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          setLoading(true);
          const isAdmin = await checkAdminStatus(user.uid);
          const isFirstTimeUser = await checkFirstTimeUser(user.uid);
          setUserWithAdminStatus({ ...user, isAdmin });
          
          if (isFirstTimeUser) {
            navigate('/my-profile');
          }
        } catch (err) {
          //console.error("Error fetching user data:", err);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      } else {
        setUserWithAdminStatus(null);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user, navigate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const addToShoppingList = (item, recipeName) => {
    setShoppingList(prevList => [...prevList, { item, recipeName }]);
    setIsShoppingListOpen(true);
  };

  const handleOpenShoppingList = () => {
    setIsShoppingListOpen(true);
  };

  const handleCloseShoppingList = () => {
    setIsShoppingListOpen(false);
  };

  const handleSignOut = () => {
    //console.log("handleSignOut called in App.js");
    signOut(auth).then(() => {
      //console.log("Sign-out successful");
      setUserWithAdminStatus(null);
      setShoppingList([]);
      setIsShoppingListOpen(false);
      setShowLogoutModal(true);
      navigate('/'); // Navigate to home page after sign out
    }).catch((error) => {
      //console.error("Error signing out:", error);
    });
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };

  const handleOpenLoginModal = () => { // Add this function
    setShowLoginModal(true);
  };

  const handleCloseLoginModal = () => { // Add this function
    setShowLoginModal(false);
  };

  // Remove this function if it's not used
  // const handleAdminEditSubmit = () => {
  //   navigate('/admin-dashboard/recipes');
  // };

  return (
    <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header 
        user={userWithAdminStatus} 
        shoppingListItemCount={shoppingList.length}
        onOpenShoppingList={handleOpenShoppingList}
        onSignOut={handleSignOut}
        onOpenLoginModal={handleOpenLoginModal} // Pass this new prop
      />
      <main style={{ flex: 1 }}>
        <Routes>
          <Route path="/" element={<PublicRecipes user={userWithAdminStatus} addToShoppingList={addToShoppingList} />} />
          <Route path="/recipe/:id" element={<RecipeDetail />} />
          <Route path="/about" element={<About />} /> {/* Add this line for the About page route */}
          <Route path="/admin-edit-recipe/:recipeId" element={<AdminEditRecipe />} />
          {/* Protected routes */}
          {user && (
            <>
              <Route path="/my-recipes" element={<MyRecipes />} />
              <Route path="/recipe/:recipeId" element={<RecipeForm />} />
              <Route path="/recipe/edit/:recipeId" element={<RecipeForm />} />
              <Route path="/recipe/new" element={<RecipeForm />} />
              <Route path="/my-profile" element={<MyProfile />} />
              {userWithAdminStatus?.isAdmin && (
                <Route path="/admin-dashboard/*" element={<AdminDashboard user={userWithAdminStatus} />} />
              )}
            </>
          )}
          {/* Redirect for old route */}
          <Route path="/myrecipes" element={<Navigate to="/my-recipes" replace />} />
          <Route path="/admin-edit-recipe/test" element={<div>Test Admin Edit Page</div>} />
          <Route path="/admin/youtube-api-settings" element={<YouTubeApiSettings />} />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </main>
      <Footer /> {/* Add the Footer component here */}
      {isShoppingListOpen && (
        <ShoppingList 
          items={shoppingList}
          onClose={handleCloseShoppingList}
          setShoppingList={setShoppingList}
        />
      )}
      {showLogoutModal && (
        <LogoutModal onClose={handleCloseLogoutModal} />
      )}
      {showLoginModal && ( // Add this block
        <LoginScreen onClose={handleCloseLoginModal} />
      )}
      <RecipeCountLogger />
    </div>
  );
};

const App = () => {
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const recipeId = urlParams.get('recipe');
    if (recipeId) {
      fetchRecipeById(recipeId).then(recipeData => {
        setSelectedRecipe(recipeData);
      }).catch(error => {
        //console.error("Error fetching recipe:", error);
      });
    }
  }, []);

  const handleShareLink = (recipeId) => {
    const baseUrl = window.location.origin + window.location.pathname;
    return `${baseUrl}?recipe=${recipeId}`;
  };

  const closePopup = () => {
    setSelectedRecipe(null);
    // Remove the recipe parameter from the URL
    window.history.pushState({}, '', window.location.pathname);
  };

  const addToShoppingList = (item, recipeName) => {
    // Implement your shopping list logic here
    //console.log(`Added ${item} from ${recipeName} to shopping list`);
  };

  useEffect(() => {
    const runMigration = async () => {
      try {
        await migrateRatings();
        //console.log('Ratings migration completed successfully');
      } catch (error) {
        //console.error('Error during ratings migration:', error);
      }
    };

    runMigration();
  }, []);

  return (
    <HelmetProvider>
      <ErrorBoundary>
        <Router>
          <AuthProvider>
            <AppContent />
          </AuthProvider>
        </Router>
      </ErrorBoundary>
      {selectedRecipe && (
        <RecipeDetailPopup
          recipe={selectedRecipe}
          onClose={closePopup}
          onShareLink={handleShareLink}
          addToShoppingList={addToShoppingList}
          user={null} // Pass the user object if available
          onRatingChange={() => {}} // Implement this function if needed
        />
      )}
      <ErrorBoundary>

      </ErrorBoundary>

      <ToastContainer />
    </HelmetProvider>
  );
};

export default App;
