import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const YouTubeApiSettings = () => {
  const [apiKey, setApiKey] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      const db = getFirestore();
      const docRef = doc(db, 'settings', 'youtubeApi');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setApiKey(data.apiKey || '');
      }
    };

    fetchSettings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const db = getFirestore();
    const docRef = doc(db, 'settings', 'youtubeApi');

    try {
      await setDoc(docRef, { apiKey }, { merge: true });
      alert('YouTube API settings updated successfully');
    } catch (error) {
      //console.error('Error updating YouTube API settings:', error);
      alert('Failed to update YouTube API settings');
    }
  };

  return (
    <Container>
      <h2>YouTube API Settings</h2>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          placeholder="Enter YouTube API Key"
          required
        />
        <Button type="submit">Save Settings</Button>
      </Form>
    </Container>
  );
};

export default YouTubeApiSettings;
