import React, { useState, useEffect } from 'react';
import { fetchAllMessages, sendMessageReply, updateMessageResolved, fetchRecipeById } from '../utils/firebaseUtils';
import styled from 'styled-components';
import { formatTimestamp } from '../utils/dateUtils';
import { auth } from '../utils/firebase';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import RecipeDetailPopup from './RecipeDetailPopup';

const MessageSection = styled.div`
  margin-top: 20px;
`;

const MessageTitle = styled.h2`
  color: #2a2b2f;
  text-align: center;
`;

const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MessageItem = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
`;

const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
`;

const DateAndResolved = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ResolvedCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const ResolvedCheckbox = styled.input`
  margin-right: 5px;
`;

const ResolvedLabel = styled.label`
  font-size: 0.8em;
  color: #666;
`;

const ReplyForm = styled.form`
  margin-top: 10px;
`;

const ReplyInput = styled.textarea`
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
`;

const ReplyButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;

const AdminTag = styled.span`
  background-color: #f0ad4e;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-left: 5px;
`;

const UserInfo = styled.div`
  font-size: 0.9em;
  color: #666;
  margin-bottom: 5px;
`;

const RecipeLink = styled.a`
  color: #2a2b2f;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const CollapsibleSection = styled.div`
  margin-top: 20px;
`;

const CollapsibleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
`;

const CollapsibleTitle = styled.h3`
  margin: 0;
  color: #2a2b2f;
`;

const CollapsibleContent = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  margin-top: 10px;
`;

const MessageDate = styled.span`
  font-size: 0.9em;
  color: #666;
`;

const MessageContent = styled.div`
  margin-bottom: 5px;
`;

const SectionTitle = styled.h3`
  color: #2a2b2f;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const MessageThread = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
`;

const MessageThreadHeader = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MessageThreadTitle = styled.span`
  font-weight: bold;
`;

const MessageThreadDate = styled.span`
  font-size: 0.8rem;
  color: #666;
`;

const MessageThreadContent = styled.div`
  padding: 10px;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const AdminMessages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [replyTexts, setReplyTexts] = useState({});
  const [isResolvedOpen, setIsResolvedOpen] = useState(false);
  const [selectedRecipeForDetail, setSelectedRecipeForDetail] = useState(null);
  const [openThreads, setOpenThreads] = useState({});

  useEffect(() => {
    const loadMessages = async () => {
      try {
        const fetchedMessages = await fetchAllMessages();
        //console.log("Messages in AdminMessages component:", fetchedMessages);
        
        const sortedMessages = fetchedMessages.sort((a, b) => {
          const timestampA = a.timestamp ? a.timestamp.toMillis() : 0;
          const timestampB = b.timestamp ? b.timestamp.toMillis() : 0;
          return timestampB - timestampA;
        });
        
        setMessages(sortedMessages);
        setLoading(false);
      } catch (err) {
        //console.error("Error loading messages:", err);
        setError("Failed to load messages. Please try again.");
        setLoading(false);
      }
    };

    loadMessages();
  }, []);

  const handleReplyChange = (messageId, text) => {
    setReplyTexts(prev => ({ ...prev, [messageId]: text }));
  };

  const handleReplySubmit = async (e, messageId) => {
    e.preventDefault();
    const replyText = replyTexts[messageId];
    if (!replyText || !replyText.trim()) return;

    try {
      const currentUser = auth.currentUser;
      await sendMessageReply(messageId, replyText, {
        uid: currentUser.uid,
        isAdmin: true
      });
      const updatedMessages = await fetchAllMessages();
      
      const sortedUpdatedMessages = updatedMessages.sort((a, b) => {
        const timestampA = a.timestamp ? a.timestamp.toMillis() : 0;
        const timestampB = b.timestamp ? b.timestamp.toMillis() : 0;
        return timestampB - timestampA;
      });
      
      setMessages(sortedUpdatedMessages);
      setReplyTexts(prev => ({ ...prev, [messageId]: '' }));
    } catch (error) {
      //console.error('Error sending reply:', error);
      setError('Failed to send reply. Please try again.');
    }
  };

  const handleResolvedChange = async (messageId, isResolved) => {
    try {
      await updateMessageResolved(messageId, isResolved);
      const updatedMessages = await fetchAllMessages();
      
      const sortedUpdatedMessages = updatedMessages.sort((a, b) => {
        const timestampA = a.timestamp ? a.timestamp.toMillis() : 0;
        const timestampB = b.timestamp ? b.timestamp.toMillis() : 0;
        return timestampB - timestampA;
      });
      
      setMessages(sortedUpdatedMessages);
    } catch (error) {
      //console.error('Error updating message resolved status:', error);
      setError('Failed to update message status. Please try again.');
    }
  };

  const handleRecipeClick = async (recipeId) => {
    try {
      const recipe = await fetchRecipeById(recipeId);
      setSelectedRecipeForDetail(recipe);
    } catch (error) {
      //console.error('Error fetching recipe:', error);
      setError('Failed to load recipe details.');
    }
  };

  const closeRecipeDetailPopup = () => {
    setSelectedRecipeForDetail(null);
  };

  const handleAddToShoppingList = (ingredient, recipeName, servings, originalServings) => {
    //console.log('Add to shopping list:', ingredient, recipeName, servings, originalServings);
  };

  const handleShareLink = (recipeId) => {
    return `${window.location.origin}/recipe/${recipeId}`;
  };

  const toggleThread = (recipeId) => {
    setOpenThreads(prev => ({
      ...prev,
      [recipeId]: !prev[recipeId]
    }));
  };

  const renderMessage = (message) => {
    return (
      <MessageItem key={message.id}>
        <MessageHeader>
          <strong>Subject: {message.subject}</strong>
          <DateAndResolved>
            <MessageDate>Date: {formatTimestamp(message.timestamp)}</MessageDate>
            <ResolvedCheckboxContainer>
              <ResolvedCheckbox
                type="checkbox"
                id={`resolved-${message.id}`}
                checked={message.resolved}
                onChange={(e) => handleResolvedChange(message.id, e.target.checked)}
              />
              <ResolvedLabel htmlFor={`resolved-${message.id}`}>Resolved</ResolvedLabel>
            </ResolvedCheckboxContainer>
          </DateAndResolved>
        </MessageHeader>
        <UserInfo>
          To: {message.userDisplayName || 'Unknown'} ({message.userEmail || 'No email'})
          {message.type && <AdminTag>{message.type}</AdminTag>}
        </UserInfo>
        <UserInfo>
          From: {message.from || 'System'}
        </UserInfo>
        {message.recipeId && (
          <UserInfo>
            Recipe ID:{' '}
            <RecipeLink
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleRecipeClick(message.recipeId);
              }}
            >
              {message.recipeId}
            </RecipeLink>
          </UserInfo>
        )}
        <MessageContent>{message.content}</MessageContent>
        {message.replies && message.replies.map((reply, index) => (
          <MessageContent key={index}>
            <strong>{reply.isAdminReply ? 'Admin' : (message.userDisplayName || 'User')}:</strong> {reply.text}
            <MessageDate> - {formatTimestamp(reply.timestamp)}</MessageDate>
          </MessageContent>
        ))}
        <ReplyForm onSubmit={(e) => handleReplySubmit(e, message.id)}>
          <ReplyInput
            value={replyTexts[message.id] || ''}
            onChange={(e) => handleReplyChange(message.id, e.target.value)}
            placeholder="Type your reply here..."
          />
          <ReplyButton type="submit">Send Reply</ReplyButton>
        </ReplyForm>
      </MessageItem>
    );
  };

  const groupMessagesByRecipe = (messages) => {
    const groups = {};
    messages.forEach(message => {
      if (message.recipeId) {
        if (!groups[message.recipeId]) {
          groups[message.recipeId] = [];
        }
        groups[message.recipeId].push(message);
      } else {
        if (!groups['uncategorized']) {
          groups['uncategorized'] = [];
        }
        groups['uncategorized'].push(message);
      }
    });
    return groups;
  };

  const renderMessageGroups = (messages) => {
    const groups = groupMessagesByRecipe(messages);
    return Object.entries(groups).map(([recipeId, groupMessages]) => {
      const isOpen = openThreads[recipeId] || false;
      const latestMessage = groupMessages[0]; // Assuming messages are already sorted
      return (
        <MessageThread key={recipeId}>
          <MessageThreadHeader onClick={() => toggleThread(recipeId)}>
            <MessageThreadTitle>
              {recipeId === 'uncategorized' ? 'Uncategorized Messages' : `Recipe: ${latestMessage.subject}`}
            </MessageThreadTitle>
            <MessageThreadDate>{formatTimestamp(latestMessage.timestamp)}</MessageThreadDate>
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </MessageThreadHeader>
          <MessageThreadContent isOpen={isOpen}>
            {groupMessages.map(renderMessage)}
          </MessageThreadContent>
        </MessageThread>
      );
    });
  };

  if (loading) return <div>Loading messages...</div>;
  if (error) return <div>Error: {error}</div>;

  const unresolvedMessages = messages.filter(message => !message.resolved);
  const resolvedMessages = messages.filter(message => message.resolved);

  return (
    <MessageSection>
      <MessageTitle>User Messages</MessageTitle>
      {messages.length === 0 ? (
        <p>No messages found.</p>
      ) : (
        <>
          <SectionTitle>Unresolved Messages</SectionTitle>
          <MessageList>
            {renderMessageGroups(unresolvedMessages)}
          </MessageList>
          <CollapsibleSection>
            <CollapsibleHeader onClick={() => setIsResolvedOpen(!isResolvedOpen)}>
              <CollapsibleTitle>
                Resolved Messages ({resolvedMessages.length})
              </CollapsibleTitle>
              {isResolvedOpen ? <FaChevronUp /> : <FaChevronDown />}
            </CollapsibleHeader>
            <CollapsibleContent isOpen={isResolvedOpen}>
              <MessageList>
                {renderMessageGroups(resolvedMessages)}
              </MessageList>
            </CollapsibleContent>
          </CollapsibleSection>
        </>
      )}
      {selectedRecipeForDetail && (
        <RecipeDetailPopup
          recipe={selectedRecipeForDetail}
          onClose={closeRecipeDetailPopup}
          onRatingChange={() => {}} // Implement this if needed
          user={auth.currentUser} // Pass the current user
          addToShoppingList={handleAddToShoppingList}
          onShareLink={handleShareLink}
        />
      )}
    </MessageSection>
  );
};

export default AdminMessages;