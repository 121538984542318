import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc, addDoc, collection, updateDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';
// Import removed as it was never used
import styled from 'styled-components';
import { FaPlus, FaMinus, FaCheckCircle, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { getAuth } from 'firebase/auth';
import { keyframes } from 'styled-components';
import { parseIngredients } from '../utils/ingredientParser';
import { sendMessageToAdmin } from '../utils/firebaseUtils'; // Add this import
import Select from 'react-select';
import { components } from 'react-select';

const FormContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #e6e0d4;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
`;

const FormSection = styled.section`
  margin-bottom: 1rem;
  background-color: #f5f1e8;
  padding: 0.75rem;
  border-radius: 6px;
`;

const SectionTitle = styled.h2`
  font-size: 1rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #34495e;
  padding-bottom: 0.2rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  margin-bottom: 0.5rem;
`;

const InputWrapper = styled.div`
  flex: 1;
  min-width: 120px;
  margin-bottom: 0.5rem;
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.2rem;
  font-weight: bold;
  color: #34495e;
  font-size: 0.8rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.3rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 0.8rem;
  background-color: #fff;
`;

const LightText = styled.span`
  font-weight: normal;
  color: #7f8c8d;
  font-size: 0.75rem;
  margin-left: 0.5rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.3rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 0.8rem;
  min-height: 60px;
  background-color: #fff;
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: #999;
    font-size: 0.8rem;
  }
`;

const CharCounter = styled.span`
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 0.7rem;
  color: #777;
`;

const StyledSelect = styled(Select)`
  .react-select__control {
    border-color: #bdc3c7;
    background-color: #fff;
    min-height: 30px;
  }
  .react-select__menu {
    background-color: #fff;
    font-size: 0.8rem;
  }
  .react-select__value-container {
    padding: 0 6px;
  }
  .react-select__multi-value__remove {
    display: inline-flex;
    padding: 0;
    margin-left: 4px;
  }
  .react-select__clear-indicator {
    display: none;
  }
  .react-select__multi-value {
    background-color: #e8f0fe;
    border-radius: 2px;
    margin: 2px;
    padding-right: 6px;
  }
  .react-select__multi-value__label {
    font-size: 0.8rem;
    color: #2c3e50;
    padding-right: 6px;
  }
  .react-select__dropdown-indicator {
    padding: 0 4px;
  }
  .react-select__dropdown-indicator svg {
    width: 10px;
    height: 10px;
  }
`;

const CustomClearIndicator = () => null;

const CustomMultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <FaTimes size={10} />
    </components.MultiValueRemove>
  );
};

const CustomDropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown size={10} />
    </components.DropdownIndicator>
  );
};

const Button = styled.button`
  background-color: #34495e;
  color: white;
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2c3e50;
  }
`;

const IngredientList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const IngredientItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-bottom: 0.3rem;
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${props => props.isClosing ? fadeOut : fadeIn} 0.3s ease-in-out;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalIcon = styled(FaCheckCircle)`
  color: #4CAF50;
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const ModalText = styled.p`
  font-size: 1.2rem;
  margin: 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const CancelButton = styled(Button)`
  background-color: #e74c3c;
  &:hover {
    background-color: #c0392b;
  }
`;

const TimeInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const TimeInputField = styled(Input)`
  width: 100%;
  height: 38px;
  padding-right: 20px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: #999;
    font-size: 0.8rem;
  }
`;

const TimeInputControls = styled.div`
  position: absolute;
  right: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const TimeInputButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  &:hover {
    color: #000;
  }
`;

const TimeInput = ({ id, name, value, onChange }) => {
  const handleIncrement = () => {
    const newValue = parseInt(value || "0", 10) + 5;
    onChange({ target: { name, value: newValue.toString() } });
  };

  const handleDecrement = () => {
    const newValue = Math.max(0, parseInt(value || "0", 10) - 5);
    onChange({ target: { name, value: newValue.toString() } });
  };

  const handleManualInput = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '' || /^\d+$/.test(inputValue)) {
      onChange(e);
    }
  };

  return (
    <TimeInputWrapper>
      <TimeInputField
        id={id}
        name={name}
        type="text"
        value={value}
        onChange={handleManualInput}
        min="0"
        placeholder="Type or click"
      />
      <TimeInputControls>
        <TimeInputButton onClick={handleIncrement} type="button">
          <FaChevronUp size={12} />
        </TimeInputButton>
        <TimeInputButton onClick={handleDecrement} type="button">
          <FaChevronDown size={12} />
        </TimeInputButton>
      </TimeInputControls>
    </TimeInputWrapper>
  );
};

// Add this new styled component
const TimeUnitSelect = styled.select`
  height: 38px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  background-color: #fff;
  font-size: 0.8rem;
  margin-left: 5px;
`;

// Custom Select component
const CustomSelect = React.forwardRef(({ options, value, onChange, onInputChange, ...props }, ref) => {
  const handleChange = (selectedOption) => {
    onChange(selectedOption ? selectedOption.value : '');
  };

  const selectedOption = options.find(option => option.value === value);

  return (
    <StyledSelect
      {...props}
      ref={ref}
      options={options}
      value={selectedOption}
      onChange={handleChange}
      onInputChange={onInputChange}
      components={{
        DropdownIndicator: CustomDropdownIndicator,
        ClearIndicator: CustomClearIndicator,
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          minHeight: '30px',
          height: '30px',
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: '30px',
          padding: '0 6px',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0px',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '30px',
        }),
      }}
    />
  );
});

const RecipeForm = ({ isAdminEdit = false }) => {
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // Remove unused state variables
  // const [isClosing, setIsClosing] = useState(false);

  const [formData, setFormData] = useState({
    recipeName: '',
    description: '',
    foodType: {},
    serves: '',
    difficulty: { value: '5', label: '5 (Medium)' }, // Set default difficulty to 5
    prepTime: '',
    prepTimeUnit: 'min',
    cookingTime: '',
    cookingTimeUnit: 'min',
    cookingMethod: [],
    otherCookingMethod: '', // Add this new state
    ingredients: [{ amount: '', measurement: '', name: '' }],
    preparationSteps: '',
    notes: '',
    imageUrl: '',
    otherFoodType: '', // Add this line
  });
  const [foodTypeCategories, setFoodTypeCategories] = useState([]);
  const [foodTypeCategoryOrder, setFoodTypeCategoryOrder] = useState([]);
  const [difficultyOptions] = useState([
    { value: '1', label: '1 (Easiest)' },
    { value: '3', label: '3' },
    { value: '5', label: '5 (Medium)' },
    { value: '7', label: '7' },
    { value: '10', label: '10 (Most Difficult)' }
  ]);
  const [cookingMethodOptions, setCookingMethodOptions] = useState([]);

  const textAreaRef = useRef(null);

  const autoResize = (element) => {
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + 'px';
  };

  useEffect(() => {
    if (textAreaRef.current) {
      autoResize(textAreaRef.current);
    }
  }, [formData.preparationSteps]);

  // Add these state variables
  const [originalSubmitter, setOriginalSubmitter] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [originalSubmitterId, setOriginalSubmitterId] = useState('');

  // Add this state variable
  const [measurementOptions, setMeasurementOptions] = useState([]);

  // Create an array of refs for each ingredient's measurement select
  const measurementRefs = useRef([]);

  useEffect(() => {
    const fetchRecipeData = async () => {
      if (recipeId) {
        setIsEditMode(true);
        try {
          const recipeDoc = await getDoc(doc(db, 'recipes', recipeId));
          if (recipeDoc.exists()) {
            const recipeData = recipeDoc.data();
            
            // Parse ingredients
            let parsedIngredients;
            if (typeof recipeData.Ingredients === 'string') {
              parsedIngredients = parseIngredients(recipeData.Ingredients);
            } else if (Array.isArray(recipeData.Ingredients)) {
              parsedIngredients = recipeData.Ingredients.map(ing => ({
                amount: ing.amount ? ing.amount.toString() : '',
                measurement: ing.measurement || '',
                name: ing.name || ''
              }));
            } else {
              parsedIngredients = [{ amount: '', measurement: '', name: '' }];
            }

            const parsedFoodType = {};
            
            if (Array.isArray(recipeData['Food Type'])) {
              recipeData['Food Type'].forEach(item => {
                if (!parsedFoodType[item.category]) {
                  parsedFoodType[item.category] = [];
                }
                parsedFoodType[item.category].push({ value: item.value, label: item.label });
              });
            }

            // Parse prep time and cooking time
            const parsePrepTime = (prepTime) => {
              const match = prepTime.match(/(\d+)\s*(\w+)/);
              return match ? { time: match[1], unit: match[2] } : { time: '', unit: 'min' };
            };

            const parsedPrepTime = parsePrepTime(recipeData['Prep Time'] || '');
            const parsedCookingTime = parsePrepTime(recipeData['Cooking Time'] || '');

            setFormData({
              recipeName: recipeData.Name || '',
              description: recipeData.Description || '',
              foodType: parsedFoodType,
              serves: recipeData.Serves || '',
              difficulty: recipeData.Difficulty 
                ? { value: recipeData.Difficulty, label: recipeData.Difficulty } 
                : { value: '5', label: '5 (Medium)' }, // Default to 5 if no difficulty is set
              prepTime: parsedPrepTime.time,
              prepTimeUnit: parsedPrepTime.unit,
              cookingTime: parsedCookingTime.time,
              cookingTimeUnit: parsedCookingTime.unit,
              cookingMethod: recipeData['Cooking Method'] 
                ? recipeData['Cooking Method'].split(', ').map(method => ({ value: method, label: method }))
                : [],
              otherCookingMethod: recipeData['Other Method'] || '', // Add this new field
              ingredients: parsedIngredients,
              preparationSteps: recipeData['Preparation Steps'] || '',
              notes: recipeData.Notes || '',
              imageUrl: recipeData.imageUrl || '',
              otherFoodType: recipeData['Other Food Type'] || '', // Add this line
            });

            if (isAdminEdit) {
              setOriginalSubmitter(recipeData.Submitter || '');
              setOriginalEmail(recipeData.Email || '');
              setOriginalSubmitterId(recipeData.submitterId || '');
            }
          }
        } catch (error) {
          //console.error('Error fetching recipe data:', error);
        }
      } else if (location.state && location.state.recipe) {
        const recipeData = location.state.recipe;
        
        // Parse prep time and cooking time
        const parsePrepTime = (prepTime) => {
          const match = prepTime.match(/(\d+)\s*(\w+)/);
          return match ? { time: match[1], unit: match[2] } : { time: '', unit: 'min' };
        };

        const parsedPrepTime = parsePrepTime(recipeData['Prep Time'] || '');
        const parsedCookingTime = parsePrepTime(recipeData['Cooking Time'] || '');

        setFormData({
          recipeName: recipeData.Name || '',
          description: recipeData.Description || '',
          foodType: recipeData['Food Type'] || {},
          serves: recipeData.Serves || '',
          difficulty: recipeData.Difficulty 
            ? { value: recipeData.Difficulty, label: recipeData.Difficulty } 
            : { value: '5', label: '5 (Medium)' }, // Default to 5 if no difficulty is set
          prepTime: parsedPrepTime.time,
          prepTimeUnit: parsedPrepTime.unit,
          cookingTime: parsedCookingTime.time,
          cookingTimeUnit: parsedCookingTime.unit,
          cookingMethod: recipeData['Cooking Method'] ? { value: recipeData['Cooking Method'], label: recipeData['Cooking Method'] } : null,
          ingredients: typeof recipeData.Ingredients === 'string'
            ? parseIngredients(recipeData.Ingredients)
            : [{ amount: '', measurement: '', name: '' }],
          preparationSteps: recipeData['Preparation Steps'] || '',
          notes: recipeData.Notes || '',
          imageUrl: recipeData.imageUrl || '',
          otherFoodType: recipeData['Other Food Type'] || '', // Add this line
        });
        setIsEditMode(true);
      } else {
        setIsEditMode(false);
      }
    };

    fetchRecipeData();
  }, [recipeId, location.state, isAdminEdit]);

  useEffect(() => {
    const fetchFoodTypes = async () => {
      try {
        const docRef = doc(db, 'settings', 'foodDropdown');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const parsedFoodTypes = {};
          const categoryOrder = [];
          Object.entries(data).forEach(([category, types]) => {
            parsedFoodTypes[category] = types.split(',').map(item => ({ value: item.trim(), label: item.trim() }));
            categoryOrder.push(category);
          });
          setFoodTypeCategories(parsedFoodTypes);
          setFoodTypeCategoryOrder(categoryOrder);
        }
      } catch (error) {
        //console.error('Error fetching food types:', error);
      }
    };

    const fetchCookingMethods = async () => {
      try {
        const docRef = doc(db, 'settings', 'methodDropdown');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.Method) {
            const methods = data.Method.split(',').map(method => ({
              value: method.trim(),
              label: method.trim()
            }));
            setCookingMethodOptions(methods);
          } else {
            //console.log("No cooking methods found in the document");
          }
        } else {
          //console.log("No methodDropdown document found in Firestore");
        }
      } catch (error) {
        //console.error('Error fetching cooking methods:', error);
      }
    };

    const fetchMeasurementOptions = async () => {
      try {
        const docRef = doc(db, 'settings', 'measurementDropdowns');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.measurement) {
            const options = data.measurement.split('|').map(measurement => ({
              value: measurement.trim(),
              label: measurement.trim()
            }));
            setMeasurementOptions(options);
          }
        }
      } catch (error) {
        //console.error('Error fetching measurement options:', error);
      }
    };

    fetchFoodTypes();
    fetchCookingMethods();
    fetchMeasurementOptions();
  }, []);

  const [descriptionLength, setDescriptionLength] = useState(0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    if (name === 'description') {
      setDescriptionLength(value.length);
    }
    
    if (name === 'preparationSteps' && textAreaRef.current) {
      autoResize(textAreaRef.current);
    }
  };

  const handleSelectChange = (name, selectedOption) => {
    setFormData(prev => ({ ...prev, [name]: selectedOption }));
  };

  const handleMultiSelectChange = (name, selectedOptions) => {
    if (foodTypeCategoryOrder.includes(name)) {
      // This is a food type category
      setFormData(prev => ({
        ...prev,
        foodType: {
          ...prev.foodType,
          [name]: selectedOptions
        }
      }));
    } else {
      // This is for other multi-select fields (e.g., cooking method)
      setFormData(prev => ({
        ...prev,
        [name]: selectedOptions
      }));
    }
  };

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = [...formData.ingredients];
    if (field === 'amount') {
      const mixedNumberRegex = /^(\d+(\s+\d+\/\d+)?|\d+\/\d+)$/;
      if (value === '' || mixedNumberRegex.test(value) || /^[\d\s/]+$/.test(value)) {
        newIngredients[index][field] = value;
      }
    } else {
      newIngredients[index][field] = value;
    }
    setFormData(prev => ({ ...prev, ingredients: newIngredients }));
  };

  const addIngredient = () => {
    setFormData(prev => ({
      ...prev,
      ingredients: Array.isArray(prev.ingredients) 
        ? [...prev.ingredients, { amount: '', measurement: '', name: '' }]
        : [{ amount: '', measurement: '', name: '' }]
    }));
  };

  const removeIngredient = (index) => {
    setFormData(prev => ({
      ...prev,
      ingredients: Array.isArray(prev.ingredients)
        ? prev.ingredients.filter((_, i) => i !== index)
        : []
    }));
  };

  const handleTimeInputChange = (e) => {
    const { name, value } = e.target;
    const numValue = value === '' ? '' : parseInt(value, 10);
    const roundedValue = numValue === '' ? '' : Math.max(0, numValue);
    setFormData(prev => ({ ...prev, [name]: roundedValue }));
  };

  const handleTimeUnitChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      // Handle no user logged in (e.g., show an error message)
      return;
    }

    try {
      const recipeData = {
        Name: formData.recipeName,
        Description: formData.description,
        'Food Type': Object.entries(formData.foodType).flatMap(([category, options]) => 
          options.map(option => ({
            category,
            value: option.value,
            label: option.label
          }))
        ),
        'Other Food Type': formData.otherFoodType, // Add this line
        Serves: formData.serves,
        Difficulty: formData.difficulty ? formData.difficulty.value : '',
        'Prep Time': `${formData.prepTime} ${formData.prepTimeUnit}`,
        'Cooking Time': `${formData.cookingTime} ${formData.cookingTimeUnit}`,
        'Cooking Method': formData.cookingMethod.map(method => method.value).join(', '),
        'Other Method': formData.otherCookingMethod, // Add this new field
        Ingredients: formData.ingredients.map(ing => `${ing.amount} ${ing.measurement} ${ing.name}`).join('|'),
        'Preparation Steps': formData.preparationSteps || null, // Add this line
        Notes: formData.notes,
        Email: isAdminEdit ? originalEmail : user.email,
        Submitter: isAdminEdit ? originalSubmitter : user.displayName || user.email,
        submitterId: isAdminEdit ? originalSubmitterId : user.uid,
        lastUpdated: new Date(),
        imageUrl: formData.imageUrl,
        approvalRequested: false,
        approved: false,
        rejected: false,
        // Remove the 'status' field as we're using the other fields to determine status
      };

      // Remove any fields that are null or undefined
      Object.keys(recipeData).forEach(key => 
        (recipeData[key] === null || recipeData[key] === undefined) && delete recipeData[key]
      );

      if (isEditMode) {
        await updateDoc(doc(db, 'recipes', recipeId), recipeData);
        // Send a message to the admin about the updated recipe
        await sendMessageToAdmin({
          subject: 'Recipe Updated',
          content: `User ${user.displayName || user.email} has updated the recipe: ${recipeData.Name}. Please review the changes.`,
          recipeId: recipeId,
          recipeName: recipeData.Name
        });
      } else {
        recipeData.createdAt = new Date();
        recipeData.averageRating = 0;
        recipeData.numberOfRatings = 0;

        const docRef = await addDoc(collection(db, 'recipes'), recipeData);
        await updateDoc(docRef, { recipeId: docRef.id });
      }

      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        navigate('/my-recipes');
      }, 3000); // Close modal and redirect after 3 seconds
    } catch (error) {
      //console.error("Error saving recipe:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleCancel = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  const handleKeyDown = (e, fieldName, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      const formElements = Array.from(form.elements);
      const currentIndex = formElements.indexOf(e.target);
      const nextElement = formElements[currentIndex + 1];
      if (nextElement) {
        nextElement.focus();
      }
    }
  };



  // Remove unused Menu component
  // const Menu = props => { ... };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>
        <FormSection>
          <SectionTitle>Basic Information</SectionTitle>
          <InputGroup>
            <InputWrapper>
              <Label htmlFor="recipeName">Recipe Name</Label>
              <Input
                id="recipeName"
                name="recipeName"
                value={formData.recipeName}
                onChange={handleInputChange}
                onKeyDown={(e) => handleKeyDown(e, 'recipeName')}
                required
              />
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="serves">Serves</Label>
              <Input
                id="serves"
                name="serves"
                type="number"
                min="0"
                value={formData.serves}
                onChange={handleInputChange}
                onKeyDown={(e) => handleKeyDown(e, 'serves')}
                required
              />
            </InputWrapper>
          </InputGroup>
          <InputWrapper>
            <Label htmlFor="description">Description</Label>
            <TextArea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, 'description')}
              placeholder="300 Character limit"
              maxLength={300}
            />
            <CharCounter>{descriptionLength}/300</CharCounter>
          </InputWrapper>
        </FormSection>

        <FormSection>
          <SectionTitle>Food Type</SectionTitle>
          <InputGroup>
            {foodTypeCategoryOrder.map(category => (
              <InputWrapper key={`foodtype-${category}`}>
                <Label>{category}</Label>
                <StyledSelect
                  isMulti
                  name={category}
                  options={foodTypeCategories[category]}
                  value={formData.foodType[category] || []}
                  onChange={(selectedOptions) => handleMultiSelectChange(category, selectedOptions)}
                  onKeyDown={(e) => handleKeyDown(e, category)}
                  components={{
                    ClearIndicator: CustomClearIndicator,
                    MultiValueRemove: CustomMultiValueRemove,
                    DropdownIndicator: CustomDropdownIndicator
                  }}
                  isSearchable={true}
                  isClearable={true}
                />
              </InputWrapper>
            ))}
          </InputGroup>
          <InputWrapper>
            <Label htmlFor="otherFoodType">Other Food Type</Label>
            <Input
              id="otherFoodType"
              name="otherFoodType"
              value={formData.otherFoodType}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, 'otherFoodType')}
            />
          </InputWrapper>
        </FormSection>

        <FormSection>
          <SectionTitle>Cooking Details</SectionTitle>
          <InputGroup>
            <InputWrapper>
              <Label htmlFor="difficulty">Difficulty</Label>
              <StyledSelect
                id="difficulty"
                name="difficulty"
                options={difficultyOptions}
                value={formData.difficulty}
                onChange={(selectedOption) => handleSelectChange('difficulty', selectedOption)}
                onKeyDown={(e) => handleKeyDown(e, 'difficulty')}
                components={{
                  ClearIndicator: CustomClearIndicator,
                  MultiValueRemove: CustomMultiValueRemove,
                  DropdownIndicator: CustomDropdownIndicator
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="prepTime">Prep Time</Label>
              <TimeInputWrapper>
                <TimeInput
                  id="prepTime"
                  name="prepTime"
                  value={formData.prepTime}
                  onChange={handleTimeInputChange}
                />
                <TimeUnitSelect
                  name="prepTimeUnit"
                  value={formData.prepTimeUnit}
                  onChange={handleTimeUnitChange}
                >
                  <option value="min">min</option>
                  <option value="hr">hr</option>
                  <option value="day">day</option>
                </TimeUnitSelect>
              </TimeInputWrapper>
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="cookingTime">Cooking Time</Label>
              <TimeInputWrapper>
                <TimeInput
                  id="cookingTime"
                  name="cookingTime"
                  value={formData.cookingTime}
                  onChange={handleTimeInputChange}
                />
                <TimeUnitSelect
                  name="cookingTimeUnit"
                  value={formData.cookingTimeUnit}
                  onChange={handleTimeUnitChange}
                >
                  <option value="min">min</option>
                  <option value="hr">hr</option>
                  <option value="day">day</option>
                </TimeUnitSelect>
              </TimeInputWrapper>
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="cookingMethod">Cooking Method</Label>
              <StyledSelect
                isMulti
                id="cookingMethod"
                name="cookingMethod"
                options={cookingMethodOptions}
                value={formData.cookingMethod}
                onChange={(selectedOptions) => handleMultiSelectChange('cookingMethod', selectedOptions)}
                onKeyDown={(e) => handleKeyDown(e, 'cookingMethod')}
                components={{
                  ClearIndicator: CustomClearIndicator,
                  MultiValueRemove: CustomMultiValueRemove,
                  DropdownIndicator: CustomDropdownIndicator
                }}
                isSearchable={true}
                isClearable={true}
              />
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="otherCookingMethod">Other Cooking Method</Label>
              <Input
                id="otherCookingMethod"
                name="otherCookingMethod"
                value={formData.otherCookingMethod}
                onChange={handleInputChange}
                onKeyDown={(e) => handleKeyDown(e, 'otherCookingMethod')}
              />
            </InputWrapper>
          </InputGroup>
        </FormSection>

        <FormSection>
        <SectionTitle>Ingredients <LightText>(pro tip: add the rows first, then add the ingredients. Tab through the fields.)</LightText></SectionTitle>
          <IngredientList>
            {Array.isArray(formData.ingredients) && formData.ingredients.map((ingredient, index) => (
              <IngredientItem key={index}>
                <Input
                  placeholder="Amount"
                  value={ingredient.amount}
                  onChange={(e) => handleIngredientChange(index, 'amount', e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, 'amount', index)}
                  style={{ width: '70px' }}
                />
                <CustomSelect
                  options={measurementOptions}
                  value={ingredient.measurement}
                  onChange={(selectedValue) => handleIngredientChange(index, 'measurement', selectedValue)}
                  onInputChange={(inputValue, { action }) => {
                    if (action === 'input-change') {
                      handleIngredientChange(index, 'measurement', inputValue);
                    }
                  }}
                  placeholder="Measurement"
                  isSearchable
                  isClearable
                  ref={(ref) => { measurementRefs.current[index] = ref; }}
                />
                <Input
                  placeholder="Ingredient name"
                  value={ingredient.name}
                  onChange={(e) => handleIngredientChange(index, 'name', e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, 'name', index)}
                  style={{ flex: 1 }}
                />
                <Button type="button" onClick={() => removeIngredient(index)}>
                  <FaMinus />
                </Button>
              </IngredientItem>
            ))}
          </IngredientList>
          <Button type="button" onClick={addIngredient}>
            <FaPlus /> Add Another Ingredient
          </Button>
        </FormSection>

        <FormSection>
          <SectionTitle>Preparation</SectionTitle>
          <InputWrapper>
            <Label htmlFor="preparationSteps">
              Preparation Steps
              <LightText>- paragraphs will be automagically numbered</LightText>
            </Label>
            <TextArea
              ref={textAreaRef}
              id="preparationSteps"
              name="preparationSteps"
              value={formData.preparationSteps}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, 'preparationSteps')}
              rows={1} // Start with one row
            />
          </InputWrapper>
        </FormSection>

        <FormSection>
          <SectionTitle>Additional Notes</SectionTitle>
          <InputWrapper>
            <Label htmlFor="notes">Notes</Label>
            <TextArea
              id="notes"
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, 'notes')}
              rows={3}
            />
          </InputWrapper>
        </FormSection>

        <Input
          type="hidden"
          id="imageUrl"
          name="imageUrl"
          value={formData.imageUrl}
        />

        <ButtonGroup>
          <CancelButton type="button" onClick={handleCancel}>
            <FaTimes /> Cancel
          </CancelButton>
          <Button type="submit">
            {isEditMode ? <FaCheckCircle /> : <FaPlus />} {isEditMode ? 'Update Recipe' : 'Submit Recipe'}
          </Button>
        </ButtonGroup>
      </form>

      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <ModalIcon />
            <ModalText>Recipe saved. Please request approval.</ModalText>
          </ModalContent>
        </ModalOverlay>
      )}
    </FormContainer>
  );
};

export default RecipeForm;