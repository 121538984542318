import Compressor from 'compressorjs';

const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB
const MAX_WIDTH = 1200;
const MAX_HEIGHT = 1200;
const QUALITY = 0.8;

export const processImage = (file) => {
  return new Promise((resolve, reject) => {
    //console.log('Processing image, initial size:', file.size);
    if (file.size <= MAX_FILE_SIZE) {
      //console.log('File is already small enough, no processing needed');
      resolve(file);
      return;
    }

    new Compressor(file, {
      maxWidth: MAX_WIDTH,
      maxHeight: MAX_HEIGHT,
      quality: QUALITY,
      success(result) {
        //console.log('Compression result size:', result.size);
        if (result.size <= MAX_FILE_SIZE) {
          resolve(result);
        } else {
          //console.log('Image still too large, compressing further');
          new Compressor(result, {
            maxWidth: MAX_WIDTH,
            maxHeight: MAX_HEIGHT,
            quality: QUALITY * 0.8,
            success(finalResult) {
              //console.log('Final compression result size:', finalResult.size);
              resolve(finalResult);
            },
            error(err) {
              //console.error('Error in second compression:', err);
              reject(err);
            },
          });
        }
      },
      error(err) {
        //console.error('Error in first compression:', err);
        reject(err);
      },
    });
  });
};

export const isValidImageType = (file) => {
  const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
  return acceptedImageTypes.includes(file.type);
};