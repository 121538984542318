import { db, auth, storage } from './firebase';
import { 
  getFirestore,
  doc, 
  getDoc, 
  setDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  updateDoc, 
  deleteDoc, 
  addDoc, 
  Timestamp, 
  arrayUnion, 
  documentId, 
  orderBy, 
  limit, 
  serverTimestamp,
  writeBatch
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, getStorage, listAll, getMetadata } from 'firebase/storage';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

// eslint-disable-next-line no-unused-vars
const getMSTTimestamp = () => {
  const now = new Date();
  return new Date(now.toLocaleString("en-US", {timeZone: "America/Phoenix"}));
};

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  //console.log('Attempting to sign in with Google');
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    //console.log('Google sign-in successful:', user.uid);
    //console.log('User object:', JSON.stringify(user, null, 2));

    //console.log('Attempting to create/update user document');
    await createOrUpdateUserDocument(user);
    //console.log('User document created/updated successfully');



    return user;
  } catch (error) {
    //console.error("Error signing in with Google:", error);
    //console.error("Error details:", JSON.stringify(error, null, 2));
    throw error;
  }
};

export const createOrUpdateUserDocument = async (user) => {
  if (!user) throw new Error('No user provided');

  const userRef = doc(db, 'users', user.uid);
  const userSnap = await getDoc(userRef);

  const now = new Date();

  if (!userSnap.exists()) {
    // New user - create document with initial fields
    const newUserData = {
      email: user.email,
      displayName: user.displayName || user.email.split('@')[0],
      photoURL: user.photoURL || null,
      firstLogin: now,
      lastLogin: now,
      lastAccessed: now,
      createdAt: now,
      updatedAt: now,
      isAdmin: false,
      isTrusted: false,
      isBlocked: false,
      isSubscribed: true,
      approvedRecipesCount: 0,
      // Add any other fields you want to initialize
    };

    await setDoc(userRef, newUserData);
    //console.log('New user document created for', user.uid);
    return newUserData;
  } else {
    // Existing user - update last login and accessed
    const updateData = {
      lastLogin: now,
      lastAccessed: now,
      updatedAt: now,
    };

    // If email login user doesn't have these fields, add them
    const userData = userSnap.data();
    if (!userData.email) updateData.email = user.email;
    if (!userData.displayName) updateData.displayName = user.displayName || user.email.split('@')[0];
    if (!userData.photoURL) updateData.photoURL = user.photoURL || null;

    await updateDoc(userRef, updateData);
    //console.log('User document updated for', user.uid);
    return { ...userData, ...updateData };
  }
};

const fetchDropdownOptions = async () => {
  try {
    const docRef = doc(db, 'settings', 'foodDropdown');
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const rawData = docSnap.data();
      //console.log('Raw data from Firestore:', rawData);
      
      const processedData = Object.entries(rawData).reduce((acc, [category, items]) => {
        acc[category] = items.split(',').map(item => item.trim());
        return acc;
      }, {});
      
      //console.log('Processed data:', processedData);
      return processedData;
    } else {
      throw new Error("No such document!");
    }
  } catch (error) {
    //console.error("Error fetching dropdown options:", error);
    throw error;
  }
};

const updateDropdownOptions = async (newOptions) => {
  try {
    const docRef = doc(db, 'settings', 'foodDropdown');
    // Convert arrays back to comma-separated strings before saving
    const dataToSave = Object.entries(newOptions).reduce((acc, [key, value]) => {
      acc[key] = Array.isArray(value) ? value.join(', ') : value;
      return acc;
    }, {});
    await setDoc(docRef, dataToSave, { merge: true });
  } catch (error) {
    //console.error("Error updating dropdown options:", error);
    throw error;
  }
};

const checkAdminStatus = async (uid) => {
  try {
    const userDocRef = doc(db, 'users', uid);
    const userDocSnap = await getDoc(userDocRef);
    
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      return userData.isAdmin === true;
    } else {
      return false;
    }
  } catch (error) {
    //console.error("Error checking admin status:", error);
    throw error;
  }
};

export const getUserDocument = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      console.log('User document data:', userData);
      return userData;
    } else {
      console.log('No such user document!');
      return null;
    }
  } catch (error) {
    //console.error('Error getting user document:', error);
    throw error;
  }
};

export const updateUserDocument = async (userId, updates) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    await updateDoc(userDocRef, updates);
    console.log('User document updated successfully');
  } catch (error) {
    //console.error('Error updating user document:', error);
    throw error;
  }
};

/**
 * Fetch all messages and enrich them with user information.
 */
const fetchAllMessages = async () => {
  //console.log("Fetching messages...");
  const messagesRef = collection(db, 'messages');
  const messagesSnap = await getDocs(messagesRef);
  const messages = messagesSnap.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  //console.log("Fetched messages:", messages);

  // Extract unique user IDs from messages
  const userIds = [...new Set(messages.map(msg => msg.to).filter(id => id))];
  //console.log("Unique user IDs:", userIds);

  if (userIds.length === 0) {
    //console.log("No user IDs to fetch.");
    return messages;
  }


  // Firestore 'in' queries can handle a maximum of 10 elements
  const chunks = [];
  const chunkSize = 10;
  for (let i = 0; i < userIds.length; i += chunkSize) {
    chunks.push(userIds.slice(i, i + chunkSize));
  }

  const usersMap = {};

  for (const chunk of chunks) {
    //console.log(`Fetching user data for chunk: [${chunk.join(', ')}]`);
    const usersSnap = await getDocs(
      query(collection(db, 'users'), where(documentId(), 'in', chunk))
    );
    usersSnap.docs.forEach(doc => {
      //console.log(`Fetched user: ${doc.id}`, doc.data());
      usersMap[doc.id] = doc.data();
    });
  }

  //console.log("Fetched user data map:", usersMap);

  // Map user data to messages
  const updatedMessages = messages.map(message => ({
    ...message,
    userEmail: usersMap[message.to]?.email || 'No email',
    userDisplayName:
      usersMap[message.to]?.displayName ||
      `${usersMap[message.to]?.firstName || ''} ${usersMap[message.to]?.lastName || ''}`.trim() ||
      'Unknown'
  }));

  //console.log("Final messages with user data:", updatedMessages);
  return updatedMessages;
};

const sendMessageReply = async (messageId, replyContent, currentUser) => {
  try {
    //console.log('sendMessageReply called with:', { messageId, replyContent, currentUser });

    const messageRef = doc(db, 'messages', messageId);
    const newReply = {
      isAdminReply: currentUser.isAdmin || false, // Default to false if undefined
      text: replyContent,
      timestamp: new Date(), // Use client-side timestamp
      userId: currentUser.uid
    };

    //console.log('New Reply Object:', newReply);

    await updateDoc(messageRef, {
      replies: arrayUnion(newReply)
    });

    //console.log('Reply sent successfully');
  } catch (error) {
    //console.error('Error sending reply:', error);
    throw error;
  }
};

export const fetchAllRecipes = async () => {
  try {
    const recipesSnapshot = await getDocs(collection(db, 'recipes'));
    const recipes = recipesSnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt ? data.createdAt : null,
        lastUpdated: data.lastUpdated ? data.lastUpdated : null
      };
    });
    return recipes;
  } catch (error) {
    //console.error('Error fetching recipes:', error);
    throw error;
  }
};

const rejectRecipe = async (recipeId, adminNote) => {
  const recipeRef = doc(db, 'recipes', recipeId);
  await updateDoc(recipeRef, {
    approved: false,
    rejected: true,
    approvalRequested: false,
    adminNote,
    lastUpdated: new Date().toISOString()
  });
};

export const updateRecipeApproval = async (recipeId, updateData) => {
  const recipeRef = doc(db, 'recipes', recipeId);
  await updateDoc(recipeRef, updateData);
};

const sendMessageToUser = async (userId, message) => {
  await addDoc(collection(db, 'messages'), { userId, message, createdAt: new Date() });
};

const deleteRecipe = async (recipeId) => {
  await deleteDoc(doc(db, 'recipes', recipeId));
};

export const fetchAllUsers = async () => {
  try {
    const usersRef = collection(db, 'users');
    const snapshot = await getDocs(usersRef);
    const users = snapshot.docs.map(doc => {
      const data = doc.data();
      //console.log('Raw user data:', data);
      return {
        uid: doc.id,
        email: data.email || 'No email',
        displayName: data.displayName || 'No name',
        isAdmin: data.isAdmin || false,
        isTrusted: data.isTrusted || false,
        isBlocked: data.isBlocked || false,
        lastLogin: data.lastLogin || null,
        lastAccessed: data.lastAccessed || null,
        createdAt: data.createdAt || null,
        approvedRecipesCount: data.approvedRecipesCount || 0,
        // ... other user properties
      };
    });
    //console.log('Processed users:', users);
    return users;
  } catch (error) {
    //console.error('Error fetching users:', error);
    throw error;
  }
};

const updateUserAdminStatus = async (userId, isAdmin) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, { isAdmin: isAdmin });
};

const updateUserBlockStatus = async (userId, isBlocked) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, { isBlocked: isBlocked });
};

const uploadRecipeImage = (file, recipeId, progressCallback) => {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, `recipe_images/${recipeId}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressCallback(progress);
      },
      (error) => {
        if (error.code === 'storage/canceled') {
          resolve({ cancelled: true });
        } else {
          reject(error);
        }
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve({ downloadURL, cancelled: false });
        } catch (error) {
          reject(error);
        }
      }
    );

    // Return the uploadTask so it can be cancelled if needed
    resolve({ uploadTask, cancelled: false });
  });
};

const cancelUpload = (uploadTask) => {
  if (uploadTask) {
    uploadTask.cancel();
    //console.log('Upload task cancelled');
  } else {
    console.warn('No upload task to cancel');
  }
};

const uploadProfilePicture = async (uid, file) => {
  const storageRef = ref(storage, `profilePictures/${uid}`);
  await uploadBytes(storageRef, file);
  return getDownloadURL(storageRef);
};

const fetchUserRecipesWithDetails = async (uid) => {
  try {
    //console.log('Fetching recipes for user ID:', uid);
    const recipesRef = collection(db, 'recipes');
    const q = query(recipesRef, where('submitterId', '==', uid)); // Changed 'userId' to 'submitterId'
    //console.log('Query:', q);
    const recipesSnap = await getDocs(q);
    //console.log('Query snapshot size:', recipesSnap.size);
    const recipes = recipesSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    //console.log('Fetched recipes from Firestore:', recipes);
    return recipes;
  } catch (error) {
    //console.error('Error in fetchUserRecipesWithDetails:', error);
    throw error;
  }
};

const fetchUserMessages = async (uid) => {
  const messagesRef = collection(db, 'messages');
  const q = query(messagesRef, where('to', '==', uid));
  const messagesSnap = await getDocs(q);
  return messagesSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const updateRecipe = async (recipeId, updatedRecipe) => {
  try {
    const recipeRef = doc(db, 'recipes', recipeId);
    await updateDoc(recipeRef, updatedRecipe);
    //console.log(`Recipe ${recipeId} updated successfully`);
  } catch (error) {
    //console.error("Error updating recipe: ", error);
    throw error;
  }
};

const submitRecipeForApproval = async (recipeId) => {
  const recipeRef = doc(db, 'recipes', recipeId);
  await updateDoc(recipeRef, {
    approvalRequested: true,
    approved: null,
    rejected: false
  });
};

const updateMessageReadStatus = async (messageId, read) => {
  const messageRef = doc(db, 'messages', messageId);
  await updateDoc(messageRef, { read });
};

const fetchPublicRecipes = async () => {
  const recipesRef = collection(db, 'recipes');
  const q = query(recipesRef, where('approved', '==', true));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};



const fetchUserFavorites = async (userId) => {
  try {
    const favoritesRef = collection(db, 'users', userId, 'favorites');
    const q = query(favoritesRef);
    const querySnapshot = await getDocs(q);
    const favorites = [];
    querySnapshot.forEach((doc) => {
      favorites.push({ id: doc.id, ...doc.data() });
    });
    return favorites;
  } catch (error) {
    //console.error('Error fetching user favorites:', error);
    throw error;
  }
};

const removeFavorite = async (userId, recipeId) => {
  try {
    const favoriteRef = doc(db, 'users', userId, 'favorites', recipeId);
    await deleteDoc(favoriteRef);
    //console.log('Favorite removed successfully');
  } catch (error) {
    //console.error('Error removing favorite:', error);
    throw error;
  }
};

const addFavorite = async (userId, recipeId) => {
  try {
    const favoriteRef = doc(db, 'users', userId, 'favorites', recipeId);
    await setDoc(favoriteRef, {
      recipeId,
      addedAt: new Date()
    });
    //console.log('Favorite added successfully');
  } catch (error) {
    //console.error('Error adding favorite:', error);
    throw error;
  }
};

const countRecipes = async () => {
  try {
    const recipesRef = collection(db, 'recipes');
    const recipesSnap = await getDocs(recipesRef);
    return recipesSnap.size;
  } catch (error) {
    //console.error('Error counting recipes:', error);
    throw error;
  }
};

const fetchUserRating = async (userId, recipeId) => {
  try {
    const ratingsRef = collection(db, 'users', userId, 'ratings');
    const q = query(ratingsRef, where('recipeId', '==', recipeId));
    const querySnapshot = await getDocs(q);
    const ratings = [];
    querySnapshot.forEach((doc) => {
      ratings.push({ id: doc.id, ...doc.data() });
    });
    return ratings.length > 0 ? ratings[0].rating : null;
  } catch (error) {
    //console.error('Error fetching user rating:', error);
    throw error;
  }
};

const addRating = async (userId, recipeId, rating) => {
  try {
    const ratingRef = doc(db, 'users', userId, 'ratings', recipeId);
    await setDoc(ratingRef, {
      recipeId,
      rating,
      ratedAt: new Date()
    });
    //console.log('Rating added successfully');
  } catch (error) {
    //console.error('Error adding rating:', error);
    throw error;
  }
};

const fetchUserRecipesCount = async (userId) => {
  try {
    const recipesRef = collection(db, 'recipes');
    const q = query(recipesRef, where('userId', '==', userId));
    const recipesSnap = await getDocs(q);
    return recipesSnap.size;
  } catch (error) {
    //console.error('Error fetching user recipes count:', error);
    throw error;
  }
};

const fetchRecipeById = async (recipeId) => {
  try {
    const recipeDocRef = doc(db, 'recipes', recipeId);
    const recipeDocSnap = await getDoc(recipeDocRef);
    
    if (!recipeDocSnap.exists()) {
      throw new Error('Recipe not found');
    }
    
    return { id: recipeDocSnap.id, ...recipeDocSnap.data() };
  } catch (error) {
    //console.error('Error fetching recipe:', error);
    throw error;
  }
};

const submitRecipe = async (recipeData) => {
  const docRef = await addDoc(collection(db, 'recipes'), recipeData);
  return docRef.id;
};

const fetchCookingTypes = async () => {
  try {
    const docRef = doc(db, 'settings', 'methodDropdown');
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data();
      //console.log('Raw data from Firestore (methodDropdown):', data);
      return data.Method ? data.Method.split(',').map(item => item.trim()) : [];
    } else {
      //console.log("No methodDropdown document!");
      return [];
    }
  } catch (error) {
    //console.error("Error fetching cooking types:", error);
    throw error;
  }
};

const updateCookingTypes = async (cookingTypes) => {
  try {
    const docRef = doc(db, 'settings', 'methodDropdown');
    const csvMethods = cookingTypes.join(', ');
    await setDoc(docRef, { Method: csvMethods }, { merge: true });
    //console.log("Cooking types updated successfully:", csvMethods);
  } catch (error) {
    //console.error("Error updating cooking types:", error);
    throw error;
  }
};

const sendMessageToAdmin = async (messageData) => {
  try {
    const messagesRef = collection(db, 'messages');
    const newMessage = {
      ...messageData,
      to: 'admin',
      from: auth.currentUser.uid,
      timestamp: serverTimestamp(),
      isRead: false
    };
    await addDoc(messagesRef, newMessage);
    //console.log('Message sent to admin successfully');
  } catch (error) {
    //console.error('Error sending message to admin:', error);
    throw error;
  }
};

const updateRating = async (userId, recipeId, rating, comment = "") => {
  //console.log(`Attempting to update rating for user ${userId} on recipe ${recipeId}`);
  
  if (!userId || !recipeId) {
    //console.error('Invalid userId or recipeId', { userId, recipeId });
    throw new Error('Invalid userId or recipeId');
  }

  try {
    const ratingId = `${userId}_${recipeId}`;
    //console.log(`Generated rating ID: ${ratingId}`);

    const ratingRef = doc(db, 'ratings', ratingId);
    const ratingData = {
      userId,
      recipeId,
      rating,
      timestamp: Timestamp.now(),
      comment: comment || "" // Ensure comment is always included, even if empty
    };

    //console.log('Rating data to be set:', ratingData);
    await setDoc(ratingRef, ratingData, { merge: true });
    //console.log('Rating updated successfully');
    return true;
  } catch (error) {
    //console.error('Error updating rating:', error);
    //console.error('Error details:', JSON.stringify(error, null, 2));
    throw error;
  }
};

const getRating = async (userId, recipeId) => {
  try {
    const ratingDoc = await getDoc(doc(db, 'ratings', `${userId}_${recipeId}`));
    if (ratingDoc.exists()) {
      return ratingDoc.data().rating;
    }
    return null;
  } catch (error) {
    //console.error('Error getting rating:', error);
    return null;
  }
};

const getAverageRating = async (recipeId) => {
  try {
    const ratingsQuery = query(collection(db, 'ratings'), where('recipeId', '==', recipeId));
    const querySnapshot = await getDocs(ratingsQuery);
    let totalRating = 0;
    let count = 0;
    querySnapshot.forEach((doc) => {
      totalRating += doc.data().rating;
      count++;
    });
    return count > 0 ? totalRating / count : 0;
  } catch (error) {
    //console.error('Error getting average rating:', error);
    return 0;
  }
};

const updateMessageResolved = async (messageId, resolved) => {
  try {
    const messageRef = doc(db, 'messages', messageId);
    await updateDoc(messageRef, { resolved });
    //console.log(`Message ${messageId} resolved status updated to ${resolved}`);
  } catch (error) {
    //console.error("Error updating message resolved status: ", error);
    throw error;
  }
};

const getTotalRecipes = async () => {
  const recipesSnapshot = await getDocs(collection(db, 'recipes'));
  //console.log('Total recipes:', recipesSnapshot.size);
  return recipesSnapshot.size;
};

const getTotalUsers = async () => {
  const usersSnapshot = await getDocs(collection(db, 'users'));
  //console.log('Total users:', usersSnapshot.size);
  return usersSnapshot.size;
};

const getPendingApprovals = async () => {
  const q = query(collection(db, 'recipes'), where('approved', '==', null));
  const pendingSnapshot = await getDocs(q);
  //console.log('Pending approvals:', pendingSnapshot.size);
  return pendingSnapshot.size;
};

const getUnreadMessages = async () => {
  const q = query(collection(db, 'messages'), where('isRead', '==', false));
  const unreadSnapshot = await getDocs(q);
  return unreadSnapshot.size;
};

const getRecentActivity = async () => {
  const activityRef = collection(db, 'activity');
  const q = query(activityRef, orderBy('timestamp', 'desc'), limit(10));
  const activitySnapshot = await getDocs(q);
  return activitySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

// Add these new functions to firebaseUtils.js

const getRecipesByCategory = async () => {
  const recipesSnapshot = await getDocs(collection(db, 'recipes'));
  const categories = {};
  recipesSnapshot.forEach(doc => {
    const recipe = doc.data();
    if (recipe['Food Type'] && typeof recipe['Food Type'] === 'object') {
      Object.keys(recipe['Food Type']).forEach(category => {
        if (!categories[category]) categories[category] = 0;
        categories[category]++;
      });
    }
  });
  return categories;
};

const getMostPopularRecipes = async (limit = 5) => {
  const q = query(collection(db, 'recipes'), orderBy('views', 'desc'), limit(limit));
  const popularRecipesSnapshot = await getDocs(q);
  return popularRecipesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const getAverageRatingAcrossAllRecipes = async () => {
  //console.log('Fetching average rating across all recipes');
  const ratingsSnapshot = await getDocs(collection(db, 'ratings'));
  let totalRating = 0;
  let count = 0;
  ratingsSnapshot.forEach(doc => {
    const rating = doc.data().rating;
    //console.log(`Rating found: ${rating}, type: ${typeof rating}`);
    if (typeof rating === 'number' && !isNaN(rating)) {
      totalRating += rating;
      count++;
    }
  });
  //console.log(`Total rating: ${totalRating}, Count: ${count}`);
  return count > 0 ? totalRating / count : null;
};

const getNewUsersLastMonth = async () => {
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const q = query(collection(db, 'users'), where('createdAt', '>=', oneMonthAgo));
  const newUsersSnapshot = await getDocs(q);
  return newUsersSnapshot.size;
};

const getMostActiveUsers = async (limit = 5) => {
  const q = query(collection(db, 'users'), orderBy('recipeCount', 'desc'), limit(limit));
  const activeUsersSnapshot = await getDocs(q);
  return activeUsersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const getTotalRatings = async () => {
  const ratingsSnapshot = await getDocs(collection(db, 'ratings'));
  return ratingsSnapshot.size;
};

const getAverageApprovalTime = async () => {
  const recipesSnapshot = await getDocs(collection(db, 'recipes'));
  let totalTime = 0;
  let count = 0;
  recipesSnapshot.forEach(doc => {
    const recipe = doc.data();
    if (recipe.approvedAt && recipe.submittedAt) {
      totalTime += recipe.approvedAt.toMillis() - recipe.submittedAt.toMillis();
      count++;
    }
  });
  return count > 0 ? totalTime / count / (1000 * 60 * 60) : 0; // Return average time in hours
};

const getLastAccessTime = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      return userSnap.data().lastAccessed;
    }
    return null;
  } catch (error) {
    //console.error("Error getting last access time:", error);
    throw error;
  }
};

const subscribeToNewsletter = async (email, firstName, lastName) => {
  try {
    const response = await fetch('/.netlify/functions/subscribeToNewsletter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, firstName, lastName }),
    });

    if (!response.ok) {
      throw new Error('Failed to subscribe to newsletter');
    }


    //console.log('Newsletter subscription successful:', data);
  } catch (error) {
    //console.error('Error subscribing to newsletter:', error);
    throw error;
  }
};

const unsubscribeFromNewsletter = async (email) => {
  try {
    const response = await fetch('/.netlify/functions/unsubscribeFromNewsletter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Failed to unsubscribe from newsletter');
    }


    //console.log('Newsletter unsubscription successful:', data);
  } catch (error) {
    //console.error('Error unsubscribing from newsletter:', error);
    throw error;
  }
};

const updateLastAccessTime = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, { lastAccessed: new Date() });
    return true;
  } catch (error) {
    //console.error("Error updating last access time:", error);
    return false;
  }
};

const getStorageInfo = async () => {
  const storage = getStorage();
  const rootRef = ref(storage, '/');
  
  try {
    const result = await listAll(rootRef);
    let totalSize = 0;
    let fileCount = 0;

    for (const itemRef of result.items) {
      const metadata = await getMetadata(itemRef);
      totalSize += metadata.size;
      fileCount++;
    }

    return {
      totalSize: totalSize / (1024 * 1024), // Convert to MB
      fileCount
    };
  } catch (error) {
    //console.error("Error getting storage info:", error);
    throw error;
  }
};

const migrateRatings = async () => {
  const ratingsRef = collection(db, 'ratings');
  const snapshot = await getDocs(ratingsRef);
  let migratedCount = 0;
  let updatedCount = 0;

  for (let docSnapshot of snapshot.docs) {
    const data = docSnapshot.data();
    const userId = data.userId || data.userEmail.split('@')[0];
    const recipeId = data.recipeId;
    const newRatingId = `${userId}_${recipeId}`;

    const newRatingData = {
      userId: userId,
      recipeId: recipeId,
      rating: data.rating,
      timestamp: data.timestamp instanceof Timestamp 
        ? data.timestamp 
        : Timestamp.fromDate(data.timestamp || new Date()),
      comment: data.comment || ""
    };

    if (docSnapshot.id !== newRatingId) {
      await setDoc(doc(db, 'ratings', newRatingId), newRatingData);
      await deleteDoc(docSnapshot.ref);
      migratedCount++;
    } else if (!data.userId) {
      await setDoc(docSnapshot.ref, newRatingData, { merge: true });
      updatedCount++;
    }
  }
  //console.log(`Ratings migration completed. Migrated: ${migratedCount}, Updated: ${updatedCount}`);
  return { migratedCount, updatedCount };
};

const checkRatingsState = async () => {
  const ratingsRef = collection(db, 'ratings');
  const snapshot = await getDocs(ratingsRef);
  let totalCount = snapshot.size;
  let correctFormatCount = 0;

  for (let docSnapshot of snapshot.docs) {
    const data = docSnapshot.data();
    if (docSnapshot.id.includes('_') && data.userId) {
      correctFormatCount++;
    }
  }

  //console.log(`Total ratings: ${totalCount}, Correct format: ${correctFormatCount}`);
  return { totalCount, correctFormatCount };
};

const YOUTUBE_API_SETTINGS_DOC = 'youtubeApiSettings';

const getYouTubeApiSettings = async () => {
  try {
    const docRef = doc(db, 'settings', YOUTUBE_API_SETTINGS_DOC);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : {};
  } catch (error) {
    //console.error('Error fetching YouTube API settings:', error);
    throw error;
  }
};

const updateYouTubeApiSettings = async (settings) => {
  try {
    const docRef = doc(db, 'settings', YOUTUBE_API_SETTINGS_DOC);
    await setDoc(docRef, settings, { merge: true });
  } catch (error) {
    //console.error('Error updating YouTube API settings:', error);
    throw error;
  }
};

const updateUserTrustedStatus = async (userId, isTrusted) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, { isTrusted });
};

export const getApprovedRecipesCount = async (userId) => {
  try {
    const recipesRef = collection(db, 'recipes');
    const q = query(
      recipesRef,
      where('submitterId', '==', userId),
      where('approved', '==', true)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  } catch (error) {
    //console.error('Error getting approved recipes count:', error);
    return 0;
  }
};

export const getUserRecipes = async (userId) => {
  try {
    const recipesRef = collection(db, 'recipes');
    const q = query(recipesRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    //console.error("Error getting user recipes:", error);
    throw error;
  }
};



/**
 * Fetches the About page content from Firestore.
 *
 * @returns {Promise<string>} The HTML content of the About page.
 */
export const fetchAboutContent = async () => {
  try {
    const docRef = doc(db, 'settings', 'about');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data().content || '';
    } else {
      //console.error('No such document for About page!');
      return '<p>About content not available.</p>';
    }
  } catch (error) {
    //console.error('Error fetching About content:', error);
    return '<p>Error loading content.</p>';
  }
};

export const checkFirstTimeUser = async (userId) => {
  const db = getFirestore();
  const userRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userRef);

  if (!userSnap.exists()) {
    // If the user document doesn't exist, it's a first-time user
    await setDoc(userRef, { 
      firstLogin: new Date(),
      // You can add other default fields here
    });
    return true;
  }

  return false;
};

// Make sure removeUser and setPendingAndReassignRecipes are defined without the 'export' keyword
const removeUser = async (userId) => {
  try {
    await deleteDoc(doc(db, 'users', userId));
    // You may want to add additional cleanup here, such as removing user's auth account
    // This requires Firebase Admin SDK and should be done in a secure environment (e.g., Cloud Function)
  } catch (error) {
    //console.error('Error removing user:', error);
    throw error;
  }
};

const setPendingAndReassignRecipes = async (userId, adminId) => {
  try {
    const recipesRef = collection(db, 'recipes');
    const q = query(recipesRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      batch.update(doc.ref, {
        approved: false,
        approvalRequested: true,
        userId: adminId
      });
    });

    await batch.commit();
  } catch (error) {
    //console.error('Error setting recipes to pending and reassigning:', error);
    throw error;
  }
};

export const getUserBlockStatus = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return userDoc.data().isBlocked || false;
    }
    return false;
  } catch (error) {
    //console.error('Error getting user block status:', error);
    throw error;
  }
};

export const getUserProfile = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      console.log('User data fetched:', userData);
      return userData;
    } else {
      console.log('No such user document!');
      return null;
    }
  } catch (error) {
    //console.error('Error getting user profile:', error);
    throw error;
  }
};

// At the end of the file, update the export statement to include all functions only once
export {
  signInWithGoogle,
  fetchDropdownOptions,
  updateDropdownOptions,
  checkAdminStatus,
  fetchAllMessages,
  sendMessageReply,
  // Remove fetchAllRecipes from here since it's already exported above
  rejectRecipe,
  sendMessageToUser,
  deleteRecipe,
  updateUserAdminStatus,
  updateUserBlockStatus,
  uploadRecipeImage,
  cancelUpload,
  uploadProfilePicture,
  fetchUserRecipesWithDetails,
  fetchUserMessages,
  updateRecipe,
  submitRecipeForApproval,
  updateMessageReadStatus,
  fetchPublicRecipes,
  fetchUserFavorites,
  removeFavorite,
  addFavorite,
  countRecipes,
  fetchUserRating,
  addRating,
  fetchUserRecipesCount,
  fetchRecipeById,
  submitRecipe,
  auth,
  db,
  fetchCookingTypes,
  updateCookingTypes,
  sendMessageToAdmin,
  updateRating,
  getRating,
  getAverageRating,
  updateMessageResolved,
  getTotalRecipes,
  getTotalUsers,
  getPendingApprovals,
  getUnreadMessages,
  getRecentActivity,
  getRecipesByCategory,
  getMostPopularRecipes,
  getAverageRatingAcrossAllRecipes,
  getNewUsersLastMonth,
  getMostActiveUsers,
  getTotalRatings,
  getAverageApprovalTime,
  getLastAccessTime,
  subscribeToNewsletter,
  unsubscribeFromNewsletter,
  updateLastAccessTime,
  getStorageInfo,
  migrateRatings,
  checkRatingsState,
  getYouTubeApiSettings,
  updateYouTubeApiSettings,
  updateUserTrustedStatus,
  removeUser,
  setPendingAndReassignRecipes,
};