import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const Title = styled.h2`
  color: #2a2b2f;
  margin-bottom: 20px;
`;

const Message = styled.p`
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #2a2b2f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #1e4b70;
  }
`;

const LogoutModal = ({ onClose }) => {
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Title>Logged Out Successfully</Title>
        <Message>You have been successfully logged out of your account.</Message>
        <Button onClick={onClose}>Close</Button>
      </ModalContent>
    </ModalOverlay>
  );
};

export default LogoutModal;
