import React, { useState } from 'react';
import styled from 'styled-components';

const CookingMethodsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 15px;
`;

const MethodList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const MethodItem = styled.div`
  padding: 4px 8px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const DeleteButton = styled.button`
  margin-left: 8px;
  background: none;
  border: none;
  color: red;
  cursor: pointer;
`;

const AddMethodForm = styled.form`
  display: flex;
  margin-bottom: 15px;
`;

const AddMethodInput = styled.input`
  padding: 4px 8px;
  margin-right: 8px;
`;

const AddMethodButton = styled.button`
  padding: 4px 8px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
`;

const CookingMethodsManager = ({ cookingTypes, setCookingTypes }) => {
  const [newMethod, setNewMethod] = useState('');

  const handleAddMethod = (e) => {
    e.preventDefault();
    if (newMethod.trim() && !cookingTypes.includes(newMethod.trim())) {
      setCookingTypes([...cookingTypes, newMethod.trim()]);
      setNewMethod('');
    }
  };

  const handleDeleteMethod = (method) => {
    setCookingTypes(cookingTypes.filter(m => m !== method));
  };

  return (
    <CookingMethodsContainer>
      <AddMethodForm onSubmit={handleAddMethod}>
        <AddMethodInput
          type="text"
          value={newMethod}
          onChange={(e) => setNewMethod(e.target.value)}
          placeholder="Add new cooking method"
        />
        <AddMethodButton type="submit">Add Method</AddMethodButton>
      </AddMethodForm>
      <MethodList>
        {Array.isArray(cookingTypes) && cookingTypes.map((method, index) => (
          <MethodItem key={index}>
            {method}
            <DeleteButton onClick={() => handleDeleteMethod(method)}>×</DeleteButton>
          </MethodItem>
        ))}
      </MethodList>
    </CookingMethodsContainer>
  );
};

export default CookingMethodsManager;